import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import {
  BoldText,
  BoxWrapper,
  DesktopSliderWrapper,
  SliderTitle,
  TextWrapper,
  Text,
  SlideImage,
  ButtonWrapper,
  SliderWrap,
  SlideImageWrapper,
  Wrapper,
} from './DesktopSlider.styled';
import logo from '../../../../assets/images/2023LogoEnDesktop.png';
import logoFR from '../../../../assets/images/2023LogoFrDesktop.png';
import GameIcons from '../images/game_icon_des.png';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Button from '../../../Button';
import { NextBtn, PreviousBtn } from './Components/CustomButtons/CustomButtons';
import { OnboardingDesktopData } from '../../Mock/OnboardingDesktopData';
import { IDataDesOnboardingProps, IDataDesProps } from '../../types';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../../../utils/types/types';
import paths from '../../../../AppRouter/paths';
import { localStorageSet } from '../../../../utils/localStorage';
import { HeaderContainer, HeaderTopContainer, LogoImage } from '../../../ApplicationHeader/ApplicationHeader.styles';

const DesktopSlider: React.FC = () => {
  const { t } = useTranslation();
  const [newUser, setNewUser] = useState<boolean>(true);
  const navigate = useNavigate();
  const { langId } = useParams();
  const lng = localStorage.getItem('i18nextLng');
  const deskOnboarding: IDataDesOnboardingProps[] = t('deskOnboarding', {
    returnObjects: true,
  });
  const trs: ITrs = t('trs', {
    returnObjects: true,
  });
  const counter = 5;
  const slider = useRef(null);
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 1,
    speed: 500,
    cssEase: 'linear',
    useTransform: false,
    pauseOnHover: false,
    arrows: true,
    variableHeight: true,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn slideCount={counter} />,
    appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
    customPaging: () => (
      <div className="dots">
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      </div>
    ),
  };

  const handleLastClick = () => {
    navigate(`/${langId}${paths.home}`);
    if (newUser === true) {
      setNewUser(!newUser);
      localStorageSet('user', newUser);
    }
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <HeaderTopContainer>
          <LogoImage src={lng === 'fr' ? logoFR : logo} />
        </HeaderTopContainer>
      </HeaderContainer>
      <SliderWrap>
        <Slider ref={slider} {...settings}>
          {deskOnboarding.map((item: IDataDesProps, id: number) => (
            <DesktopSliderWrapper key={id}>
              <SliderTitle>{item.title}</SliderTitle>
              <BoxWrapper>
                <TextWrapper>
                  {item.description && (
                    <>
                      {item.description.map((item: string, index: number) => (
                        <BoldText
                          style={{
                            marginTop: index !== 0 ? '10px' : '0px',
                          }}
                        >
                          {item}
                        </BoldText>
                      ))}
                    </>
                  )}
                  {item.subDescription && (
                    <>
                      {item.subDescription.map((item: string) => (
                        <Text
                          style={{
                            marginTop: '10px',
                          }}
                        >
                          {item}
                        </Text>
                      ))}
                    </>
                  )}
                  {item.subDescriptionTwo && (
                    <>
                      {item.subDescriptionTwo.map((item: string, index: number) => (
                        <Text
                          style={{
                            marginTop: index === 0 ? '10px' : '0px',
                          }}
                        >
                          {item}
                        </Text>
                      ))}
                    </>
                  )}
                  {item.link && (
                    <Link
                      to={`/${langId}${paths.rules}`}
                      style={{
                        fontSize: '14px',
                        lineHeight: '140%',
                        color: '#1c1c1d',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.link}
                    </Link>
                  )}
                  {item.icons && <img style={{ marginTop: '15px' }} src={GameIcons} alt="game" />}
                </TextWrapper>
                <SlideImageWrapper>
                  <SlideImage src={item.screen} alt="screen" />
                </SlideImageWrapper>
              </BoxWrapper>
              <ButtonWrapper>
                {OnboardingDesktopData.length - 1 === id && (
                  <Button text={trs.gotIt} fullWidth handleClick={handleLastClick} />
                )}
              </ButtonWrapper>
            </DesktopSliderWrapper>
          ))}
        </Slider>
      </SliderWrap>
    </Wrapper>
  );
};

export default DesktopSlider;
