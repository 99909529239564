import styled from "styled-components";

export const Row = styled("tr")({
    width: "100%",
    color: "white",
    height: "60px",
    fontSize: "14px",
    cursor: "pointer",
    background: "#10100F",
    borderBottom: "1px solid #656563",
});

export const Value = styled("td")({
    verticalAlign: "middle",
    textAlign: "center",
});