import React, {FC} from 'react';
import {NavLink, useParams} from "react-router-dom";
import paths from '../../../AppRouter/paths';
import {TabsWrapper} from './PlayTabs.styled';

type Props = {
    activeSteps: boolean[];
}
const PlayTabs:FC<Props> = ({activeSteps}) => {
    const lng = localStorage.getItem('i18nextLng');
    const { langId } = useParams();
    // `/${langId}${}`
    return (
        <TabsWrapper>
            <NavLink className={`tabs wins_tab ${lng === 'en' ? 'en' : 'fr'}`} to={`/${langId}${paths.wins}`}/>
            <NavLink className={`tabs saves_tab ${lng === 'en' ? 'en' : 'fr'} ${activeSteps[0] ? '' : 'isDisabled'}`} to={`/${langId}${paths.save}`}/>
            <NavLink className={`tabs goals_tab ${lng === 'en' ? 'en' : 'fr'} ${activeSteps[1] ? '' : 'isDisabled'}`} to={`/${langId}${paths.goals}`}/>
            <NavLink className={`tabs pepsi_tab ${lng === 'en' ? 'en' : 'fr'} ${activeSteps[2] ? '' : 'isDisabled'}`} to={`/${langId}${paths.shutout}`}/>
        </TabsWrapper>
    );
};

export default PlayTabs;
