import React from "react";

const RightArray: React.FC = () => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.24375 0.410582C7.56919 0.0851447 8.09683 0.0851447 8.42226 0.410582L13.4223 5.41058C13.5785 5.56686 13.6663 5.77882 13.6663 5.99984C13.6663 6.22085 13.5785 6.43281 13.4223 6.58909L8.42226 11.5891C8.09683 11.9145 7.56919 11.9145 7.24375 11.5891C6.91831 11.2637 6.91832 10.736 7.24375 10.4106L10.8212 6.83317L1.16634 6.83317C0.706104 6.83317 0.333008 6.46008 0.333008 5.99984C0.333008 5.5396 0.706104 5.1665 1.16634 5.1665L10.8212 5.1665L7.24375 1.58909C6.91831 1.26366 6.91831 0.736018 7.24375 0.410582Z" fill="white"/>
        </svg>
    );
};

export default RightArray;