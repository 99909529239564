import {
    StyledButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
    BlueGoalieContainer,
    PopupBackdrop,
    PopupBodyContainer,
    PopupContainer,
    PopupCrossContainer,
} from "../Popups/Popups.styles" ;
import CongratPopupBg from './images/CongratPopupBg.png';
import { IDeleteMemberPopupProps } from '../PepsiShutout/types';
import { CaretRight } from '../../assets/icons/CaretRight';
import CrossIcon from '../../assets/icons/CrossIcon';
import { B } from './Popups.styles';
import { useTranslation } from 'react-i18next';
import { deleteUserFromLeague } from '../../api/pickems';
import { useState } from 'react';
import Loader from '../Loader/Loader';

const DeleteLeagueMemberPopup = ({
    currentLeague,
    memberToDelete,
    isVisible,
    setIsVisible,
    handleDeleteLeagueMember,
}: IDeleteMemberPopupProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async () => {

        setIsLoading(true)
        if (currentLeague && memberToDelete) {
            const deleteResponse = await deleteUserFromLeague(currentLeague?.league_id, memberToDelete.user_id);
            deleteResponse.success && handleDeleteLeagueMember();
        }
        setIsLoading(false)
        setIsVisible(false);
    };
    const { t } = useTranslation();
    return (
        <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
            <PopupCrossContainer
                onClick={() => {setIsVisible(false);}}
            >
                <CrossIcon />
            </PopupCrossContainer>
            <PopupContainer>
                <BlueGoalieContainer>
                    <img
                        src={CongratPopupBg}
                        alt={'goalie'}
                        style={{ float: 'right' }}
                    />
                </BlueGoalieContainer>
                <PopupBodyContainer>
                    <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                        {t('removePlayer')}
                    </h1>
                    <p style={{ fontWeight: 400, marginTop: '16px' }}>
                        {t('textSt')} <B>{memberToDelete?.username}</B> {t('textMd')}{' '}
                        <B>{currentLeague?.title}</B>, {t('textEnd')}{' '}
                    </p>

                    <p
                        style={{
                            marginTop: '18px',
                            fontWeight: 700,
                            fontSize: '16px',
                        }}
                    >
                        {t('once')}
                    </p>

                    <StyledButton
                        onClick={handleDelete}
                        style={{
                            width: '160px',
                            height: '60px',
                            marginTop: '18px' }}
                    >
                        {!isLoading ? <>
                            {t('confirm')}
                            <CaretRight />
                        </> : <Loader />
                        }
                    </StyledButton>
                </PopupBodyContainer>
            </PopupContainer>
        </PopupBackdrop>
    );
};

export default DeleteLeagueMemberPopup;
