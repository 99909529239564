import {
    StyledButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
    BlueGoalieContainer,
    PopupBackdrop,
    PopupBodyContainer,
    PopupContainer,
    PopupCrossContainer,
} from "../Popups/Popups.styles" ;
import CongratPopupBg from './images/CongratPopupBg.png';
import { ILeaguePopupProps } from '../PepsiShutout/types';
import { CaretRight } from '../../assets/icons/CaretRight';
import CrossIcon from '../../assets/icons/CrossIcon';
import { deleteLeague, League } from '../../api/pickems';
import { useLazyQuery } from '../../hooks/useLazyQuery';
import { useContext, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../AppRouter/paths';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../context/StateContex';

const DeleteLeaguePopup = ({
    currentLeague,
    isVisible,
    setIsVisible,
}: ILeaguePopupProps) => {
    const [leagueToDelete, setLeagueToDelete] = useState<League | undefined>(
        currentLeague
    );
    const navigate = useNavigate();
    const { langId } = useParams();
    const {userLeagues} = useContext(StateContext) 

    const deleteLeagueQuery = useLazyQuery<{ league_id: number }, any>({
        fetcher: ({ league_id }) => deleteLeague(league_id),
    });

    const handleConfirmDeleteLeague = async () => {
        if (!leagueToDelete) return;
        const data = await deleteLeagueQuery.fetchData({
            league_id: leagueToDelete.league_id,
        });
        if (data.success) {
            navigate(`/${langId}${paths.myLeagues}`);
            await userLeagues.refetchData();
            setLeagueToDelete(undefined);
        } else {
            alert('Error');
        }
    };
    const { t } = useTranslation();
    return (
        <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
            <PopupCrossContainer
                onClick={() => {
                    setIsVisible(false);
                }}
            >
                <CrossIcon />
            </PopupCrossContainer>
            <PopupContainer>
                <BlueGoalieContainer>
                    <img
                        src={CongratPopupBg}
                        alt={'goalie'}
                        style={{ float: 'right' }}
                    />
                </BlueGoalieContainer>
                <PopupBodyContainer>
                    <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                        {t('deleteLeagues')}
                    </h1>
                    {/* <p style={{marginTop: "8px", fontWeight: 400}}>Your picks are in.</p> */}
                    <p
                        style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            marginTop: '18px',
                        }}
                    >
                        {t('toDelete')}{' '}
                        <b style={{ fontWeight: 700 }}>{leagueToDelete?.title}</b>,
                        {t('toDeleteEnd')}
                    </p>

                    <p
                        style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            marginTop: '16px',
                        }}
                    >
                        {t('plzConfirm')}{' '}
                    </p>

                    <StyledButton
                        onClick={handleConfirmDeleteLeague}
                        style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            marginTop: '18px',
                        }}
                    >
                        {t('confirm')}
                        <CaretRight />
                    </StyledButton>
                </PopupBodyContainer>
            </PopupContainer>
        </PopupBackdrop>
    );
};

export default DeleteLeaguePopup;
