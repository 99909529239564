import React from "react";
import { Bling as GPT } from "react-gpt";
import {Wrapper} from "./AdsBanner.styles";
import {useTranslation} from "react-i18next";
import { useWindowSize } from "../../helpers/useWidth";

const AdsBanner: React.FC<any> = ({slotSize, positionLeft, customStyle}) => {
    const id = React.useId();
    const { width } = useWindowSize();
    const isDesktop = width < 728;
    const { t } = useTranslation()

    return (
        <Wrapper
            positionLeft={positionLeft}
            style={{maxWidth: isDesktop ? 'unset' : `${width}px`, overflow: 'hidden', ...customStyle}}
        >
            <GPT
                id={id}
                adUnitPath={t("idBanner")}
                // slotSize={slotSize}
                sizeMapping={[
                    { viewport: [0, 0], slot: slotSize },
                ]}
            />
        </Wrapper>
    );
};

export default AdsBanner;