import EN from './2023RulesEn.docx';
import FR from './2023RulesFr.docx';
export interface IRules {
    leng: string;
    path: string;
}
export const rulesData: IRules[] = [
    {
        leng: 'en',
        path: EN,
    },
    {
        leng: 'fr',
        path: FR,
    },
];
