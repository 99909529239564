import React from 'react';
import Button from '../Button';
import Input from '../Input';
import RightArray from '../../assets/icons/RightArray';
import {ImageLoaded, InputContainer, StylesForm} from './InputForm.styles';
import TickRight from '../../assets/icons/TickRight';
import TickWrong from '../../assets/icons/TickWrong';
import {InputFormProps} from './types';
import loader from "../../pages/Loader/loader.svg";

const InputForm: React.FC<InputFormProps> = ({
    item,
    updateItem,
    isButtonDisabled,
    checkItem,
    showResult,
    correctText,
    incorrectText,
    resultItem,
    textInfo,
    maxItem,
    isLoaded,
    join
}) => {

    return (
        <>
            <StylesForm>
                <InputContainer>
                    {isLoaded && <ImageLoaded src={loader} alt="loader"/>}
                    <Input
                        updateItem={updateItem}
                        value={
                            !showResult
                                ? item
                                : resultItem
                                    ? correctText
                                    : incorrectText
                        }
                        inputVariant={'custom'}
                        textInput={textInfo}
                        borderTick={resultItem}
                        checkItem={checkItem}
                        isLoaded={isLoaded}
                        maxItem={maxItem}
                        join={join}
                    />
                </InputContainer>
                {!showResult ? (
                    <Button
                        handleClick={checkItem}
                        color={'notStyles'}
                        disabled={isButtonDisabled}
                        iconItem={maxItem ? <TickWrong/> : <RightArray/>}
                    />
                ) : (
                    <Button
                        color={'notStyles'}
                        disabled={true}
                        iconItem={resultItem ? <TickRight/> : <TickWrong/>}
                    />
                )}
            </StylesForm>
        </>
    );
};

export default InputForm;
