import { IPlayer } from "../../context/type";

const ScoresCount = (selectedPlayers: IPlayer[], boostedPlayer: IPlayer) => {

    const scoreToNum = selectedPlayers[2]?.data.target_score ? parseInt(selectedPlayers[2].data.target_score, 10) : 0;

    const scoreStep1 = selectedPlayers[0]?.is_correct
        ? process.env.REACT_APP_GAME_WON
        : 0;

    const scoreStep2 = selectedPlayers[1]?.data.target_score
        ? // @ts-ignore
        selectedPlayers[1].data.target_score
            ? // @ts-ignore
            process.env.REACT_APP_POINT_PER_SAVE *
            parseInt(selectedPlayers[1].data.target_score, 10)
            : 0
        : 0;

    const scoreStep3 = selectedPlayers[2]?.data.target_score
        ? selectedPlayers[2].data.target_score === undefined
            ? 0
            : scoreToNum > 5
                ? process.env.REACT_APP_FEWEST_GOAL_5
                : scoreToNum === 4
                    ? process.env.REACT_APP_FEWEST_GOAL_4
                    : scoreToNum === 3
                        ? process.env.REACT_APP_FEWEST_GOAL_3
                        : scoreToNum === 2
                            ? process.env.REACT_APP_FEWEST_GOAL_2
                            : scoreToNum === 1
                                ? process.env.REACT_APP_FEWEST_GOAL_1
                                : scoreToNum === 0
                                    ? process.env.REACT_APP_FEWEST_GOAL_0 : 0
        : 0;

    // @ts-ignore
    const pepsiBooster = parseInt(process.env.REACT_APP_PEPSI_BOOSTER, 10);

    const scoreStep1WithBoost = selectedPlayers[0]?.title_en === boostedPlayer?.title_en && boostedPlayer?.is_correct
        // @ts-ignore
        ? parseInt(scoreStep1, 10) + pepsiBooster
        // @ts-ignore
        : parseInt(scoreStep1, 10);

    const scoreStep2WithBoost = selectedPlayers[1]?.title_en === boostedPlayer?.title_en && boostedPlayer?.is_correct
        // @ts-ignore
        ? parseInt(scoreStep2, 10) + pepsiBooster
        // @ts-ignore
        : parseInt(scoreStep2, 10);

    const scoreStep3WithBoost = selectedPlayers[2]?.title_en === boostedPlayer?.title_en && boostedPlayer?.is_correct
        // @ts-ignore
        ? parseInt(scoreStep3, 10) + pepsiBooster
        // @ts-ignore
        : parseInt(scoreStep3, 10);


    const totalScoreAmount = scoreStep1WithBoost + scoreStep2WithBoost + scoreStep3WithBoost;
    return {scoreStep1WithBoost, scoreStep2WithBoost, scoreStep3WithBoost, totalScoreAmount}
}

export default ScoresCount