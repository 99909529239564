import React from 'react';
import { useParams } from 'react-router-dom';

import BannerTempFr from './BannerTempFr.jpeg';
import BannerTemp from './BannerTemp.jpeg';

// winter classic banner
export const Banner = () => {
  const { langId } = useParams();
  return <img alt='banner' src={langId === 'fr' ? BannerTempFr : BannerTemp} style={{margin: 'auto', maxWidth: '100%'}}/>
}