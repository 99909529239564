import React from "react";
import { Arrow } from "./CustomButtons.styled";
import ArrowLeft from "./images/Icon_two.svg";
import ArrowRight from "./images/Icon.svg";
import {IButtonProps, IProps} from "./types";

export const PreviousBtn = (props: IProps) => {
    const { className, onClick, currentSlide } = props;
    return (
        <>
            {currentSlide !== 0 && (
                <div className={className} onClick={onClick}>
                    <Arrow src={ArrowLeft} alt=" arrow left" />
                </div>
            )}
        </>
    );
};
export const NextBtn = (props: IButtonProps) => {
    const { className, onClick, currentSlide, slideCount } = props;
    const slideToShow = 1;
    const pos = slideCount - slideToShow;
    return (
        <>
            {currentSlide !== pos && (
                <div className={className} onClick={onClick}>
                    <Arrow src={ArrowRight} alt=" arrow right" />
                </div>
            )}
        </>
    );
};