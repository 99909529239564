const paths: Record<string, string> = {
    home: '/home',
    guide: '/how-to-play',
    myLeagues: '/my-leagues',
    wins: '/wins',
    save: '/save',
    goals: '/goals',
    overall: '/overall',
    pepsi: '/pepsi',
    weekly: '/weekly',
    global: 'global',
    shutout: '/shutout',
    leaderboardScores: '/scores',
    timeout: '/timeout',
    admin: '/admin',
    gamezone: '/gamezone',
    userLeague: '/league',
    rules: '/rules',
    splashscreen: '/',
};

export default paths;
