import React from 'react';
// @ts-ignore
import logo from '../../assets/images/2023LogoEnMobile.png';
import logoFr from '../../assets/images/2023LogoFrMobile.png';

const SidebarHeader = () => {
    const lng = localStorage.getItem('i18nextLng');

    return (
        <div style={{ width: '140px', height: '164px' }}>
            <img
                src={lng === 'fr' ? logoFr : logo}
                style={{ width: '100%', display: 'block' }}
            />
            ;
        </div>
    );
};

export default SidebarHeader;
