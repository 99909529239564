import React from "react";
import {NavigationButtonsProps} from "./types";
import {ButtonsWrapper} from "./NavigationButtons.styles";

const NavigationButtons: React.FC<NavigationButtonsProps> = ({children}) => {
    return (
        <ButtonsWrapper>
            {children}
        </ButtonsWrapper>
    );
};

export default NavigationButtons;