import React from "react";

interface Props {
  onReachPageEnd: () => void;
  deps?: unknown[];
}

const useIntersectionObserver = ({ onReachPageEnd, deps }: Props) => {
  const loadMoreRef = React.useRef(null);
  const [hasToLoadMore, setHasToLoadMore] = React.useState(false);

  React.useEffect(() => {
    setHasToLoadMore(false);
    setTimeout(() => {
      setHasToLoadMore(true);
    }, 100);
  }, deps || []);

  React.useEffect(() => {
    const callback = (entries: any) => {
      const [entry] = entries;
      setHasToLoadMore(entry.isIntersecting);
    };
    const options = {
      root: null,
      rootMaring: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(callback, options);
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, []);

  React.useEffect(() => {
    if (hasToLoadMore) {
      onReachPageEnd();
    }
  }, [hasToLoadMore]);

  return { intersectionObserverRef: loadMoreRef };
};

export default useIntersectionObserver;
