import styled from 'styled-components';
import bg from '../../assets/images/splashbg.png';
export const Wrapper = styled.div`
    background-color: #1c1c1d;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
`;
export const ImgWrapper = styled.div`
    display: flex;
    width: 243.88px;
    height: 283.38px;
`;
export const Logo = styled.img`
    display: block;
    width: 100%;
`;
