import styled from 'styled-components';

export const NavigationWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
    '@media screen and (min-width: 768px)': {
        padding: '0px 16px',
        flexDirection: 'row',
        gap: '4px',
        justifyContent: 'center',
    },
});

export const DivisionsWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '22px',
    justifyItems: 'right',
    paddingRight: '10px',
    '@media screen and (min-width: 768px)': {
        padding: '13px',
    },
});

export const DivisionsImage = styled('img')({});
