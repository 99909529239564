import { FC, useContext, useEffect, useState } from 'react';
import PlayTabs from './PlayTabs/PlayTabs';
import {
  PlayTitleWrapper,
  PlayWrapper,
  PlayersWrapper,
  CardWrapper,
  BannerInfo,
  PointsInfo,
  AdsBannerContainer,
} from './Play.styled';
import { useNavigate, useParams } from 'react-router-dom';
import PlayerCard from '../PlayerCard';
// @ts-ignore
import nhlLogo from './images/nhl_logo.svg';
import { useWindowSize } from '../../helpers/useWidth';
import { Player } from './types';
import PepsiShutout from '../PepsiShutout/PepsiShutout';
import paths from '../../AppRouter/paths';
import Loader from '../Loader/Loader';
import AdsBanner from '../../Components/AdsBanner';
import { IPlayer, IQuestion } from '../../context/type';
import { PlayersContext } from '../../context/PlayersContext';
import wins_small from './images/wins_small.svg';
import saves_small from './images/saves_small.svg';
import goal_small from './images/goal_small.svg';
import shuffleIcon from './../../assets/icons/shuffleIcon.svg';
import PepsiLogo from './../../assets/images/2023PepsiLogo.png';
import { ArrowRightIcon } from '../../assets/icons/ArrowRightIcon';
import './animation.css';

type Props = {
  title: string;
  isPepsiShutout: boolean;
  bannerInfo?: string;
  pointsInfo?: string[];
  playerData?: Player[];
  isLoaded?: boolean;
  bigData?: any[] | any;
};
const CORNER_ICONS = [wins_small, goal_small, saves_small];
const Play: FC<Props> = ({ title, playerData, bannerInfo, pointsInfo, isPepsiShutout, isLoaded, bigData }) => {
  const { width } = useWindowSize();
  const isDesktop = width > 970;
  // const storageSelectedPlayers = JSON.parse(
  //     localStorage.getItem('selectedPlayers') as string
  // );

  const selectedData = JSON.parse(localStorage.getItem('data') as string)?.at(0)?.selections;
  if (!selectedData?.length) {
    localStorage.removeItem('fetchPlayers');
    localStorage.removeItem('selectedPlayers');
  }
  const [selectedPlayers, setSelectedPlayers] = useState<IPlayer[] | any[]>([{}, {}, {}]);
  const [activeSteps, setActiveSteps] = useState<boolean[]>([false, false, false]);
  const [fetchPlayers, setFetchPlayers] = useState<any[]>([{}, {}, {}]);
  const [useSelection, setUseSelection] = useState<boolean>(true);
  const [highlightCard, setHighlightCard] = useState<number | null>(null)

  const { allPlayersData } = useContext(PlayersContext);
  const location = window.location.pathname;
  const navigate = useNavigate();
  const { langId } = useParams();
  const selections = bigData && bigData.selections;
  const lng = localStorage.getItem('i18nextLng');
  localStorage.setItem('activeSteps', JSON.stringify(activeSteps));

  useEffect(() => {
    window.scrollTo({left: 0, top: 0})
  }, [])

  useEffect(() => {
    if (bigData?.selections) {
      setFetchPlayers(bigData.selections.splice(0, 4));
    }
  }, [bigData]);

  if (selections?.length && useSelection) {
    const selectionData =
      bigData &&
      bigData.questions
        .map((question: any, index: number) =>
          question.options.find((elem: any) => elem.option_id === selections[index]?.option_id)
        )
        .slice(0, 3);
    localStorage.setItem('selectedPlayers', JSON.stringify(selectionData));
    localStorage.setItem('activeSteps', JSON.stringify([true, true, true]));
    setSelectedPlayers(selectionData);
    setActiveSteps([true, true, true]);
    setUseSelection(false);
  }

  const getRandIndex = (length: number) => Math.floor(Math.random() * length)

  const randomPick = () => {
    const selected: Array<IPlayer | undefined> = []
    allPlayersData[0].questions.map((question: IQuestion, index: number) => {
      if (index === 3 || (selectedPlayers[index].start_utc && new Date(selectedPlayers[index].start_utc) < new Date())) {
        // shutout
        return
      } else {
        const path = index === 2 ? paths.shutout : undefined;
        let findPlayer: null | IPlayer = null
        const availableOptions: IPlayer[] = question.options.filter((opt) => (new Date(opt.start_utc) > new Date) && !selected.find(el => el?.title_en === opt.title_en && el?.description_en === opt.description_en))
        let rand = getRandIndex(availableOptions.length)
        const newPlayer = availableOptions.find((option: IPlayer, index: number) => {
          // const alreadySelected = selected.find(el => el?.title_en === option.title_en && el?.description_en === option.description_en);
          const matchStarted = new Date(option.start_utc) < new Date()

          if (matchStarted && index === rand) {
            return availableOptions.find(player => new Date(player.start_utc) > new Date())
          }
          
          if (!matchStarted && index === rand) {
            return availableOptions[rand]
          }
          
        })
        if (newPlayer && !selectedPlayers.find(player => player.title_en === newPlayer.title_en)) {
          selected[index] = newPlayer
        } else {
          selected[index] = selectedPlayers[index]
        }
      }
    })
    selected.map((player) => player && new Date(player.start_utc) < new Date ? player = undefined : player)
    setSelectedPlayers([selected[0] || selectedPlayers[0], selected[1] || selectedPlayers[1], selected[2] || selectedPlayers[2]])
    setActiveSteps([true, true, true])
    navigate(`/${langId}${paths.shutout}`)
  }

  const onPlayerSelect = (playerID: number, playerName: string, isDisabled?: boolean) => {
    if (allPlayersData[0].status !== 'UPCOMING' || isDisabled) {
      return;
    }

    if (
      location.includes(paths.wins) &&
      selectedPlayers[0]?.title_en !== playerName &&
      selectedPlayers[1]?.title_en !== playerName &&
      selectedPlayers[2]?.title_en !== playerName
    ) {
      playerSelectLogic(0, playerID, paths.save);
    }
    
    if (
      location.includes(paths.save) &&
      selectedPlayers[0]?.title_en !== playerName &&
      selectedPlayers[1]?.title_en !== playerName &&
      selectedPlayers[2]?.title_en !== playerName
      ) {
        playerSelectLogic(1, playerID, paths.goals);
    }

    if (
      location.includes(paths.goals) &&
      selectedPlayers[0]?.title_en !== playerName &&
      selectedPlayers[1]?.title_en !== playerName &&
      selectedPlayers[2]?.title_en !== playerName
      ) {
      playerSelectLogic(2, playerID, paths.shutout);
    }

  };
  const playerSelectLogic = (playerIndex: number, playerID: number, path?: string,) => {
    const selectedPlayer = [...selectedPlayers]
    if (new Date() > new Date(selectedPlayer[playerIndex].start_utc)) {
      setHighlightCard(selectedPlayer[playerIndex].option_id)
      setTimeout(() => {
        setHighlightCard(null);
      }, 2000)
      return
    }
    const newPlayer = allPlayersData[0].questions[playerIndex].options?.find((player: any) => player.option_id === playerID)

    if (newPlayer) {
      selectedPlayer[playerIndex] = newPlayer
    }

    localStorage.setItem('selectedPlayers', JSON.stringify(selectedPlayer));

    setSelectedPlayers(selectedPlayer);
    setFetchPlayers((fetchPlayers) => {
      fetchPlayers[playerIndex] = {
        question_id: bigData && bigData.questions[playerIndex].question_id,
        option_id: playerID,
      };
      return fetchPlayers;
    });

    setActiveSteps((activeSteps) => {
      activeSteps[playerIndex] = true;
      return activeSteps;
    });

    if (path) { navigate(`/${langId}${path}`);}
    window.scrollTo(0, 0);
    localStorage.setItem('fetchPlayers', JSON.stringify(fetchPlayers));
  };

  return (
    <PlayWrapper>
      <PlayTabs activeSteps={activeSteps} />
      <PlayTitleWrapper style={{minHeight: width > 768 ? '120px' : '50px'}}>
        {/* <img src={PepsiLogo} style={{width: width > 768 ? '52px' : '32px', height: width > 768 ? '52px' : '32px', top: width > 768 ? '20px' : '7px'}}/> */}
          <AdsBannerContainer style={{position: 'relative', transform: 'scale(0.7)', maxWidth: '70px', left: '-8px', top: '-4px'}}>
            <AdsBanner slotSize={[100, 70]} />
          </AdsBannerContainer>
        <div className={'content'} style={!isDesktop ? {justifyContent: 'center'} : undefined}>
          <p style={width < 400 && !isPepsiShutout ? {marginLeft: '36px'} : {}}>{width > 768 ? bannerInfo : title}</p>

          {width > 768 && <span style={{display: 'inline-block', background: '#18181E', borderRadius: '9px', padding: '14px 10px', minWidth: '40%'}}>
            {pointsInfo}
          </span>}
          

        </div>

        {!isPepsiShutout && <button
          style={{
            // position: 'absolute',
            // right: '16px',
            // top: '24px',
            backgroundColor: '#292933',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: 'none',
            width: isDesktop ? '115px' : '95px',
            color: 'white',
            paddingLeft: '4px',
            cursor: bigData?.selections.length || isPepsiShutout ? 'not-allowed' : 'pointer',
            opacity: bigData?.selections.length || isPepsiShutout ? '50%' : '100%',
          }}
          disabled={isPepsiShutout}
          onClick={randomPick}
        >
          Random Picks
          <img src={shuffleIcon} style={{padding: '2px 4px', borderRadius: '12px'}}/>
        </button>
        }

      </PlayTitleWrapper>

      {bannerInfo && width < 768 && window.location.pathname !== paths.shutout && (
        <BannerInfo>
          <p>{bannerInfo}</p>
        </BannerInfo>
      )}

      {pointsInfo &&
        width < 768 &&
        window.location.pathname !== paths.shutout &&
        (pointsInfo.length === 1 ? (
          <PointsInfo>
            <p>{pointsInfo}</p>
          </PointsInfo>
        ) : (
          <PointsInfo>
            <div className={'grid'}>
              <p>{pointsInfo}</p>
            </div>
          </PointsInfo>
        ))}

      <PlayersWrapper>
        {!isLoaded ? (
          <Loader />
        ) : (
          <div className={'PlayersWrapperWidth'} id='touch-action-img'>
            {!isPepsiShutout ? (
              <div className={'players'}>
                {playerData &&
                  playerData.map((player: Player) => {
                    const {
                      title_en,
                      title_fr,
                      option_id,
                      option_image,
                      option_secondary_image,
                      option_sponsor_image,
                      description_en,
                      description_fr,
                      start_utc,
                    } = player;

                    const { jersey_number, opp_name, stat_1, stat_2, stat_3 } = player.data;

                    let bgColor: string = 'blue';
                    let cornerIcon: JSX.Element | undefined = undefined;
                    selectedPlayers.find((selectedPlayer: IPlayer, index: number) => {
                      // @ts-ignore
                      if (option_id === selectedPlayer.option_id || (title_en === selectedPlayer.title_en && description_en === selectedPlayer.description_en)) {
                        cornerIcon = (
                          // <CornerIconPlayContainer>
                            <img src={CORNER_ICONS[index]} alt="Selected goalie!"/>
                          // </CornerIconPlayContainer>
                        );
                        bgColor = 'red';
                      }
                    });
                    const playerSelectDisabled = new Date() > new Date (start_utc)
                    return (
                      <CardWrapper key={option_id} onClick={() => onPlayerSelect(option_id, title_en, playerSelectDisabled)}
                        style={highlightCard === player.option_id ? {
                          WebkitBoxShadow: '0px 0px 24px 5px rgba(128,0,24,1)',
                          MozBoxShadow: '0px 0px 24px 5px rgba(128,0,24,1)',
                          boxShadow: '0px 0px 24px 5px rgba(128,0,24,1)',
                          transition: 'box-shadow 0.3s ease-in-out',
                          borderRadius: '16px',
                        } : undefined}
                      >
                        <PlayerCard
                          name={lng === 'en' ? title_en : title_fr}
                          team={lng === 'en' ? description_en : description_fr}
                          playerNum={jersey_number}
                          playerPhoto={option_image}
                          teamLogo={option_secondary_image ? option_secondary_image : nhlLogo}
                          opposingTeam={opp_name}
                          bgColor={bgColor}
                          CornerIcon={cornerIcon}
                          stats={{stat_1, stat_2,stat_3}}
                          disabled={playerSelectDisabled}
                          opp_image={option_sponsor_image}
                        />

                      </CardWrapper>
                    );
                  })}
              </div>
            ) : (
              // fetchPlayers - data ready for fetching
              <PepsiShutout
                selectedPlayers={selectedPlayers}
                selectionArray={fetchPlayers}
                setSelectionArray={setFetchPlayers}
              />
            )}
            {window.location.pathname !== paths.shutout && (
              isDesktop ? <AdsBanner slotSize={[970, 250]} /> : <AdsBanner slotSize={[300, 250]} />
            )}
          </div>
        )}
      </PlayersWrapper>
    </PlayWrapper>
  );
};

export default Play;
