import React, { useCallback, useState } from 'react';
import Button from '../Button';
import InputForm from '../InputForm';
import { CreateLeagueProps } from './types';
import { useLazyQuery } from '../../hooks/useLazyQuery';
import { checkLeagueName, createLeague } from '../../api/pickems';
import ModalWindow from '../ModalWindow';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../utils/types/types';

const CreateLeague: React.FC<CreateLeagueProps> = ({
    openCreate,
    setOpenCreate,
    setOpenJoin,
    refetchData,
    maxItem,
    isLoaded,

}) => {
    const { fetchData, isLoading } = useLazyQuery({
        fetcher: (title: string) => createLeague(title),
    });

    const [createItem, setCreateItem] = useState('');
    const [code, setCode] = useState('');
    const [showCreateResult, setShowCreateResult] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [resultCreate, setResultCreate] = useState(false);
    const [checkNameValue, setCheckNameValue] = useState(false);

    const { t } = useTranslation();
    const trs: ITrs = t('trs', {
        returnObjects: true,
    });

    const handleOpenCreate = useCallback(() => {
        setOpenCreate(true);
        setOpenJoin(false);
    }, [setOpenCreate]);

    const updateCreateItem = (e: any) => {
        const { value } = e.target;
        const message = value.slice(0, 20);

        setCreateItem(message);
    };

    const isButtonDisabled = createItem.length <= 5 || isLoading;

    const handleCreateLeague = async () => {
        const data = await fetchData(createItem);
        const checkName = await checkLeagueName(createItem);

        if (data) {
            setCode(data?.code);
            await refetchData();
            setShowCreateResult(true);
            setResultCreate(true);
            setTimeout(() => {
                setResultCreate(false);
                setShowShare(true);
            }, 3000);
        } else {
            if(checkName.exists) {
                setCheckNameValue(true);
                setShowCreateResult(true);
                setResultCreate(false);
                setTimeout(() => {
                    setOpenCreate(false);
                    setCreateItem('');
                    setShowCreateResult(false);
                    setCheckNameValue(false);
                }, 3000);
            } else {
                setShowCreateResult(true);
                setResultCreate(false);
                setTimeout(() => {
                    setOpenCreate(false);
                    setCreateItem('');
                    setShowCreateResult(false);
                }, 3000);
            }
        }
    };

    const closeCreate = () => {
        setOpenCreate(false);
        setShowShare(false);
        setCreateItem('');
        setShowCreateResult(false);
        setCode('');
    };

    return (
        <>
            {!openCreate ? (
                <Button
                    handleClick={handleOpenCreate}
                    fullWidth
                    text={trs.crateLeag}
                    buttonMargin
                />
            ) : showShare ? (
                 <ModalWindow
                     handleClick={closeCreate}
                     code={code.toUpperCase()}
                     nameLeague={createItem.toUpperCase()}
                 />
            ) : (
                <InputForm
                    item={createItem}
                    updateItem={updateCreateItem}
                    isButtonDisabled={isButtonDisabled}
                    checkItem={handleCreateLeague}
                    showResult={showCreateResult}
                    correctText={trs.leagCreated}
                    incorrectText={checkNameValue ? trs.invalidNameLeague : trs.invalid}
                    resultItem={resultCreate}
                    textInfo={trs.enterName}
                    maxItem={maxItem}
                    isLoaded={isLoaded}
                />
            )}
        </>
    );
};

export default CreateLeague;
