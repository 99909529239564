import { ChevronLeft } from "../../assets/icons/ChevronLeft"
import PepsiIcon from '../../assets/images/2023PepsiLogo.png';
import { FlexContainer } from "../ApplicationHeader/ApplicationHeader.styles"
import Button from "../Button/Button"
import { useWindowSize } from "../../helpers/useWidth"
import { leaderboardPlayer } from "../Overall/Overall"
import { IPickem, IPlayer, IQuestion, ISelection } from "../../context/type"
import { useEffect, useState } from "react"
import { useUserPickemSelections } from "../../api/hooks/useUserPickemSelections"
import { HelmetIcon } from './../../pages/PepsiShutout/images/HelmetIcon';
import { PuckIcon } from './../../pages/PepsiShutout/images/PuckIcon';
import { GoalNetIcon } from './../../pages/PepsiShutout/images/GoalNetIcon'
import Loader from "../../pages/Loader/Loader"
import { CornerIconContainer } from "../../pages/PlayerCard/playerCard.styled"

const icons = [HelmetIcon,
  PuckIcon,
  GoalNetIcon,]

const WritersPicks = ({currentPickem, pinnedIds, leaderboard}:{currentPickem?: IPickem, pinnedIds?: Array<any>, leaderboard: leaderboardPlayer[] }) => {
    const { width } = useWindowSize();
    const isDesktop = width > 970;
    const [writersPicks, setWritersPicks] = useState<ISelection[][]>([[], [], []])
    const [currentWriterPick, setCurrentWriterPick] = useState<number>(0)
    const {isUserSelectionsLoading, fetchData, userSelections} = useUserPickemSelections({userId: pinnedIds?.at(currentWriterPick), pickemId: currentPickem?.pickem_id});

    useEffect(() => {
        if (!isUserSelectionsLoading && pinnedIds?.at(currentWriterPick) && currentPickem?.pickem_id && !writersPicks.at(currentWriterPick)?.length) {
            fetchData({userId: pinnedIds?.at(currentWriterPick), pickemId: currentPickem.pickem_id}).then((response) => {
                if(response?.selections.length){
                    const newPicks = [...writersPicks];
                    newPicks[currentWriterPick] = response?.selections
                    setWritersPicks(newPicks);
                }
                return response
            });
        }
    }, [pinnedIds?.at(currentWriterPick), currentPickem, currentWriterPick])

    const shutoutTeam = currentPickem?.questions[3].options.find((option: IPlayer, index) => option?.option_id === writersPicks.at(currentWriterPick)?.[3]?.option_id)
    const username = leaderboard?.find((player) => player.user_id.toString() === pinnedIds?.at(currentWriterPick)?.toString())?.username
    return (
        <div style={{height: '330px', width: '100%', maxWidth: '1100px', margin: 'auto', padding: isDesktop ? '22px 58px 30px 58px' : '22px 24px 30px 24px', background: '#292933', borderRadius: isDesktop ? '0px 0px 25px 25px': '0px'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '24px'}}>
                <span style={{fontFamily: "Sintony", fontSize: isDesktop ? '36px' : '18px', fontWeight: 700}}>Writers Picks</span>
                <br/>
                <span style={{display: "inline-block"}}>{username}</span>
            </div>
            <FlexContainer customStyles={{justifyContent: 'space-between', minHeight: '148px'}}>
                <div onClick={() => {setCurrentWriterPick(prev => prev === 0 ? 2 :  currentWriterPick - 1)}}>
                    <Button endIcon={<ChevronLeft/>} customStyle={{padding: '8px 10px'}} />
                </div>

                <FlexContainer>

                    {writersPicks.at(currentWriterPick)?.length && !isUserSelectionsLoading ? currentPickem?.questions.map((question: IQuestion, index: number) => {
                        if (index === 3) {return}
                        const questionSelection = question.options.find((option: IPlayer) => option.option_id === writersPicks.at(currentWriterPick)?.at(index)?.option_id)
                        const isShutout = shutoutTeam && (questionSelection?.title_en === shutoutTeam.title_en && questionSelection.description_en === shutoutTeam.description_en)
                        return (
                            <div
                                style={{top: isDesktop && index === 2 ? '5px' : 'unset', height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
                                key={index}
                            >

                                {isShutout && <CornerIconContainer style={isDesktop ? {right: '24px'} : {top: '-12px', right: '-12px'}}>
                                    <img src={PepsiIcon} style={{width: '32px', height: '32px'}}/>
                                </CornerIconContainer>}

                                <img
                                    src={questionSelection?.option_secondary_image}
                                    style={{
                                        width: isDesktop ? '50%' : '20vw',
                                        marginBottom: isDesktop  ? '16px' : '8px',
                                    }}
                                />

                                {index < 3 && 
                                (index === 0 ? (
                                    <div style={{position: "absolute", borderRadius: '50%', top:'72%', background: '#292933'}}>
                                        <HelmetIcon width={42} height={42}/>
                                    </div>
                                ) : index === 1 ? (
                                    <div style={{position: "absolute", borderRadius: '50%', top:'72%', background: '#292933'}}>
                                        <PuckIcon width={42} height={42}/>
                                    </div>
                                ) : (
                                    <div style={{position: "absolute", borderRadius: '50%', top:'72%', background: '#292933'}}>
                                        <GoalNetIcon width={42} height={42}/>
                                    </div>
                                ))}

                                    {index < 3 && 
                                    (index === 0 ? (
                                        <div style={{position: 'relative', textAlign: "center", textTransform: 'uppercase', borderRadius: '50%', top:isDesktop ? '28px' : '66px', background: '#292933'}}>
                                            Wins
                                        </div>
                                    ) : index === 1 ? (
                                        <div style={{position: 'relative', textAlign: "center", textTransform: 'uppercase', borderRadius: '50%', top:isDesktop ? '28px' : '66px', background: '#292933'}}>
                                            Saves
                                        </div>
                                    ) : (
                                        <div style={{position: 'relative', textAlign: "center", textTransform: 'uppercase', borderRadius: '50%', top:isDesktop ? '28px' : '60px', background: '#292933'}}>
                                            Goals allowed
                                        </div>
                                    ))}

                            </div>
                        )
                    }) : <Loader />}
                {/* /team icons/ */}
                    
                </FlexContainer>
                
                <div
                    style={{transform: 'rotate(180deg)'}}
                    onClick={() => {setCurrentWriterPick(prev => prev === 2 ? 0 : currentWriterPick + 1)}}
                >
                    <Button endIcon={<ChevronLeft/>} customStyle={{padding: '8px 10px'}}/>
                </div>
            </FlexContainer>
        </div>
    )
}

export default WritersPicks
