// @ts-ignore
import instagram from './icons/instagram.svg';
// @ts-ignore
import twitter from './icons/twitterx.png';
// @ts-ignore
import tiktok from './icons/tiktok.svg';
import Facebook from './icons/Facebook.svg';

export const linkData = [
    {
        linkName: 'Instagram',
        path: 'https://www.instagram.com/nhl/',
        frPath: 'https://www.instagram.com/nhleurope/',
        logo: instagram,
    },
    {
        linkName: 'Twitter',
        path: 'https://twitter.com/NHL',
        frPath: 'https://twitter.com/LNH_FR',
        logo: twitter,
    },
    {
        linkName: 'Facebook',
        path: 'https://www.facebook.com/NHL',
        frPath: 'https://www.facebook.com/NHL',
        logo: Facebook,
    },
    {
        linkName: 'TikTok',
        path: 'https://www.tiktok.com/@nhl?lang=en',
        frPath: 'https://www.tiktok.com/@nhl?lang=fr',
        logo: tiktok,
    },
];
