import React, { useCallback, useState } from 'react';
import Button from '../Button';
import InputForm from '../InputForm';
import { JoinLeagueProps } from './types';
import { useLazyQuery } from '../../hooks/useLazyQuery';
import { joinLeague } from '../../api/pickems';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../utils/types/types';

const JoinLeague: React.FC<JoinLeagueProps> = ({
    openJoin,
    setOpenJoin,
    setOpenCreate,
    refetchData,
    maxItem,
    isLoaded,
}) => {
    const { fetchData, isLoading } = useLazyQuery({
        fetcher: (code: string) => joinLeague(code),
    });
    const [joinItem, setJoinItem] = useState('');
    const [showJoinResult, setShowJoinResult] = useState(false);
    const [joinResult, setJoinResult] = useState(false);
    const { t } = useTranslation();
    const trs: ITrs = t('trs', {
        returnObjects: true,
    });

    const handleOpenJoin = useCallback(() => {
        setOpenJoin(true);
        setOpenCreate(false);
    }, [setOpenJoin]);

    const updateJoinItem = (e: any) => {
        const { value } = e.target;
        const message = value.slice(0, 6);

        setJoinItem(message);
    };

    const isButtonDisabled = joinItem.length !== 6 || isLoading;

    const handleJoinLeague = async () => {
        const data = await fetchData(joinItem);
        if (!data?.success) {
            setShowJoinResult(true);
            setJoinResult(false);
            setTimeout(() => {
                setOpenJoin(false);
                setJoinItem('');
                setShowJoinResult(false);
            }, 3000);
        } else {
            refetchData();
            setShowJoinResult(true);
            setJoinResult(true);
            setTimeout(() => {
                setOpenJoin(false);
                setJoinItem('');
                setShowJoinResult(false);
            }, 3000);
        }
    };


    return (
        <>
            {!openJoin ? (
                <Button
                    handleClick={handleOpenJoin}
                    fullWidth
                    text={trs.joinLeag}
                    buttonMargin
                />
            ) : (
                <InputForm
                    join={true}
                    item={joinItem}
                    updateItem={updateJoinItem}
                    isButtonDisabled={isButtonDisabled}
                    checkItem={handleJoinLeague}
                    showResult={showJoinResult}
                    correctText={trs.acces}
                    incorrectText={trs.incorect}
                    resultItem={joinResult}
                    textInfo={trs.enterCode}
                    maxItem={maxItem}
                    isLoaded={isLoaded}
                />
            )}
        </>
    );
};

export default JoinLeague;
