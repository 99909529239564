import { useContext, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import paths from "../../AppRouter/paths";
import pepsiIcon from "../../assets/images/2023PepsiLogo.png";
import {
  LeagueMember,
  LeagueMemberIndex,
  LeagueMemberName,
  LeagueMemberScore,
} from "../../pages/LeaguePages/AdminLeaguePage.styles";
import { getPickemLeaderboard } from "../../api/weeklyApi";
import Loader from "../../pages/Loader/Loader";
import { leaderboardPlayer } from "../Overall/Overall";
import { useAdvancedPagination } from "../../hooks/useAdvancedPagination";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { PlayersContext } from "../../context/PlayersContext";
import { usePickemLeaderboardUserPosition } from "../../api/hooks/usePickemLeaderboardUserPosition";
import WritersPicks from "./WritersPicks";

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max);
};

const WeeklyTabPage = ({
  currentPickemIndex,
}: {
  currentPickemIndex: number;
}) => {
  const navigate = useNavigate();
  const { pickemsList, fetchPickemsList, isLoaded } = useContext(PlayersContext);
  const [filteredAllPlayersData, setFilteredAllPlayersData] = useState(pickemsList.length ? pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY") : [])
  const [pickemData, setPickemData] = useState(filteredAllPlayersData[currentPickemIndex]);
  const [pinnedIds, setPinnedIds] = useState<Array<any>>()
  const [currentUserPositionData, setCurrentUserPositionData] = useState<leaderboardPlayer | null>(null);
  const { langId } = useParams();

  useEffect(() => {
    if (!pickemsList.length || !isLoaded) {
      fetchPickemsList()
    }
  }, [])

  useEffect(() => {
      if (pickemsList?.length) {
        setFilteredAllPlayersData(pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY"))
      }
  }, [pickemsList])

  useEffect(() => {
    if (pickemData?.start !== filteredAllPlayersData[currentPickemIndex]?.start) {
      setPickemData(filteredAllPlayersData[currentPickemIndex]);
    }
  }, [currentPickemIndex]);

  const { isUserLoading, result } = usePickemLeaderboardUserPosition(
    pickemData?.pickem_id
  );

  useEffect(() => {
    if (!isUserLoading && result) {
      const userPosData = (result.position)
          ? result.position
          : null;
      setCurrentUserPositionData(userPosData);
    } else if (currentUserPositionData) {
      setCurrentUserPositionData(null)
    }
  }, [isUserLoading, result]);

  const { list, isLoading, onLoadMore } = useAdvancedPagination<
    any,
    leaderboardPlayer
  >({
    fetcher: ({ offset, limit }) =>
      getPickemLeaderboard({
        offset,
        limit,
        pickemId: filteredAllPlayersData[currentPickemIndex].pickem_id,
      }),
    deps: [pickemData, pickemData?.pickem_id, isLoaded, currentPickemIndex],
    execute: pickemData,
    mapResult: (data: any) => {setPinnedIds(data?.pinnedUserIds); return data?.leaderboard},
  });
  const [usersListToRender, setUsersListToRender] = useState<leaderboardPlayer[]>(list);

  const { intersectionObserverRef } = useIntersectionObserver({
    onReachPageEnd: onLoadMore,
    deps: [currentPickemIndex],
  });

  const handleLeagueMemberClick = (userId: number) => {
    navigate(`/${langId}${paths.leaderboardScores}/${userId.toString()}/${filteredAllPlayersData[currentPickemIndex].pickem_id}`);
  };

  const isUserInTop9 = currentUserPositionData
    ? list
        .slice(0, 8)
        .find((item) => item.user_id === currentUserPositionData.user_id)
    : false;
  const indexofUserInList = list.find((user, index) => {
    if (user?.user_id === currentUserPositionData?.user_id) {
      return index;
    }
  });
  if (!isUserInTop9 && indexofUserInList) {
    list.splice(list.indexOf(indexofUserInList), 1);
  }

  useEffect(() => {
    if (currentUserPositionData) {
      if (isUserInTop9) {
        setUsersListToRender(list);
      } else {
        setUsersListToRender([currentUserPositionData, ...list]);
      }
    } else {
      setUsersListToRender(list);
    }
    // setUsersListToRender((currentUserPositionData && !isUserInTop9 ? [currentUserPositionData, ...list] : list))
  }, [list, isUserInTop9]);

  return (
    <>
    <WritersPicks currentPickem={pickemData} pinnedIds={pinnedIds} leaderboard={list}/>
    <div style={{ maxWidth: "500px", margin: "auto" }}>
      {usersListToRender.map((member: leaderboardPlayer, index: number) => (
        <LeagueMember
          key={index}
          onClick={() => handleLeagueMemberClick(member.user_id)
          }
          style={{
            background:
              currentUserPositionData &&
              member.user_id === currentUserPositionData.user_id
                ? "#0025FF"
                : "#10100F",
          }}
        >
          <LeagueMemberIndex>{member.rank}</LeagueMemberIndex>
          <LeagueMemberName>
            <img src={pepsiIcon}  style={{width: '40px', marginRight: '18px'}}/>
            {member.username}
          </LeagueMemberName>
          <LeagueMemberScore>{member.points}</LeagueMemberScore>
        </LeagueMember>
      ))}
      <div>
        <div style={{ color: "rgba(0,0,0,0.0)", textAlign: "center" }}>end</div>
        <div ref={intersectionObserverRef} />
      </div>
      {isLoading && <Loader />}
    </div>
    </>
  );
};

export default WeeklyTabPage;
