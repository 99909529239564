import React from "react";
import { PlayerCardProps } from "./types";
import lockIcon from './../../assets/icons/lockIcon.svg';
import {
  PlayerCardWrapper,
  PlayerCardPhoto,
  PlayerCardLogo,
  PlayerCardInfoWrapper,
  PlayerImg,
  PlayerCardName,
  PlayerCardTeam,
  PlayerCardPosition,
  TeamLogoImg,
  OpposingTeam,
  TeamLogoContainer,
  PlayerCardStat,
  PlayerCardStatTitle,
  PlayerCardStatValue,
  DisabledOverlay,
  CornerIconContainer,
} from "./playerCard.styled";
import { useWindowSize } from "../../helpers/useWidth";

const PlayerCard: React.FC<PlayerCardProps> = ({
  playerNum,
  name,
  team,
  playerPhoto,
  teamLogo,
  nameSize,
  teamNameSize,
  positionSize,
  lazyLoad,
  opposingTeam,
  bgColor,
  CornerIcon,
  opp_name,
  opp_image,
  disabled = false,
  stats
  // isDragging,
  // isCornerDraggable,
  // cornerDraggableId,
}) => {
  const {width} = useWindowSize();
  const isDesktop = width > 768;
  return (
      <>
        <PlayerCardWrapper bgColor={bgColor} style={{minWidth: width > 500 ? '140px' : "110px"}}>
          {disabled && <DisabledOverlay >
              <img src={lockIcon} />
            </DisabledOverlay>}
          <CornerIconContainer>
            {CornerIcon}
          </CornerIconContainer>
          
          <TeamLogoContainer style={{opacity: disabled ? '50%' : '100%'}}>
            <TeamLogoImg src={teamLogo} alt="logo" loading="lazy" style={{userSelect: 'none', MozUserSelect: 'none',}} id='touch-action-img'/>
          </TeamLogoContainer>

      <div style={{display: 'flex', position: 'relative', top: '-75px' }}>
        <PlayerCardPhoto>
          <PlayerImg src={playerPhoto} alt="player" loading={lazyLoad} />
          {/* <PlayerCardName nameSize={nameSize}>{name}</PlayerCardName> */}
        </PlayerCardPhoto>
        {/* <PlayerCardPhoto>
          <PlayerImg src={playerPhoto} alt="player" loading={lazyLoad} />
          <PlayerCardName nameSize={nameSize}>{name}</PlayerCardName>
        </PlayerCardPhoto> */}
      </div>

      <PlayerCardTeam teamNameSize={teamNameSize} >
        <span style={{fontSize: '18px', fontWeight: 400,}}>{name}</span>
        <br/>
        <span style={{
          fontSize: team?.length >= 10 ? (team?.length >= 12 && !isDesktop &&  window.location.pathname.includes('shutout') ? '14px' : '18px') : '22px',
          fontWeight: 700,
          lineHeight: '18px',
        }}
          >{team}</span>
      </PlayerCardTeam>

      <PlayerCardInfoWrapper>
        <PlayerCardStat>
          <PlayerCardStatTitle>wins</PlayerCardStatTitle>
          <PlayerCardStatValue>{stats && stats?.stat_1}</PlayerCardStatValue>
        </PlayerCardStat>
        <PlayerCardStat>
          <PlayerCardStatTitle>sa / gp</PlayerCardStatTitle>
          <PlayerCardStatValue>{stats && stats?.stat_2}</PlayerCardStatValue>
        </PlayerCardStat>
        <PlayerCardStat>
          <PlayerCardStatTitle>SO</PlayerCardStatTitle>
          <PlayerCardStatValue>{stats && stats?.stat_3}</PlayerCardStatValue>
        </PlayerCardStat>
      </PlayerCardInfoWrapper>

      {/* <PlayerCardPosition className={'playerPosition'} positionSize={positionSize}>
        #{playerNum}
      </PlayerCardPosition> */}

      {opp_image &&
        <div style={{ width: '100%', textAlign: 'center', display: 'flex', alignItems: "center", justifyContent: "center", position: 'relative', top: '-92px'}}>
          VS <img src={opp_image} style={{width: '42px'}}/>
        </div>
      }

      </PlayerCardWrapper>
      </>
  );
};

export default PlayerCard;
