import {
    StyledButton, StyledShareButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
    PopupBackdrop,
    PopupBodyContainer,
    PopupContainer,
    P16W700,
    BlueGoalieContainer,
    PopupCrossContainer,
} from "../Popups/Popups.styles"
import Goalie from './images/2023ShareLeaguePopup.png'
import { IPopupProps } from '../PepsiShutout/types';
import { CaretRight } from '../../assets/icons/CaretRight';
import CrossIcon from '../../assets/icons/CrossIcon';
import paths from '../../AppRouter/paths';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import html2canvas from "html2canvas";
import {useWindowSize} from "../../helpers/useWidth";
import HockeyFieldBnw from './../../pages/PepsiShutout/images/HockeyFieldBnw.svg';
import { useContext, useEffect, useState } from 'react';
import { FetchLeaderboardUser } from '../../api/leaderbordUserApi';
import { PlayersContext } from '../../context/PlayersContext';
import { localStorageSet } from '../../utils/localStorage';

const CongratsPopup = ({ isVisible, setIsVisible, setIsShareVisible }: IPopupProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { langId } = useParams();
    const { width } = useWindowSize();
    const { allPlayersData,} = useContext(PlayersContext);
    const closeCross = () => {
        setIsVisible(false);
        navigate(`/${langId}${paths.myLeagues}`);
    };
    const [sharePath, setSharePath] = useState('https://goaliechallenge.nhl.com/en/home')
    useEffect(() => {
        if (sharePath === 'https://goaliechallenge.nhl.com/en/home') {
            FetchLeaderboardUser(allPlayersData[0].pickem_id).then((response) => {setSharePath(`https://goaliechallenge.nhl.com/${langId}${paths.leaderboardScores}/${response?.position?.user_id}/${allPlayersData[0]?.pickem_id}`); localStorageSet('sharePath', `https://goaliechallenge.nhl.com/${langId}${paths.leaderboardScores}/${response?.position?.user_id}/${allPlayersData[0]?.pickem_id}`)})
        }
    }, [])

    const exportAsPicture = async () => {
        const htmlToCanvasVis = document.getElementById(
            "PlayersWrapperWidthContainer",
        ) as HTMLElement;
        await html2canvas(htmlToCanvasVis, {
            allowTaint: true,
            useCORS: false,
            backgroundColor: `url(${HockeyFieldBnw})`,
        }).then(function (canvas) {
            (
                document.querySelector(".htmlToCanvasResult") as HTMLElement
            ).appendChild(canvas);
            canvas.style.width = `${width < 1920 ? '100%' : '700px'}`;
            canvas.style.height = "auto";
            canvas.style.borderRadius = "20px";
            canvas.style.transform = `${width < 768 ? 'scale(0.95)' : ''}`;
            // canvas.style.top = `${width > 1920 ? '50%' : ''}`;
            // canvas.style.left = `${width > 1920 ? '50%' : ''}`;
            // canvas.style.position = `${width > 1920 ? 'absolute' : ''}`;
        });
    };

    const openSharePopUp = async () => {
        await setIsVisible(false);
        if (setIsShareVisible) {
            setIsShareVisible(true);
            exportAsPicture();
        };
    }

    return (
        <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
            <PopupContainer>
            <PopupCrossContainer
                style={{position: "absolute", top: '-56px', right: '16px'}}
                onClick={() => {
                    closeCross();
                }}
            >
                <CrossIcon />
            </PopupCrossContainer>
                <BlueGoalieContainer>
                    <img
                        src={Goalie}
                        alt={'goalie'}
                    />
                </BlueGoalieContainer>
                <PopupBodyContainer>
                    <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                        {t('congrats')}
                    </h1>
                    <p style={{ marginTop: '8px', fontWeight: 400 }}>
                        {t('yourPick')}
                    </p>
                    <P16W700>
                        {t('checkOut')}
                    </P16W700>
                    <P16W700>
                        {t('comeBack')}
                    </P16W700>

                    <StyledButton
                        onClick={closeCross}
                    >
                        {t('createAndJoin')}
                        <CaretRight fill="white" />
                    </StyledButton>
                    <StyledShareButton
                        onClick={openSharePopUp}
                    >
                        Share Picks
                    </StyledShareButton>
                </PopupBodyContainer>
            </PopupContainer>
        </PopupBackdrop>
    );
};

export default CongratsPopup;
