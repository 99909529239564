import styled from "styled-components";

export const Wrapper = styled("div")<any>((prop) => ({
    display: "flex",
    justifyContent: prop.positionLeft ? "left" : "center",
    alignItems: "center",
    marginTop: "10px",
    '>>.img_ad': {
        width: '32px'
    }
}));