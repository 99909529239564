import { IDataDesProps } from '../types';

export const OnboardingDesktopData: IDataDesProps[] = [
    {
        title: 'Let’s Get Started',
        description: ['Welcome to the Pepsi Zero Sugar NHL Goalie Challenge!'],
        subDescription: [
            'Every Tuesday, select three NHL goalies and earn points based on their performance that night.',
        ],
        icons: false,
        screen: '',
    },
    {
        title: 'How to Play',
        description: [
            'Pick one goalie for each category:',
            'Wins, Total Saves & Fewest Goals Against',
        ],
        subDescription: [
            'ou cannot pick the same goalie for more than one category.',
        ],
        screen: '',
    },
    {
        title: 'Scoring',
        subDescription: [
            'If your goalie records a win, you earn 15 points',
            ' For every shot your goalie saves, you earn 1 point',
            'The fewer goals made against your pick, the more points you will earn',
        ],
        subDescriptionTwo: [
            '0 Goals Allowed = 10 points',
            '1 Goal Allowed = 7 points',
            '2 Goals Allowed = 5 points',
            '3 Goals Allowed = 3 points',
            '4 Goals Allowed = 1 point',
            '5+ Goals Allowed = 0 points',
        ],
        screen: '',
    },
    {
        title: 'Scoring',
        subDescription: [
            'Lastly, for 20 points, predict which one of your goalies will record a shutout Tuesday night',
        ],
        subDescriptionTwo: [],
        icons: true,
        screen: '',
    },
    {
        title: 'Challenge Friends',
        subDescription: [
            'Create a private league with friends or compare your weekly scores against all NHL fans playing the Pepsi Zero Sugar NHL Goalie Challenge.',
        ],
        subDescriptionTwo: [],
        screen: '',
    },
    {
        title: 'Prizing',
        subDescription: [
            'Earn prizes throughout the remainder of the 2023 NHL season. Compete each periodfor a chance to win NHL Shop codes, game tickets and more!For more information visit Official Rules.',
        ],
        subDescriptionTwo: [],
        screen: '',
    },
];
