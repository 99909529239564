import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bga from '../../../../assets/images/onboarding_background.png';

interface MobileSliderStylesProp {
  marginVariant?: boolean;
  textAlingVariant?: boolean;
}

export const OnboardingWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url('${bga}');
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 0px;
  position: relative;
`;

export const Title = styled('h2')({
  fontWeight: '700',
  fontSize: '24px',
  lineHeight: '130%',
  textAlign: 'center',
});

export const Image = styled('img')({
  marginTop: '15px',
  width: '100%',
  height: '40vh',
  objectFit: 'contain',
});

export const SupTitle = styled('p')({
  marginTop: '30px',
  padding: '10px 62px',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '140%',
  textAlign: 'center',
});

export const WrapperInfoText = styled('div')({
  padding: '0px 24px',
  textAlign: 'center',
});

export const Text = styled('p')<MobileSliderStylesProp>((prop) => ({
  marginTop: prop.marginVariant ? '15px' : 'none',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '140%',
  textAlign: prop.textAlingVariant ? 'initial' : 'center',
}));

export const ButtonWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SliderWrap = styled.div`
  height: 100vh;
}
  .slick-slider {
    box-sizing: border-box;
    background-color: var(--brand-black);
    .slick-list {
      .slick-track {
        .slick-slider.slide_item {
          height: 80vh;
          width: 80vw;
          background-size: cover;
          background-repeat: no-repeat;
          .slick-slide > div {
            margin: 0 30px;
            .slick-list {
              margin: 0 30px;
            }
          }
        }
      }
    }
  }
  @keyframes loading {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }

  .ft-slick__dots--custom {
    width: 6px;
    height: 6px;
    float: left;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s ease width;
    position: relative;
  }
  
  .slick-dots {
    bottom: 0px;
  }

  .slick-dots li {
    width: 4px;
    margin: 0 4px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 20px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 24px;
    top: 0px;
    overflow: hidden;
    background-color: white;
  }
  .dots {
    position: absolute;
    bottom: 200px;
    right: 0;
    left: 0;
    width: 200px;
    height: 20px;
    margin: 0 auto;
    transform: translateY(-50%);
    overflow: hidden;
    z-index: 1;
     @media (min-width: 400px)  {
       bottom: 30px;
  }
`;
