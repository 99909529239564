import React, { useContext, useEffect, useState } from 'react';
import Background from '../Background';
import { ILayoutProps } from './types';
import { Outlet, useLocation } from 'react-router-dom';
import SidebarHeader from './SidebarHeader';
import SidebarBottom from './SidebarBottom';
import SidebarLayout from '../SidebarLayout';
import ApplicationHeader from '../ApplicationHeader';
import Navigation from './Components/Navigation/Navigation';
import VideoAdPage from '../../pages/VideoAd/VideoAdPage';

const Layout: React.FC<ILayoutProps> = () => {
  const [isSidebarVisibleProp, setIsSidebarVisible] = useState(false);

  const { pathname } = useLocation();

  const handleSidebarOpen = React.useCallback(
    () => setIsSidebarVisible(true),
    [setIsSidebarVisible]
  );

  const handleSidebarClose = React.useCallback(
    () => setIsSidebarVisible(false),
    [setIsSidebarVisible]
  );

  useEffect(() => {
    handleSidebarClose();
  }, [pathname]);

  return (
    <>
      <VideoAdPage />
      <Background
        style={{ background: "#000000" }}
        onCloseClick={handleSidebarClose}
        isVisible={isSidebarVisibleProp}
      />
      <SidebarLayout
        isVisible={isSidebarVisibleProp}
        TopComponent={<SidebarHeader />}
        MiddleComponent={<Navigation />}
        BottomComponent={<SidebarBottom />}
      />
      <div>
        <ApplicationHeader votesAmount={0} onBurgerClick={handleSidebarOpen} />
        <main>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Layout;
