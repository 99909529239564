import styled from 'styled-components';

type Active = boolean;

interface StyledButtonProps {
  isActive: Active;
  rotateIcon?: boolean;
  fillIcon?: boolean;
}

interface CornerProps {
  isReversed: boolean;
  isActive: Active;
}

interface AdditionalContentProps {
  isVisible: Active;
}

export const StyledButton = styled('div')<StyledButtonProps>((props) => ({
  position: 'relative',
  width: '100%',
  background: props.isActive ? '#000000' : 'white',
  color: props.isActive ? 'white' : '#262626',
  borderRadius: '40px 0px 0px 40px',
  padding: '10px 0px 10px 0px',
  border: 'none',
  cursor: 'pointer',
  zIndex: props.isActive ? 3 : 2,
  svg: {
    transform: props.rotateIcon && props.isActive ? `rotate(180deg)` : 'rotate(0deg)',
  },
  'svg path': {
    stroke: props.isActive ? 'white' : '',
    fill: props.isActive && props.fillIcon ? 'white' : '',
  },

  '@media screen and (min-width: 768px)': {
    padding: '0',
    maxHeight: '48px',
    width: 'max-content',
    borderRadius: '4px 4px 0px 0px ',
  },
}));

export const ButtonContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: '17px',
  padding: '0 19px',
  letterSpacing: '1px',
  '@media screen and (min-width: 768px)': {
    columnGap: '10px',
    padding: '0',
    fontSize: '10px',
    height: '100%',
  },
});

export const Corner = styled('div')<CornerProps>((props) => ({
  opacity: props.isActive ? 1 : 0,
  background: props.isActive ? '#000000' : 'none',
  position: 'absolute',
  bottom: !props.isReversed ? '-21px' : 'none',
  top: props.isReversed ? '-21px' : 'none',
  right: 0,
  height: '21px',
  width: '21px',
  '@media screen and (min-width: 768px)': {
    display: 'none',
  },
  ':before': {
    content: "''",
    background: 'white',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: props.isReversed ? '0 0 40px 0' : '0 40px 0 0',
  },
}));

export const AdditionalContent = styled('div')<AdditionalContentProps>(({ isVisible }) => ({
  paddingTop: isVisible ? '19px' : 0,
  maxHeight: isVisible ? '999px' : 0,
  overflow: 'hidden',
  '@media screen and (min-width: 768px)': {
    position: 'absolute',
    backgroundColor: '#262626',
    borderRadius: '0px 0px 4px 4px',
    // padding: '-1px 4px 4px 4px',
    marginTop: '12px',
    right: '-5px',
    padding: isVisible ? '0px' : 0,
  },
}));

export const LinkBtn = styled('a')<StyledButtonProps>((props) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "10px",
  textDecoration: "none",
  color: props.isActive ? 'white' : '#262626',
}));
