import styled, { css, keyframes } from "styled-components";
import HockeyFieldBnw from "../PepsiShutout/images/HockeyFieldBnw.svg";

export const PlayWrapper = styled.div`
  //display: flex;
`;

export const AdsBannerContainer = styled.div`
  @media screen and (min-width: 768px){
    position: absolute;
    top: 0px;
    right: 0px;
  }
`;

export const PlayTitleWrapper = styled.div`
  background-color: #000;
  position: relative;
  font-family: 'Sintony', sans-serif;
  font-weight: 600;
  font-size: 17px;
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #fff;
 
  .content {
    position: relative;
    max-width: 840px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
  // }
  
  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 25px;
    .content {
      position: relative;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      span {
        display: inline-block;
        font-family: 'Sintony', sans-serif;
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        color: #999999;
      }
      p {
        max-width: 50%;
        // margin: auto;
      }
      img {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
`;

export const PlayersWrapper = styled.div`
  background: linear-gradient(0deg, #1D1D1B, #1D1D1B), #262626;
  padding: 0 14px;
  
  .PlayersWrapperWidth {
    width: 100%;
    margin: auto;
    
    .players {
      max-width: 800px;
      margin: 32px auto 0 auto;
      gap: 40px 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      @media screen and (min-width: 768px) {
        gap: 48px 16px;
      }
    }
      background-image: url(${HockeyFieldBnw});
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% -195px;
      @media screen and (min-width: 768px) {
      background-position: top;
      gap: 30px 0;
      padding-top: 60px;
      //background-size: cover;
      height: 100vh;
    }
  }
`;

export const CardWrapper = styled.div`
  max-width: 195px;
  width: max-content;
  @media screen and (max-width: 768px) {
    max-width: 165px;
  }

`;

export const BannerInfo = styled.div`
  background: linear-gradient(0deg, #1D1D1B, #1D1D1B), #262626;
  padding: 15px;
  
  p {
    font-family: 'Sintony', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    max-width: 250px;
    line-height: 23px;
    width: 100%;
    margin: auto;
    text-align: center;
  }
`;

export const PointsInfo = styled.div`
  background: #18181E;
  border-radius: 9px;
  padding: 16px 15px;
  
  .grid {
    //display: grid;
    //grid-template-columns: repeat(2, 1fr);
    //grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 10px;
    max-width: 300px;
    width: 100%;
    margin: auto;
  }
  
  p {
    font-family: 'Sintony', sans-serif;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: #999999;
  }
`;

export const CornerIconPlayContainer = styled.div`
  background: white;
  border-radius: 24px;
  padding: 4px 4px 2px 4px;
  box-shadow: 0 0 16px white;

  position: absolute;
  z-index: 5;
  top: -9%;
  right: -12%;
`
