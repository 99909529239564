import axios from "axios";
import { localStorageGet } from "../utils/localStorage";

export const FetchLeaderboardUser = async (pickemId: number) => {
  const allWeekly = `${process.env.REACT_APP_LOGIN_API_KEY}/pickem-leaderboard/${pickemId}/user`;

  const jwtToken = localStorageGet("jwt");
  try {
    const result = await axios.get(allWeekly, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
    const data = result.data;
    return data;
  } catch {
    throw Error("Promise failed");
  }
};
