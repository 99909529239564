import { useContext, useEffect, useState } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
    DroppableProvided,
    DroppableStateSnapshot,
    DraggableProvided,
    DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import PlayerCard from '../PlayerCard';
// import pepsiCanPng from './images/2023PepsiCan.png';
import pepsiCanPhoto from './images/2023PepsiCanPhoto_2.png';
import pepsiCanPhotoFrench from './images/2023PepsiCanPhotoFrench.png';
import pepsiCanNotAvailable from './images/2023PepsiCanNotAvailable.svg';
import {
    CanContainer,
    CanDescriptionContainer,
    Container,
    DragDropField,
    EmptyPepsiCanContainer,
    PepsiCanContainer,
    ScoringSpan,
    StyledButton,
    ThreeGoaliesContainer,
    UnderGoalieSpan,
} from './PepsiShutout.styles';
import { PointUpIcon } from '../../assets/icons/PointUpIcon';
import CongratsPopup from '../Popups/CongratsPopup';
import { CaretRight } from '../../assets/icons/CaretRight';
// import { PepsiIcon } from '../../assets/icons/PepsiIcon';
import PepsiLogo from './../../assets/images/2023PepsiLogo.png';
import PlusIcon from './images/PlusIcon.svg';
import { CornerIconContainer } from '../PlayerCard/playerCard.styled';
// import { HelmetIcon } from './images/HelmetIcon';
// import { PuckIcon } from './images/PuckIcon';
// import { GoalNetIcon } from './images/GoalNetIcon';
import { MouseIcon } from '../../assets/icons/MouseIcon';
import nhlLogo from '../Play/images/nhl_logo.svg';
import { StateContext } from '../../context/StateContex';
import { IShutoutProps } from './types';
import { ISelection, postSelections } from '../../api/pickems';
import { PlayersContext } from '../../context/PlayersContext';
import Loader from '../Loader/Loader';
import { IPlayer } from '../../context/type';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../AppRouter/paths';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../utils/types/types';
import GameAlreadyInPlayPopup from '../Popups/GameAlreadyInPlayPopup';
import { FetchSinglePickem } from '../../api/devisionApi';
import SharePopUp from "./SharePopUp/SharePopUp";
import { useWindowSize } from '../../helpers/useWidth';
import { localStorageGet } from '../../utils/localStorage';

const pepsiCanAvailable = true;

const PepsiShutout = ({
    selectedPlayers,
    selectionArray,
    setSelectionArray,
}: IShutoutProps) => {
    const [playersToRender, setPlayersToRender] = useState(selectedPlayers);
    const [isCanAvailable, setCanAvailable] = useState(pepsiCanAvailable);
    const [isButtonActive, setIsButtonActive] = useState(true);
    const [isSendingSelection, setIsSendingSelection] = useState(false);
    const [isGameInPlayPopup, setIsGameInPlayPopup] = useState(false);
    const [isShareVisible, setIsShareVisible] = useState(false);
    const { isCongratsPopupOpen, setIsCongratsPopupOpen, setIsVideoAdPopupOpen } = useContext(StateContext);
    const { fetchAllPlayersData, allPlayersData, handleFetchSinglePickem } = useContext(PlayersContext);
    const navigate = useNavigate();
    const width = useWindowSize().width
    const isDesktop = width > 768;
    const country = localStorageGet('country')

    // const lang = Cookies.get('i18next');
    // const locationUser = localStorageGet('location');
    // (locationUser && locationUser.country === 'CA') || lang === 'fr'
    //     ? pepsiCanPngFrench
    //     : 
    const currentPepsiCan = country !== 'Canada' ? pepsiCanPhoto : pepsiCanPhotoFrench;
    const { t } = useTranslation();
    const { langId } = useParams();
    const trs: ITrs = t('trs', {
        returnObjects: true,
    });

    useEffect(() => {
        if (!selectedPlayers[0]?.option_id) {
            navigate(`/${langId}${paths.wins}`);
        }

        if (selectedPlayers?.length) {
            selectedPlayers?.find((player) => {
                if (player.boosted) {
                    setCanAvailable(false);
                    return false;
                }
            });
        }
    }, [selectedPlayers]);

    useEffect(() => {
        if (selectionArray[3] && allPlayersData) {
            const BoostedPlayerFromSelection = allPlayersData[0].questions[3].options.find(
                (player: IPlayer) => {
                    if (player.option_id === selectionArray[3].option_id) {
                        return player;
                    }
                }
            );
            const isBoostedPlayerOnShutout = playersToRender.find(
                (player: IPlayer) => {
                    if (
                        player?.title_en ===
                        BoostedPlayerFromSelection?.title_en
                    ) {
                        return player;
                    }
                }
            );
            if (isBoostedPlayerOnShutout && isCanAvailable) {
                setPlayersToRender((prev) => {
                    const renderedPlayerToBoost = prev.find(
                        (player) =>
                            player.title_en ===
                            isBoostedPlayerOnShutout.title_en
                    );
                    if (renderedPlayerToBoost) {
                        renderedPlayerToBoost.boosted = true;
                    }
                    return prev;
                });
                setCanAvailable(false);
            }
        }
    }, [allPlayersData]);

    const handlePostSelection = async (pickemId: number) => {
        const checkData = await FetchSinglePickem(pickemId);
        const selectionsToBackend: ISelection[] = [] // preparing selections object for post request
        await allPlayersData[0].questions.forEach((question: any, index: number) => { // populating selections
            if (index < 3) { // first tree questions (not shutout boost)
                selectionsToBackend.push({question_id: question.question_id, option_id: selectedPlayers[index].option_id})
            } else if (index === 3) { // last question (shutout boost)
                const boostedPlayerName = selectedPlayers.find((player: IPlayer) => player.boosted)
                const boostedPlayerFromQuestion = question.options.find((player: IPlayer) => player.title_en === boostedPlayerName?.title_en)
                selectionsToBackend.push({question_id: question.question_id, option_id: boostedPlayerFromQuestion?.option_id})
            }
        })
        if (checkData.status === "IN_PLAY" || checkData.status === "COMPLETE") {
            setIsButtonActive(false)
            setIsGameInPlayPopup(true)
        } else if (checkData.status === "UPCOMING") {
            await postSelections(pickemId, selectionsToBackend);
            setIsCongratsPopupOpen(true);
            await handleFetchSinglePickem(pickemId);
        }
    };

    const handleSubmitButton = async () => {
        await setIsSendingSelection(true);
        const pickemId = allPlayersData[0].pickem_id;
        setIsVideoAdPopupOpen(true);
        handlePostSelection(pickemId);
        localStorage.removeItem('selectedPlayers');
        localStorage.removeItem('activeSteps');
        // setIsButtonActive(false);
        window.scrollTo(0, 0);
        // setIsSendingSelection(false);
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.source.droppableId === 'pepsi-can-available') {
            const playerToBoost = selectedPlayers.find((player, index) => {
                if (result.destination?.droppableId===`${player.option_id}` && new Date (player.start_utc) < new Date()) {
                    // return if dragDrop destination match has already started
                    return
                }

                if (result.destination?.droppableId===`${player.option_id}`) {
                    setSelectionArray((prev) => {
                        prev[3] = {
                            question_id:
                                allPlayersData[0].questions[3].question_id,
                            option_id:
                                allPlayersData[0].questions[3].options.find(
                                    (opt: IPlayer) => opt.title_en === player.title_en)?.option_id,
                        }; // modifying selection array for backend
                        return prev;
                    });
                    setPlayersToRender((prev) => {
                        prev[index].boosted = true;
                        return prev;
                    }); // modifying local array of players
                    setCanAvailable(false);
                    return player;
                }
            });

            if (playerToBoost?.boosted) {
                setPlayersToRender(selectedPlayers);
            }
        } else if (
            result.draggableId.includes('_boosted') &&
            result.destination.droppableId.includes('-not-')
        ) {
            // to unbust player
            const draggedPlayerId = result.source.droppableId

            setSelectionArray((prev) => {
                prev[3] = {
                    question_id: allPlayersData[0].questions[3].question_id,
                    option_id: 0,
                };
                return prev;
            });
            setPlayersToRender((prev) => {
                const draggedPlayer = prev.find((player) =>
                    player.option_id.toString().includes(draggedPlayerId)
                );
                if (draggedPlayer) {
                    draggedPlayer.boosted = false;
                }
                return prev;
            });
            setCanAvailable(true);
        } else if (
            result.draggableId.includes('_boosted') &&
            !result.destination.droppableId.includes('pepsi-can')
        ) {
            // to move boost from one player to another
            const draggedPlayerId = result.source.droppableId;
            const newPlayerToBoostId = result.destination.droppableId;
            setPlayersToRender((prev) => {
                const draggedPlayer = prev.find((player) =>
                    player.option_id.toString() === draggedPlayerId
                );
                const newPlayerToBoost = prev.find((player) =>
                    player.option_id.toString() === newPlayerToBoostId
                );
                if ((draggedPlayer && new Date(draggedPlayer?.start_utc) < new Date()) || (newPlayerToBoost && new Date(newPlayerToBoost?.start_utc) < new Date())) {
                    // return if matches started
                    return prev;
                }
                setSelectionArray((prev) => {
                    prev[3] = {
                        // setting selection array for backend
                        question_id: allPlayersData[0]?.questions[3].question_id,
                        option_id: allPlayersData[0]?.questions[3]?.options.find(
                            (opt: IPlayer) =>
                                opt.title_en === newPlayerToBoost?.title_en
                        )?.option_id,
                    };
                    return prev;
                });

                if (draggedPlayer && newPlayerToBoost) {
                    draggedPlayer.boosted = false;
                    newPlayerToBoost.boosted = true;
                }
                return prev;
            });
        }
    };

    useEffect(() => {
        const el = document.getElementById('dragdropTip') || document.getElementById("pepsiCan");
        el?.scrollIntoView({block: 'end', behavior: 'smooth'})
    }, [])

    const getStyle = (style: any, snapshot: any, player: IPlayer) => {
        if (!snapshot.isDropAnimating) {
          return style;
        }
        const cardWidth = width < 526 ? (width - 42) / 3 : 163;
        const { moveTo, curve, duration } = snapshot.dropAnimation;
        const translate = snapshot.draggingOver == player.option_id || !snapshot.draggingOver ? `translate(${moveTo.x}px, ${moveTo.y}px)`
        : `translate(${moveTo.x+cardWidth}px, ${moveTo.y - (width > 526 ? 12 : 0)}px)`;
      
        return {
          ...style,
          transform: `${translate}`,
          transition: `all ${curve} ${duration + 0.5}s`,
        };
      }

    return (
        <>
            <GameAlreadyInPlayPopup
                isVisible={isGameInPlayPopup}
                setIsVisible={setIsGameInPlayPopup}
            />
            <CongratsPopup
                isVisible={isCongratsPopupOpen}
                setIsVisible={setIsCongratsPopupOpen}
                setIsShareVisible={setIsShareVisible}
            />
            <SharePopUp
                isShareVisible={isShareVisible}
                setIsShareVisible={setIsShareVisible}
                child={<div className={"htmlToCanvasResult"}></div>}
            />
            <Container>
                <DragDropField id='PlayersWrapperWidthContainer'>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <ThreeGoaliesContainer className={'htmlToCanvasVis'}>
                            {playersToRender?.length &&
                                playersToRender.map((player: IPlayer, index) => {
                                    const matchStarted = new Date() > new Date(player.start_utc)
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                maxWidth: '309px',
                                                minHeight: '102px',
                                                maxHeight: '330px',
                                            }}
                                        >
                                            <Droppable
                                                droppableId={`${player.option_id}`}
                                                key={index}
                                            >
                                                {(
                                                    provided: DroppableProvided,
                                                    snapshot: DroppableStateSnapshot
                                                ) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                touchAction:'pan-y',
                                                                userSelect:'none',
                                                            }}
                                                        >
                                                            {player.boosted ? (
                                                                player && (
                                                                    <PlayerCard
                                                                        nameSize={'20px'}
                                                                        teamNameSize={'18px'}
                                                                        name={player.title_en}
                                                                        team={player.description_en}
                                                                        playerNum={player.data.jersey_number}
                                                                        playerPhoto={player.option_image}
                                                                        teamLogo={
                                                                            player.option_secondary_image
                                                                                ? player.option_secondary_image
                                                                                : nhlLogo
                                                                        }
                                                                        opposingTeam={player.data.opp_name}
                                                                        opp_name={player.data.opp_name}
                                                                        CornerIcon={
                                                                            <Draggable
                                                                                draggableId={`${player.option_id}_boosted`}
                                                                                key={player.option_id}
                                                                                index={index}
																				shouldRespectForcePress={false}
                                                                                isDragDisabled={allPlayersData[0].status !== "UPCOMING" || matchStarted}
                                                                            >
                                                                                {(
                                                                                    dragprovided: DraggableProvided,
                                                                                    dragsnapshot: DraggableStateSnapshot
                                                                                ) => {
                                                                                    return (
                                                                                        <div
                                                                                            ref={
                                                                                                dragprovided.innerRef
                                                                                            }
                                                                                            {...dragprovided.draggableProps}
                                                                                            {...dragprovided.dragHandleProps}
                                                                                            style={{
                                                                                                ...dragprovided.draggableProps.style,
                                                                                                ...getStyle(dragprovided.draggableProps.style, dragsnapshot, player),
                                                                                                userSelect: 'none',
                                                                                            }}
                                                                                        >
                                                                                            <CornerIconContainer
                                                                                                isDragging={
                                                                                                    dragsnapshot.isDragging
                                                                                                }
                                                                                            >
                                                                                                {/* <PepsiIcon /> */}
                                                                                                <img src={PepsiLogo} style={{width: '38px', height: '38px'}}/>
                                                                                            </CornerIconContainer>
                                                                                        </div>
                                                                                    );
                                                                                }}
                                                                            </Draggable>
                                                                        }
                                                                        stats={{stat_1: player.data.stat_1, stat_2: player.data.stat_2,stat_3: player.data.stat_3}}
                                                                        opp_image={player.option_sponsor_image}
                                                                    />
                                                                )
                                                            ) : (
                                                                // not boosted player
                                                                <div>
                                                                    {player ? (
                                                                        <PlayerCard
                                                                            nameSize={'20px'}
                                                                            teamNameSize={'18px'}
                                                                            name={player.title_en}
                                                                            team={player.description_en}
                                                                            playerNum={player.data?.jersey_number}
                                                                            playerPhoto={player.option_image}
                                                                            teamLogo={
                                                                                player.option_secondary_image
                                                                                    ? player.option_secondary_image
                                                                                    : nhlLogo
                                                                            }
                                                                            opposingTeam={player?.data?.opp_name}
                                                                            CornerIcon={
                                                                                snapshot.isDraggingOver ? (
                                                                                    <CornerIconContainer>
                                                                                        <img
                                                                                            src={
                                                                                                PlusIcon
                                                                                            }
                                                                                        />
                                                                                    </CornerIconContainer>
                                                                                ) : undefined
                                                                            }
                                                                            stats={{stat_1: player.data?.stat_1, stat_2: player.data?.stat_2,stat_3: player.data?.stat_3}}
                                                                            opp_image={player.option_sponsor_image}
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                            <UnderGoalieSpan>
                                                                {isDesktop && window.location.pathname === paths.home && 
                                                                        // <GoalNetIcon />
                                                                        <>
                                                                            <span style={{ display: 'inline-block', textTransform: 'uppercase', marginTop: '16px', fontSize: '18px', color: 'white'}}>Score</span>
                                                                            {/* <ScoringSpan>pasf</ScoringSpan> */}
                                                                        </>
                                                                    }

                                                                {/* {index === 2
                                                                    ? trs.goalsAgainst
                                                                    : index ===
                                                                      1
                                                                    ? trs.mostSaves
                                                                    : trs.wins} */}
                                                            </UnderGoalieSpan>
                                                            {/* {provided.placeholder} */}
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    );
                                })}
                        </ThreeGoaliesContainer>

                        {/* {width < 600 && isCanAvailable &&
                            <div className='arrowPulsateAnimation arrowPulsate' id="arrowPulsateId" onClick={() => document.getElementById('pepsiCan')?.scrollIntoView({block: 'end', behavior: 'smooth'})}>
                                <ArrowRightIcon fill="black"/>
                            </div>
                        } */}

                        <CanContainer>
                            <Droppable
                                droppableId={`pepsi-can-${
                                    !isCanAvailable ? 'not-' : ''
                                }available`}
                                isDropDisabled={isCanAvailable}
                            >
                                {(
                                    provided: DroppableProvided,
                                    snapshot: DroppableStateSnapshot
                                ) => {
                                    return (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                                width: '109px',
                                                margin: 'auto',
                                                touchAction: 'none',
                                                userSelect: 'none',
                                                WebkitTouchCallout: 'none',
                                            }}
                                        >
                                            {isCanAvailable ? (
                                                <>
                                                    <Draggable
                                                        draggableId="pepsiCan"
                                                        index={0}
                                                        isDragDisabled={allPlayersData[0].status !== "UPCOMING"}
                                                        shouldRespectForcePress={true}
                                                    >
                                                        {(
                                                            dragProvided: DraggableProvided,
                                                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                                            dragSnapshot: DraggableStateSnapshot
                                                        ) => {
                                                            return (
                                                                <div
                                                                    ref={dragProvided.innerRef}
                                                                    {...dragProvided.draggableProps}
                                                                    {...dragProvided.dragHandleProps}
                                                                    style={{
                                                                        ...dragProvided.draggableProps.style,
                                                                    }}
                                                                >
                                                                    <PepsiCanContainer>
                                                                        <img
                                                                            id="pepsiCan"
                                                                            src={
                                                                                currentPepsiCan
                                                                            }
                                                                            alt="pepsi can"
                                                                        />
                                                                    </PepsiCanContainer>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                    {snapshot.draggingFromThisWith && (
                                                        <EmptyPepsiCanContainer>
                                                            <img
                                                                src={
                                                                    pepsiCanNotAvailable
                                                                }
                                                                style={{
                                                                    borderRadius: '18px',
                                                                    // touchAction: 'none',
                                                                    // userSelect: 'none',
                                                                    // MozUserSelect: 'none',
																	// WebkitTouchCallout: 'none',
                                                                }}
                                                                alt={'Pepsi can is somewhere else'}
																id='touch-action-img'
                                                            />
                                                        </EmptyPepsiCanContainer>
                                                    )}
                                                </>
                                            ) : (
                                                //no can
                                                <div
                                                    style={{
                                                        width: '90px',
                                                        margin: 'auto',
                                                        height: '175px',
                                                        position: 'relative',
                                                        // WebkitTouchCallout: 'none',
                                                        pointerEvents: 'none'
                                                    }}
                                                >
                                                    <img
                                                        src={pepsiCanNotAvailable}
                                                        alt={'Pepsi can is somewhere else'}
                                                        style={{zIndex: 3,}}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            </Droppable>
                            <CanDescriptionContainer>
                                {isCanAvailable ? (
                                    isDesktop ? (
                                        <span id="dragdropTip">
                                            <MouseIcon />
                                            {t('dragDrop')}
                                        </span>
                                    ) : (
                                        <span id="dragdropTip">
                                            <PointUpIcon />
                                            {t('dragHold')}
                                        </span>
                                    )
                                ) : (
                                    <div>
                                        {isButtonActive && (
                                            <StyledButton
                                                style={{
                                                    width: '160px',
                                                    height: '60px',
                                                    alignItems: isSendingSelection ? 'flex-end' : 'center',
                                                }}
                                                id="shutoutSubmitBtn"
                                                onClick={() =>handleSubmitButton()}
                                            >
                                                {isSendingSelection ? (
                                                    <Loader color="white" />
                                                ) : (
                                                    <>
                                                        <span>
                                                            {t('submitGolie')}
                                                        </span>
                                                        <CaretRight fill="white" />
                                                    </>
                                                )}
                                            </StyledButton>
                                        )}
                                    </div>
                                )}
                            </CanDescriptionContainer>
                        </CanContainer>
                    </DragDropContext>
                </DragDropField>
            </Container>
        </>
    );
};

export default PepsiShutout;
