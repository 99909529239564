import React, { useContext, useEffect, useState } from 'react';
import { Wrapper, Title, Description, ImagePepsiLogo, DesktopImagePepsiLogo, Container } from './HomeBody.styles';
import PepsiLogo from '../../assets/images/2023onBlackMobileEn.png';
import PepsiLogoFR from '../../assets/images/2023onBlackMobileFr.png';
import Button from '../Button';
import { NavLink, useParams } from 'react-router-dom';
import paths from '../../AppRouter/paths';
import AdsBanner from '../AdsBanner';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../utils/types/types';
import { PlayersContext } from '../../context/PlayersContext';
import Loader from '../../pages/Loader/Loader';
import { localStorageGet, localStorageSet } from '../../utils/localStorage';
import UserSelectionsDisplay from './components/UserSelectionsDisplay';
import { useWindowSize } from '../../helpers/useWidth';
import { IPickem, IQuestion } from '../../context/type';
import CountdownTimer from '../CountdownTimer/CountownTimer';
import ConfirmationPopup from './components/ConfirmationPopup';
import { Banner } from './Banner/index';

const HomeBody: React.FC = () => {
  const { t } = useTranslation();
  const width = useWindowSize().width
  const isDesktop = width > 970;
  const trs: ITrs = t('trs', {
    returnObjects: true,
  });
  const lng = localStorage.getItem('i18nextLng');
  const { isLoaded, allPlayersData, allSelections, fetchAllPlayersData } = useContext(PlayersContext);
  const [isSelectionIn, setIsSelectionIn] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(localStorageGet('dec27InfoPopup') ? false : true);
  if (localStorageGet('infoPopup')) { 
    localStorage.removeItem('infoPopup')
  }

  const { langId } = useParams();
  const closeModalWindow = () => {
    setShowModal(false);
    localStorageSet("dec27InfoPopup", true)
  };
  const getPickemStatusStates = () => {
    if (allSelections?.length) {
      // user made selections
      setIsSelectionIn(true);
    } else {
      setIsSelectionIn(false);
    }
  };

  useEffect(() => {
    fetchAllPlayersData();
    if (localStorageGet('showing')) {
      localStorage.removeItem('showing');
    }
  }, []);

  useEffect(() => {
    getPickemStatusStates();
  }, [allPlayersData, lng]);

  const allPickemsQuestions: IQuestion[] = [];
  allPlayersData.map((pickem: IPickem, index: number) => {
      allPickemsQuestions.push(...pickem.questions)
  })

  return (
    <div
      style={{textAlign: 'center'}}
    >
      {/* <ConfirmationPopup isOpen={showModal} handleClose={closeModalWindow} /> */}
      <Banner /> {/* winter classic banner */}
      <Container>
        {allPlayersData[0]?.selections.length && isDesktop ? <UserSelectionsDisplay selections={allPlayersData[0]?.selections} questions={allPlayersData[0]?.questions}/> : <DesktopImagePepsiLogo src={lng === 'fr' ? PepsiLogoFR : PepsiLogo} />}
        <Wrapper>
          {isLoaded && allPlayersData[0].status === 'UPCOMING' && <CountdownTimer currentPickem={allPlayersData[0]} />}
          
          {isLoaded ? (
            <>
              <Title>
                {allPlayersData[0]?.status === 'IN_PLAY'
                  ? t('youGoalie')
                  : allPlayersData[0]?.status === 'COMPLETE'
                    ? t('result')
                    : allPlayersData[0]?.status === 'UPCOMING' && allPlayersData[0]?.selections.length
                      ? t('youGoalie') : t('ready')}
              </Title>

              <Description style={{ marginBottom: '0px' }}>
                {allPlayersData[0]?.status === 'IN_PLAY'
                  ? t('checkOutLive')
                  : allPlayersData[0]?.status === 'COMPLETE'
                    ? t('thisWeek')
                    : allPlayersData[0]?.status === 'UPCOMING' && allPlayersData[0]?.selections.length
                      ? (<>
                        {t('tnpuck')}{' '}
                        <a style={{ color: '#007EB9' }} href="https://www.nhl.com/stats/goalies" target="_blank">
                          NHL.com
                        </a>{' '}
                        {t('tnpuck2')}
                      </>)
                      : t('itsTime')}
              </Description>
              <Description>
                {allPlayersData[0]?.status === 'UPCOMING' && !allPlayersData[0]?.selections.length
                  ? (
                    <>
                      {t('allow')}{' '}
                      <a style={{ color: '#007EB9' }} href={langId === 'en' ? "https://nhlshop.com/" : "https://nhlshop.ca/"} target="_blank">
                        {langId === 'en' ? 'NHLShop.com' : 'NHLShop.ca'}
                      </a>{' '}
                      {t('allow2')}
                      <br />
                      <br />
                      {t('who')}
                    </>)
                    : null }
              </Description>
            </>
          ) : null}
            {!isDesktop && isLoaded && <NavLink to={`/${langId}${allPlayersData[0].status === 'UPCOMING' ? paths.wins : paths.overall}`} style={{ textDecoration: 'none', marginBottom: '24px'}}>
              <Button fullWidth text={!allPlayersData[0]?.selections.length ? trs.enterNow : (allPlayersData[0].status === 'UPCOMING' ? trs.seeyour : "View Leaderboard")}/>
            </NavLink>}
          {allPlayersData[0]?.selections.length && !isDesktop ? <UserSelectionsDisplay  selections={allPlayersData[0]?.selections} questions={allPlayersData[0]?.questions} /> : <ImagePepsiLogo src={lng === 'fr' ? PepsiLogoFR : PepsiLogo} />}
          {isLoaded && allPlayersData.length ? (
            isDesktop && <NavLink to={`/${langId}${allPlayersData[0].status === 'UPCOMING' ? paths.wins : paths.overall}`} style={{ textDecoration: 'none', position: 'relative', top: isDesktop ? 0 :'50px' }}>
              <Button fullWidth text={!allPlayersData[0]?.selections.length ? trs.enterNow : (allPlayersData[0].status === 'UPCOMING' ? trs.seeyour : "View Leaderboard")}/>
            </NavLink>
          ) : (
            <Loader color="white" />
          )}
          {/* {
                        <>
                            <Title>{t('youGoalie')}</Title>
                            <Description>{t('tnpuck')}</Description>
                            <ImagePepsiLogo src={PepsiLogo} />
                            <NavLink
                                to={paths.wins}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button fullWidth text={trs.seeyour} />
                            </NavLink>
                        </>
                    }
                    {
                        <>
                            <Title>{t('result')}</Title>
                            <Description>{t('thisWeek')}</Description>
                            <ImagePepsiLogo src={PepsiLogo} />
                            <NavLink
                                to={paths.wins}
                                style={{ textDecoration: 'none' }}
                            >
                                <Button fullWidth text={trs.seeyour} />
                            </NavLink>
                        </>
                    } */}
        </Wrapper>
      </Container>{' '}
      {isDesktop ? <AdsBanner slotSize={[970, 250]} /> : <AdsBanner slotSize={[300, 250]} customStyle={{marginTop: '48px'}} />}
      <div style={{ borderBottom: '15px solid #666666' }} />
    </div>
  );
};

export default HomeBody;
