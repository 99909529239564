import React from 'react';
import MobileSlider from './Components/MobileSlider';
import DesktopSlider from './Components/DesktopSlider';
import { localStorageGet } from '../../utils/localStorage';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../AppRouter/paths';
import { CloseButton, CloseButtonMob } from './Onboarding.styles';
import { useWindowSize } from '../../helpers/useWidth';

const Onboarding: React.FC = () => {
    const width = useWindowSize().width
    const isDesktop = width > 768;
    const visible = localStorageGet('user');
    const navigate = useNavigate();
    const { langId } = useParams();
    return (
        <>
            {isDesktop ? (
                <>
                    {visible && (
                        <CloseButton onClick={() => navigate(`/${langId}${paths.home}`)}>
                            &#215;
                        </CloseButton>
                    )}
                    <DesktopSlider />
                </>
            ) : (
                <>
                    {visible && (
                        <CloseButtonMob onClick={() => navigate(`/${langId}${paths.home}`)}>
                            &#215;
                        </CloseButtonMob>
                    )}
                    <MobileSlider />
                </>
            )}
        </>
    );
};

export default Onboarding;
