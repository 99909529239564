import React from "react";

const TickWrong: React.FC = () => {
    return(
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1587 5.6665L9.00033 7.82484L6.84199 5.6665L5.66699 6.8415L7.82533 8.99984L5.66699 11.1582L6.84199 12.3332L9.00033 10.1748L11.1587 12.3332L12.3337 11.1582L11.2545 10.079L10.1753 8.99984L12.3337 6.8415L11.1587 5.6665ZM9.00033 0.666504C4.39199 0.666504 0.666992 4.3915 0.666992 8.99984C0.666992 13.6082 4.39199 17.3332 9.00033 17.3332C13.6087 17.3332 17.3337 13.6082 17.3337 8.99984C17.3337 4.3915 13.6087 0.666504 9.00033 0.666504ZM9.00033 15.6665C5.32533 15.6665 2.33366 12.6748 2.33366 8.99984C2.33366 5.32484 5.32533 2.33317 9.00033 2.33317C12.6753 2.33317 15.667 5.32484 15.667 8.99984C15.667 12.6748 12.6753 15.6665 9.00033 15.6665Z" fill="white"/>
        </svg>
    );
}

export default TickWrong;