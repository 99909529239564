import { ISelection } from "../../../api/pickems";
import { IPickem, IPlayer, IQuestion } from "../../../context/type";
import { CardWrapper } from "../../../pages/Play/Play.styled";
import PlayerCard from "../../../pages/PlayerCard";
import nhlLogo from './../../../assets/images/nhl_logo.png';
import PepsiLogo from './../../../assets/images/2023PepsiLogo.png';
import { HelmetIcon } from './../../../pages/PepsiShutout/images/HelmetIcon';
import { PuckIcon } from './../../../pages/PepsiShutout/images/PuckIcon';
import { GoalNetIcon } from './../../../pages/PepsiShutout/images/GoalNetIcon'
import { useWindowSize } from "../../../helpers/useWidth";
import { useContext } from "react";
import { PlayersContext } from "../../../context/PlayersContext";
import { CornerIconContainer } from "../../../pages/PlayerCard/playerCard.styled";
import { ScoringSpan, UnderGoalieSpan } from "../../../pages/PepsiShutout/PepsiShutout.styles";
import { ITrs } from "../../../utils/types/types";
import { t } from "i18next";
import ScoresCount from "../../../pages/PepsiShutout/ScoresCount";
import { PointsContainer, ScoreContainer, ScoreSpan } from "../../../pages/PepsiShutout/ShutoutScoreReview.styles";
import colors from "../../../assets/theme/colors";
import { margin } from "styled-system";

const icons = [HelmetIcon,
  PuckIcon,
  GoalNetIcon,]

const UserSelectionsDisplay = ({selections, questions}: {selections: Array<ISelection>, questions: Array<IQuestion>}) => {
    const lng = localStorage.getItem('i18nextLng');
    const trs: ITrs = t('trs', {returnObjects: true,});
    const width = useWindowSize().width;
    const { allPlayersData } = useContext(PlayersContext);
    const isDesktop = width > 970;

    const selectedPlayers: IPlayer[] = []
    if (selections.length && !selectedPlayers.length) {
      questions.slice(0, 3).forEach((question: IQuestion, index: number) => {
        const currentSelection = question.options.find((opt: IPlayer) => (
          opt.option_id === selections[index].option_id
        ))
        if (currentSelection) {
          selectedPlayers.push(currentSelection);
        }
      })
    }
    const shutoutTeam = questions[3].options.find((option: IPlayer, index) => option?.option_id === selections[3]?.option_id)

    const scores = shutoutTeam ? ScoresCount(selectedPlayers, shutoutTeam) : {scoreStep1WithBoost: 0, scoreStep2WithBoost: 0, scoreStep3WithBoost: 0, totalScoreAmount: 0}
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <PointsContainer style={{margin: 0}}>
            <ScoreContainer
                style={{backgroundColor: colors.greenScoreBackground, margin: 0}}
            >
                <span>{trs.totalPoints}</span>
                <ScoreSpan>
                    {scores.totalScoreAmount || 0}
                </ScoreSpan>
            </ScoreContainer>
        </PointsContainer>
        <div style={{display: 'flex', flexWrap: width < 545 ? 'nowrap' : "wrap", minWidth: isDesktop ? '510px' : '100%', gap: '42px 12px', maxWidth: isDesktop ? '615px' : '100vw', marginTop: isDesktop ? 'unset' : '32px', justifyContent: isDesktop ? 'unset' : 'center'}}>
            {selections?.length > 3 &&
                  questions.map((question: IQuestion, index: number) => {
                    if ((index+1)%4 === 0) {return}
                    const currentSelectionId = selections.find((selection: ISelection) => selection.question_id === question.question_id);
                    const currentSelection = question.options.find((option: IPlayer) => option.option_id === currentSelectionId?.option_id)
                    const currentPickem = allPlayersData.find((pickem: IPickem) => pickem.questions.find((pickemQuestion: IQuestion) => pickemQuestion?.question_id === question?.question_id))
                    const isShutout = shutoutTeam && (currentSelection?.title_en === shutoutTeam.title_en && currentSelection.description_en === shutoutTeam.description_en)
                    // const {
                    //   title_en,
                    //   title_fr,
                    //   option_id,
                    //   option_image,
                    //   option_secondary_image,
                    //   option_sponsor_image,
                    //   description_en,
                    //   description_fr,
                    // } = currentSelection;

                    // const { jersey_number, opp_name, stat_1, stat_2, stat_3 } = question?.data;
                    let bgColor: string = 'blue';
                    let cornerIcon: JSX.Element | undefined = isShutout ? 
                      <CornerIconContainer isDragging={false}>
                        {/* <PepsiIcon /> */}
                        <img src={PepsiLogo} style={{width: '30px', height: '30px'}}/>
                      </CornerIconContainer>
                    : undefined;

                    return (
                      !!currentSelection && 
                      <CardWrapper
                        key={currentSelection.option_id}
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '40px',
                              maxWidth: width < 545  ? '33%' : '195px',
                              transform: width < 545  ? 'scale(0.85)' : 'none',
                        }}
                      >
                        {isDesktop && index < 3 && 
                          (index === 0 ? (
                              <HelmetIcon/>
                          ) : index === 1 ? (
                              <PuckIcon/>
                          ) : (
                              <GoalNetIcon/>
                          ))}
                        <PlayerCard
                          name={lng === 'en' ? currentSelection.title_en : currentSelection.title_fr}
                          team={lng === 'en' ? currentSelection.description_en : currentSelection.description_fr}
                          playerNum={currentSelection.data.jersey_number}
                          playerPhoto={currentSelection.option_image}
                          teamLogo={currentSelection.option_secondary_image ? currentSelection.option_secondary_image : nhlLogo}
                          opposingTeam={currentSelection.opp_name}
                          bgColor={bgColor}
                          CornerIcon={cornerIcon}
                          stats={{...currentSelection.data}}
                          // disabled={currentPickem.status !== 'UPCOMING' || new Date() > new Date(currentSelection.start_utc)}
                          opp_image={currentSelection.option_sponsor_image}
                        />
                        {allPlayersData[0].status === 'IN_PLAY' &&
                        <div style={isDesktop ? {} : {fontSize: '28px'}}>
                          <UnderGoalieSpan style={index === 2 ? {marginTop: isDesktop ? '-18px' : ''} : {}}>
                            {index === 2
                                ? trs.goalsAgainst
                                : index === 1
                                    ? trs.mostSaves
                                    : trs.wins}
                          </UnderGoalieSpan>
                          <ScoringSpan style={isDesktop ? {} : {fontSize: 'inherit'}}>
                          {index === 2
                              ?
                              `+${scores.scoreStep3WithBoost || 0}`
                              : index === 1
                                  ?
                                  `+${scores.scoreStep2WithBoost || 0}`
                                  :
                                  `+${scores.scoreStep1WithBoost || 0}`}
                          </ScoringSpan>
                        </div>
                        }
                      </CardWrapper>
                    );
                  })}
        </div>
      </div>
    )
}

export default UserSelectionsDisplay
