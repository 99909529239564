import React, { useState } from 'react';
import Slider from 'react-slick';
import {
  ButtonWrapper,
  Image,
  OnboardingWrapper,
  SliderWrap,
  SupTitle,
  Text,
  Title,
  WrapperInfoText,
} from './MobileSlider.styles';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { IDataMobDeskProps, IDataProps } from '../../types';
import Button from '../../../Button';
import DefaultImg from '../../../../assets/images/pepsi_logo.png';
import Icons from '../images/game_icons_mob.png';
import SwipeIcon from '../../../../assets/icons/SwipeIcon';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../../../utils/types/types';
import paths from '../../../../AppRouter/paths';
import { localStorageSet } from '../../../../utils/localStorage';

const MobileSlider: React.FC<any> = () => {
  const [dots, setDots] = useState(true);
  const [newUser, setNewUser] = useState<boolean>(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { langId } = useParams();
  const onboarding: IDataMobDeskProps[] = t('onboarding', {
    returnObjects: true,
  });
  const trs: ITrs = t('trs', {
    returnObjects: true,
  });

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    edgeFriction: 1,
    speed: 500,
    cssEase: 'linear',
    useTransform: false,
    pauseOnHover: false,
    arrows: false,
    afterChange: (currentSlide: any) => {
      onboarding.length - 1 === currentSlide ? setDots(false) : setDots(true);
    },
    appendDots: (dots: React.ReactNode) => <ul>{dots}</ul>,
    customPaging: () => (
      <div className="dots">
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      </div>
    ),
  };

  const handleLastClick = () => {
    navigate(`/${langId}${paths.home}`);
    if (newUser === true) {
      setNewUser(!newUser);
      localStorageSet('user', newUser);
    }
  };

  return (
    <SliderWrap>
      <Slider {...settings}>
        {onboarding.map((item: IDataProps, id: number) => (
          <OnboardingWrapper key={id}>
            <Title>{item.title}</Title>
            <div
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px',
                height: '22px',
                paddingTop: '15px',
              }}
            >
              {onboarding.length - 1 !== id && (
                <>
                  Swipe across <SwipeIcon />
                </>
              )}
            </div>
            <Image src={item.screen ? item.screen : DefaultImg} />
            {item.supText && <SupTitle>{item.supText}</SupTitle>}
            <WrapperInfoText>
              {item.text.map((item, index) => (
                <Text marginVariant={index === 0 || index === 1} key={index}>
                  {item}
                </Text>
              ))}
              {item.pointsList && (
                <div style={{ width: 'max-content', margin: '0 auto' }}>
                  {item.pointsList.map((item, index) => (
                    <Text marginVariant={index === 0} key={index} textAlingVariant={true}>
                      {item}
                    </Text>
                  ))}
                </div>
              )}
              {item.link && (
                <Link
                  to={`/${langId}${paths.rules}`}
                  style={{
                    fontSize: '14px',
                    lineHeight: '140%',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {item.link}
                </Link>
              )}
              <>{item.icons && <img src={Icons} style={{ margin: '20px auto' }} alt="game" />}</>
            </WrapperInfoText>
            {onboarding.length - 1 === id && (
              <ButtonWrapper>
                <Button handleClick={handleLastClick} text={trs.gotIt} />
              </ButtonWrapper>
            )}
          </OnboardingWrapper>
        ))}
      </Slider>
    </SliderWrap>
  );
};

export default MobileSlider;
