import React from "react";

const Game: React.FC = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13V16H2V12H1C0.45 12 0 12.45 0 13Z" fill="#262626"/>
            <path d="M7 12H3V16L7.69 15.99C8.07 15.99 8.41 15.78 8.58 15.44L9.45 13.54L7.86 10.06L7 12Z" fill="#262626"/>
            <path d="M19.71 12.29C19.53 12.11 19.28 12 19 12H18V16H20V13C20 12.72 19.89 12.47 19.71 12.29Z" fill="#262626"/>
            <path d="M11.6 8.84L15.65 0H12.3L10.54 3.97L10.05 5.07L10 5.21L7.7 0H4.35L9.92 12.16L10 12.34L11.42 15.44C11.59 15.78 11.93 15.99 12.31 15.99L17 16V12H13L11.6 8.84Z" fill="#262626"/>
        </svg>

    );
}

export default Game;