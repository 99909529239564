import React from "react";
import {Wrapper} from "./OnboardingScreen.styles";
import Onboarding from "../../Components/Onboarding";

const OnboardingScreen: React.FC = () => {
    return (
        <Wrapper>
            <Onboarding/>
        </Wrapper>
    );
};

export default OnboardingScreen;