import axios from "axios";
import { localStorageGet } from "../utils/localStorage";

interface GetCurrentUserLeaguePosition {
  league_id: number;
  tournament_id: number;
}

export const getCurrentUserLeaguePosition = async ({
  league_id,
  tournament_id,
}: GetCurrentUserLeaguePosition) => {
  const jwtToken = localStorageGet("jwt");
  const { data } = await axios.get(
    `${process.env.REACT_APP_LOGIN_API_KEY}/league/${league_id}/tournament/${tournament_id}/leaderboard-user`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );
  return data;
};

export const FetchOverall = async (
  leagueId?: number | string,
  tournamentId?: number
) => {
  const allLeaderboard = `${process.env.REACT_APP_LOGIN_API_KEY}/league/${leagueId}/tournament/${tournamentId}/leaderboard?offset=0&limit=40`;
  const leaderboardUser = `${process.env.REACT_APP_LOGIN_API_KEY}/league/${leagueId}/tournament/${tournamentId}/leaderboard-user`;

  const jwtToken = localStorageGet("jwt");

  try {
    const result = await Promise.all([
      axios.get(allLeaderboard),
      axios.get(leaderboardUser, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }),
    ]);
    const data = result.map((res) => res.data);
    return data;
  } catch {
    throw Error("Promise failed");
  }
};
