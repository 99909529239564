import { useNavigate, useParams } from 'react-router-dom';
import { Btn, RulWrapper } from './Rules.styled';
import { rulesData } from './rulsPath/rulesData';
import FileViewer from 'react-file-viewer';

const RulesPage = () => {
    const navigate = useNavigate();
    const {langId} = useParams()
    const defaultPath = rulesData.find((item) => item.leng === 'en');
    const frPath = rulesData.find((item) => item.leng === langId);
    const goBack = () => {
        return navigate(-1);
    };
    return (
        <RulWrapper>
            <Btn onClick={goBack}>&#215;</Btn>
            {langId === 'en' && <FileViewer
                fileType="docx"
                filePath={defaultPath?.path}
            />}
            {langId === 'fr' && <FileViewer
                fileType="docx"
                filePath={frPath?.path}
                />
            }
        </RulWrapper>
    );
};
export default RulesPage;
