import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../../../assets/theme/colors';

interface StyledButtonProps {
  selected: boolean;
}

export const StylesButton = styled(Link)<StyledButtonProps>(({ selected }) => ({
  width: '100%',
  height: '54px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  background: selected ? '#000000' : '#292933',
  borderTop: '2px solid #1D1D1B',
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '140%',
  textAlign: 'center',
  color: colors.lightGrayFont,
  textTransform: 'uppercase',
}));
