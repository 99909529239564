import { getJWTToken } from '../api/pickems';
import React from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import i18n from '../i18n';

export const useAuth = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(true);
  const [lang, setLang] = React.useState<string | null>('en');
  const navigate = useNavigate();

  React.useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    const lang = new URLSearchParams(location.search).get('lang');

    if (searchParams.has('lang')) {
      i18n.changeLanguage(lang || 'en');
      window.dispatchEvent(new Event('storage'));
      setLang(lang);
      searchParams.delete('lang');
    }

    if (token) {
      searchParams.delete('token');
      setSearchParams(searchParams);
      getJWTToken(token)
        .then(() => {
          setLoading(false);
          if (localStorage.getItem('jwt')) {
            navigate(`/${lang}/home`);
          }
        })
        .catch(() => {
          // window.location.href =(process.env.REACT_APP_URL_GAMEZONE)
          window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
        });
    } else {
      setLoading(false);
    }
  }, []);

  return loading;
};
