import {
    StyledButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
    BlueGoalieContainer,
    PopupBackdrop,
    PopupBodyContainer,
    PopupContainer,
    PopupCrossContainer,
} from "../Popups/Popups.styles" ;
import CongratPopupBg from './images/2023ShareLeaguePopup.png';
import { ILeaguePopupProps } from '../PepsiShutout/types';
import CrossIcon from '../../assets/icons/CrossIcon';
import ShareArrowIcon from '../../assets/icons/ShareArrowIcon';
import { useState } from 'react';
import { CheckRoundedIcon } from '../../assets/icons/CheckRoundedIcon';
import { useTranslation } from 'react-i18next';

const ShareLeaguePopup = ({
    currentLeague,
    isVisible,
    setIsVisible,
}: ILeaguePopupProps) => {
    const [wasTextCopied, setWasTextCopied] = useState(false);
    const lng = localStorage.getItem("i18nextLng");


    const handleCopyLink = () => {
        if (!wasTextCopied && currentLeague?.code) {
            navigator.clipboard.writeText(`${window.location.href.replace(window.location.pathname, `/${lng}/league`)}&code=${currentLeague.code}`)
                .then(() => setWasTextCopied(true))
                .catch(() => alert(`Code: ${currentLeague?.code}`));
        }
    };

    const { t } = useTranslation();
    return (
        <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
            <PopupContainer>
            <PopupCrossContainer
                onClick={() => {
                    setIsVisible(false);
                    setWasTextCopied(false)
                }}
            >
                <CrossIcon />
            </PopupCrossContainer>
                <BlueGoalieContainer>
                    <img
                        src={CongratPopupBg}
                        alt={'goalie'}
                        style={{ width: '100%', }}
                    />
                </BlueGoalieContainer>
                <PopupBodyContainer>
                    <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                        {t('shareYourLeag')}
                    </h1>

                    <p
                        style={{
                            fontSize: '16px',
                            fontWeight: 500,
                            marginTop: '18px',
                        }}
                    >
                        {t('shareCode')}{' '}
                        <b style={{ fontWeight: 700 }}>{currentLeague?.title}</b>:
                    </p>
                    <p
                        style={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '22.4px',
                            marginTop: '18px',
                        }}
                    >
                        {currentLeague?.code}
                    </p>

                    <StyledButton
                        onClick={handleCopyLink}
                        style={{ marginTop: '18px' }}
                    >
                        {wasTextCopied ? 'Copied!' : t('copyLink')}
                        {wasTextCopied ? (
                            <CheckRoundedIcon />
                        ) : (
                            <ShareArrowIcon />
                        )}
                    </StyledButton>
                </PopupBodyContainer>
            </PopupContainer>
        </PopupBackdrop>
    );
};

export default ShareLeaguePopup;
