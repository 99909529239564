import styled from "styled-components";
import colors from "../../assets/theme/colors";
import GoalieScoresBackground from "../../assets/images/GoalieScoresBackground.svg"

export const ShutoutScoreHeader = styled("div") ({
  display: "flex", alignItems: "center",
  justifyContent: "center",
  padding: "0 9px",
  backgroundColor: "white",
  height: "54px",
  maxWidth: "900px",
   "@media screen and (min-width: 768px)": {
    margin: "0 auto",
    borderBottomLeftRadius: "12px",
    borderBottomRighttRadius: "12px",
    background: "none",
    color: "white",
   }
})

export const PointsContainer = styled("div") ({
  display: "flex",
   justifyContent: "space-evenly",
   alignItems: "center",
   margin: "17px auto 80px",
   "@media screen and (min-width: 768px)": {
    margin: "90px auto",
   }
})

export const ScoreContainer = styled("div") ({
  fontFamily: "'Sintony', san-serif",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  color: "#262626",
  fontSize: "9px",
  lineHeight: "13px",
  width: "100px",
  maxWidth: "250px",
  height: "60px",
  borderRadius: "8px",
  'span': {
    textTransform: "uppercase",
  }
})

export const ScoreSpan = styled("span") ({
  fontSize: "35px",
  lineHeight: "29px",
  fontWeight: "bold",
  color: "#262626",
  display: "inline-block",
  marginTop: "5px",
})

export const ScoresBottomContainer = styled("div") ({
  marginTop: "2vh",
  backgroundImage: `url(${GoalieScoresBackground})`,
  backgroundPosition: "top right",
  backgroundRepeat: "no-repeat",
})

export const ScoreBottomFlexContainer = styled("div") ({
  width: "50vw",
  margin: "auto",
  height: "27vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})


export const ChevronLeftContainer = styled("div") ({
  display: "flex",
  padding: "0 8px",
  borderRadius: "8px",
  width: "34px",
  height: "28px",
  backgroundColor: colors.primaryLightGrey,
})

export const ChevronRightConrainer = styled("div") ({
  display: "flex",
  transform: "rotate(180deg)",
  padding: "0 8px",
  borderRadius: "8px",
  width: "34px",
  height: "28px",
  backgroundColor: colors.primaryLightGrey,
})

export const ScoreHeaderDate = styled("div") ({
  color: "black",
  fontWeight: 700,
  fontSize: "18px",
  "@media screen and (min-width: 768px)": {
  color: "white",
   }
})