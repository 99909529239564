import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
// import colors from "../../assets/theme/colors"
import { StateContext } from "../../context/StateContex"
import { Button } from "../PepsiShutout/PepsiShutout.styles"
import VideoPlayer from "../../Components/VideoPlayer";
// import paths from "../../AppRouter/paths";
import { useWindowSize } from "../../helpers/useWidth"
import { CrossContainer } from "../../Components/ModalWindow/ModalWindow.styles";
import CrossIcon from "../../assets/icons/CrossIcon";

const VideoAdPage = () => {
  const [isButtonAllowed, setIsButtonAllowed] = useState<boolean>(true);
  const {isVideoAdPopupOpen, setIsVideoAdPopupOpen} = useContext(StateContext);
  const width = useWindowSize().width
  const isDesktop = width > 970;

  const handleContinueClick = () => {
    setIsVideoAdPopupOpen(false);
  }

  useEffect(() => {
    setIsButtonAllowed(false);
    const timeout = setTimeout(() => {
      setIsButtonAllowed(true);
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isVideoAdPopupOpen])

  return (
    isVideoAdPopupOpen ? <VideoAdContainer>
      <>

          <CrossContainer style={{zIndex: 100, top: isDesktop ? '10vh' : '10px', right: isDesktop ? '15%' : '10px'}} onClick={handleContinueClick}>
            <CrossIcon />
          </CrossContainer>
        <div style={{display: "flex", position: 'relative', flexDirection: 'column', alignItems: "center", justifyContent: "center", height: "100vw"}}>
          <span>
            {isDesktop ?
              <VideoPlayer
                widthSize={"970"}
                heightSize={"540"}
              />
              :
              <VideoPlayer
                widthSize={width < 540 ? (width-10).toString() : '540'}
                heightSize={width < 540 ? ((width-60)*1.5).toString() : '1080'}
              />
            }
          </span>
        </div>
        {isButtonAllowed && (
          <div style={{position: "absolute", bottom: "10vh"}}>
            <Button
              onClick={handleContinueClick}
              className={isButtonAllowed ? "active" : "inactive"}>
              Continue
            </Button>
          </div>
        )}

      </>
    </VideoAdContainer> : null
  )
}

export default VideoAdPage

const VideoAdContainer = styled("div") ({
  backgroundColor: "#000",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100vw",
  height: "100vh",
  zIndex: 200,
})
