import styled from "styled-components";

export const Container = styled("div")({
  width: "100%",
  display: "flex",
});

export const StyledInput = styled("input")({
  width: "100%",
  background: "#F2F2F2",
  padding: `13px 10px 13px`,
  border: "none",
  borderRadius: "4px 0 0 4px",
  outline: "none",
  fontWeight: 400,
  fontSize: "14px",
});

export const Copy = styled("div")({
  display: "flex",
  alignItems: "center",
  background: "#F2F2F2",
  padding: `0 15px`,
  borderRadius: "0 4px 4px 0",
  fontWeight: 700,
  fontSize: "14px",
  color: "#0183DA",
  cursor: "pointer",

  transition: "200ms background ease",
  ":hover": {
    background: "#e0e0e0",
  },
  ":active": {
    background: "#d6d6d6",
  },
});
