import { useQuery } from "../../hooks/useQuery";
import { getCurrentUserLeaguePosition } from "../overallApi";

export const useLeagueLeaderboardUserPosition = (
  league_id: number,
  tournament_id: number
) => {
  const currentUserPosition = useQuery({
    fetcher: () => getCurrentUserLeaguePosition({ league_id, tournament_id }),
    deps: [league_id, tournament_id],
    execute: league_id !== 0 && tournament_id !== 0,
  });

  return currentUserPosition;
};
