import React from "react";

const Close: React.FC = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.496963 0.496963C1.05021 -0.0562795 1.94719 -0.0562795 2.50043 0.496963L9.9987 7.99523L17.497 0.496963C18.0502 -0.0562795 18.9472 -0.0562795 19.5004 0.496963C20.0537 1.05021 20.0537 1.94719 19.5004 2.50043L12.0022 9.9987L19.5004 17.497C20.0537 18.0502 20.0537 18.9472 19.5004 19.5004C18.9472 20.0537 18.0502 20.0537 17.497 19.5004L9.9987 12.0022L2.50043 19.5004C1.94719 20.0537 1.05021 20.0537 0.496963 19.5004C-0.0562795 18.9472 -0.0562795 18.0502 0.496963 17.497L7.99523 9.9987L0.496963 2.50043C-0.0562795 1.94719 -0.0562795 1.05021 0.496963 0.496963Z"
                fill="white"
            />
        </svg>
    );
};

export default Close;