import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { SidebarButtonProps } from '../../../SidebarButton/types';
import Home from '../../icons/Home';
import Game from '../../icons/Game';
import Leaderboard from '../../icons/Leaderboard';
import HowToPlay from '../../icons/HowToPlay';
import OfficialRules from '../../icons/OfficialRules';
import Social from '../../icons/Social';
import Globe from '../../icons/Globe';
import { NavigationWrapper } from './Navigation.styles';
import SidebarButton from '../../../SidebarButton';
import paths from '../../../../AppRouter/paths';
import SocialLink from '../SocialLink';
import Lenguages from '../Lenguages';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Helmet from "../../icons/Helmet";
import Gznhl from '../../../../assets/icons/gznhl_logo';
import { useWindowSize } from '../../../../helpers/useWidth';

const Navigation = () => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const leng = Cookies.get('i18next');
  const [activeLang, setActiveLang] = useState<any>(leng?.toLocaleUpperCase());
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isDesktop = width > 768;
  const { langId } = useParams();
  const SideBarNavigations: Omit<SidebarButtonProps, 'isActive'>[] = [
    {
      label: t('home'),
      icon: {
        Component: <Home />,
      },
      link: paths.home,
      onLinkClick: () => navigate(`/${langId}${paths.home}`),
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('leag'),
      icon: {
        Component: <Game />,
      },
      link: paths.myLeagues,
      onLinkClick: () => navigate(`/${langId}${paths.myLeagues}`),
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('leaderBoard'),
      icon: {
        Component: <Leaderboard />,
      },
      link: pathname === `/${langId}${paths.weekly}` ? paths.weekly : paths.overall,
      onLinkClick: () => navigate(`/${langId}${paths.overall}`),
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('howToPlay'),
      icon: {
        Component: <HowToPlay />,
      },
      link: paths.guide,
      onLinkClick: () => navigate(`/${langId}${paths.guide}`),
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('offRuls'),
      icon: {
        Component: <OfficialRules />,
      },
      link: paths.rules,
      onLinkClick: () => navigate(`/${langId}${paths.rules}`),
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('social'),
      AdditionalComponent: <SocialLink />,
      icon: {
        Component: <Social />,
      },
      onTabClick: () => setActiveIndex(activeIndex),
    },
    {
      label: t('goaliePreview'),
      icon: {
        Component: <Helmet />,
      },
    onTabClick: () => () => setActiveIndex(activeIndex),
      redirectLink: 'https://www.nhl.com/news/nhl-fantasy-hockey-goalie-challenge-game-projected-starters-picks-340353950'
    },
    {
      label: activeLang,
      AdditionalComponent: <Lenguages setActiveLang={setActiveLang} />,
      icon: {
        Component: <Globe />,
      },
      onTabClick: () => () => setActiveIndex(activeIndex),
    },
    {
      label: 'Game Zone',
      // AdditionalComponent: <Lenguages setActiveLang={setActiveLang} />,
      icon: {
        Component: <Gznhl />,
      },
      redirectLink: `${process.env.REACT_APP_URL_GAMEZONE}`,
    },
  ];

  useEffect(() => {
    if (activeIndex && activeIndex !== 5 && activeIndex !== 7) {
      setActiveIndex(undefined);
    }
  }, [activeIndex]);

  return (
    <NavigationWrapper>
      {SideBarNavigations.map((item, index) => {
        const isButtonDisabledOnPreview =
          index !== SideBarNavigations.length - 1 && window.location.pathname === `/${langId}${paths.preview}`;
          if (isDesktop && item.label === 'Game Zone') {return null}
        return (
          <SidebarButton
            key={index}
            label={item.label}
            isActive={activeIndex ? index === activeIndex : pathname === item.link}
            onTabClick={() =>
              !isButtonDisabledOnPreview ? setActiveIndex(index === activeIndex ? undefined : index) : undefined
            }
            onLinkClick={isButtonDisabledOnPreview ? undefined : item.onLinkClick}
            AdditionalComponent={item.AdditionalComponent}
            icon={item.icon}
            redirectLink={item.redirectLink}
          />
        );
      })}
    </NavigationWrapper>
  );
};

export default Navigation;
