import React, { Dispatch, SetStateAction } from "react";
import { useLazyQuery } from "./useLazyQuery";

interface Params<Result, ListItem> {
  fetcher: ({ offset, limit }: T) => Promise<Result>;
  deps: unknown[];
  mapResult: (data: Result | null) => ListItem[];
  limit?: number;
  execute?: boolean;
}

interface Return<Result, ItemType> {
  list: ItemType[];
  onLoadMore: () => void;
  isLoading: boolean;
  result: Result | null;
  setList: Dispatch<SetStateAction<ItemType[]>>;
}

interface T {
  offset: number;
  limit: number;
}

export const useAdvancedPagination = <Result, ItemType>({
  deps,
  fetcher,
  limit = 20,
  mapResult,
  execute = true,
}: Params<Result, ItemType>): Return<Result, ItemType> => {
  const [list, setList] = React.useState<ItemType[]>([]);
  const [offset, setOffset] = React.useState(0);
  const [isEnd, setIsEnd] = React.useState<boolean>(false);

  const { isLoading, fetchData, result } = useLazyQuery<T, Result>({
    fetcher: () => fetcher({ offset, limit }),
    execute,
  });

  React.useEffect(() => {
    // Monitor changes in the 'execute' prop and reinitialize pagination when it becomes true
    if (execute) {
      setIsEnd(false);
      setOffset(0);
      setList([]);
      // onLoadMore();
    }
  }, [execute]);

  React.useEffect(() => {
    setIsEnd(false);
    setOffset(0);
    setList([]);
  }, [...deps, execute]);

  const addItems = (list: ItemType[]) => {
    if (list.length < limit) {
      setIsEnd(true);
    }
    setList((prevState) => [...prevState, ...list]);
    setOffset((prevState) => prevState + list.length);
  };

  const onLoadMore = async () => {
    if (isEnd || isLoading) return null;
    const data = await fetchData({ offset, limit });
    const array = mapResult(data) || [];
    addItems(array);
  };

  return { list, onLoadMore, isLoading, result, setList };
};
