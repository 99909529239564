import styled from "styled-components";
import loader from "./puck.svg";
import whiteLoader from "./whiteLoader.svg";

const Loader = ({color}: {color?: "white" | "blue"}) => (
  <StyledLoaderContainer>
    <img src={color === "white" ? loader : loader} alt="loader" style={{borderRadius: '50%', maxWidth: '60%', display: 'block', margin: 'auto'}} />
  </StyledLoaderContainer>
);

export default Loader;

const StyledLoaderContainer = styled("div") ({
  height: "100%",
  display: "fixed",
  alignItems: "center",
  justifyContent: "center",
  marginTop:"20px",

  position: "relative",
  top: "-25%",
});
