import React, { useEffect } from 'react';
import { ImgWrapper, Logo, Wrapper } from './SplashScreen.styled';
import logoFR from '../../assets/images/FRLogo.svg';
import logoEN from '../../assets/images/ENLogo.svg';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../AppRouter/paths';
import { localStorageGet } from '../../utils/localStorage';

const SplashScreen = () => {
    const lng = localStorage.getItem('i18nextLng');
    const navigate = useNavigate();
    const { langId } = useParams();
    useEffect(() => {
        const newUser = localStorageGet('user');
        const timeout = setTimeout(() => {
            if (newUser) {
                navigate(`/${langId}${paths.home}`);
            } else navigate(`/${langId}${paths.guide}`);
        }, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);
    return (
        <Wrapper>
            <ImgWrapper>
                <Logo src={lng === 'en' ? logoEN : logoFR} />
            </ImgWrapper>
        </Wrapper>
    );
};

export default SplashScreen;
