import React from 'react';
import {
    BoxLinkWrapper,
    Link,
    LinkLogo,
    LinkWrapper,
    WrapperLogo,
} from './SocialLink.styled';
import { linkData } from './linkData';

const SocialLink = () => {
    const lng = localStorage.getItem('i18nextLng');
    return (
        <BoxLinkWrapper>
            {linkData.map((link, id) => (
                <LinkWrapper key={id}>
                    <WrapperLogo style={link.linkName === 'Twitter' ? {width: '18px', height: '18px', border: '10px solid white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', background: "white"} : link.linkName === 'Facebook' ? {left: '7px', top: '-9px'} : link.linkName === 'Instagram' ? {left: '14px'} : {}}>
                        <LinkLogo src={link.logo} style={link.linkName === 'Instagram' ? {width: '28px', height: '28px'} : link.linkName === 'Twitter' ? {width: '14px', height: '14px'} : link.linkName === 'Facebook' ? {width: '32px', height: '32px'} : {width: '20px', height: '20px'}}/>
                    </WrapperLogo>
                    <Link
                        href={lng === 'fr' ? link.frPath : link.path}
                        target="_blank"
                    >
                        {link.linkName}
                    </Link>
                </LinkWrapper>
            ))}
        </BoxLinkWrapper>
    );
};

export default SocialLink;
