import styled from "styled-components";

export const BoxLinkWrapper = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  padding: 6px 0px;
`;

export const LinkWrapper = styled.div`
  position: relative;
  display: flex;
  padding-left: 48px;
  align-items: center;
  column-gap: 18px;
`;
export const WrapperLogo = styled.div`
  position: absolute;
  left: 16px;
  display: block;
`;
export const LinkLogo = styled.img`
  display: block;
  width: 22px;
  height: auto;
`;
export const Link = styled.a`
  font-size: 16px;
  letter-spacing: 0.65px;
  line-height: 140%;
  display: block;
  color: white;
  text-decoration: none;
`;
