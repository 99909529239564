import React from "react";

const SwipeIcon: React.FC = () => {
    return (
        <svg
            width="22px"
            height="22px"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="104.55 2.67 13.11 14.67"
        >
            <path
                d="M111 2.66675C107.793 2.66675 105.467 4.26008 104.607 5.94675C104.44 6.27341 104.687 6.66675 105.06 6.66675C105.247 6.66675 105.42 6.56675 105.5 6.40008C106.153 5.12675 108.053 3.66675 111 3.66675C113.02 3.66675 114.86 4.42675 116.273 5.66675H114.833C114.56 5.66675 114.333 5.89341 114.333 6.16675C114.333 6.44008 114.56 6.66675 114.833 6.66675H117C117.367 6.66675 117.667 6.36675 117.667 6.00008V3.83341C117.667 3.56008 117.44 3.33341 117.167 3.33341C116.893 3.33341 116.667 3.56008 116.667 3.83341V4.68008C115.12 3.42008 113.147 2.66675 111 2.66675ZM106.467 13.6201C106.467 13.1867 106.867 12.8667 107.293 12.9601L109.667 13.4934V6.33341C109.667 5.78008 110.113 5.33341 110.667 5.33341C111.22 5.33341 111.667 5.78008 111.667 6.33341V10.3334H112.273C112.48 10.3334 112.687 10.3801 112.867 10.4734L115.593 11.8334C116.107 12.0867 116.4 12.6467 116.32 13.2134L115.9 16.1867C115.807 16.8467 115.24 17.3334 114.58 17.3334H110.473C110.12 17.3334 109.613 17.1934 109.367 16.9401L106.653 14.0801C106.533 13.9601 106.467 13.7934 106.467 13.6201Z"
                fill="white"
            />
        </svg>
    );
}

export default SwipeIcon;