import React from "react";
import {NavigationLinkProps} from "./types";
import {StylesButton} from "./NavigationLink.styles"

const NavigationLink: React.FC<NavigationLinkProps> = ({
    children,
    selected,
    to,
}) => {
    return (
        <StylesButton selected={selected} to={to}>
            {children}
        </StylesButton>
    );
};

export default NavigationLink;