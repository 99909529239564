import React from 'react';
import { PicksHomeNavigationProps } from './types';
import NavigationButtons from '../NavigationButtons';
import NavigationLink from '../NavigationButtons/Components/NavigationLink';
import paths from '../../AppRouter/paths';
import {Outlet, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../helpers/useWidth';

const PicksHomeNavigation: React.FC<PicksHomeNavigationProps> = ({
    children,
    currentPage,
}) => {
    const { t } = useTranslation();
    const width = useWindowSize().width
    const isDesktop = width > 768;
    const { langId } = useParams();

    return (
        <>
            {isDesktop ? (
                <>
                    {children}
                    <Outlet />
                </>
            ) : (
                <>
                    <NavigationButtons>
                        <NavigationLink
                            selected={currentPage === 'game'}
                            to={`/${langId}${paths.home}`}
                        >
                            {t('game')}
                        </NavigationLink>
                        <NavigationLink
                            selected={currentPage === 'leaderboard'}
                            to={`/${langId}${paths.myLeagues}`}
                        >
                            {t('leaderBoard')}
                        </NavigationLink>
                    </NavigationButtons>
                    {children}
                    <Outlet />
                </>
            )}
        </>
    );
};

export default PicksHomeNavigation;
