import styled from "styled-components";
import { variant } from "styled-system";
import {ColorVariant} from "./types";

interface StylesButtonProps {
    fullWidth?: boolean;
    colorVariant?: ColorVariant;
    buttonMargin?: boolean;
    colorIcon?: boolean
}

interface FirstLetterCapital {
    paddingRight?: boolean;
}

export const StylesButton = styled("button")<StylesButtonProps>(
    (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: props.fullWidth ? "100%" : "none",
        padding: "13px 20px",
        fontWeight: "400",
        fontSize: "16px",
        textAlign: "center",
        color: "#000000",
        background: "#FFFFFF",
        borderRadius: "8px",
        border: "none",
        cursor: "pointer",
        userSelect: "none",
        marginBottom: props.buttonMargin ? "17px" : "none",
        "svg path": {
            stroke: props.colorIcon ? "#262626" : "",
            fill: props.colorIcon ? "#262626" : "",
        }
    }),
    variant({
            prop: "colorVariant",
            variants: {
               black: {
                   bg: "#262626",
                   borderRadius: "6px",
                   color: "#FFFFFF",
               },
                notStyles: {
                    background: "none",
                    height: "max-content",
                    border: "none",
                    padding: "15px",
                    borderRadius: "none",
                    position: "absolute",
                    right: "0px",
                    top: "50%",
                    transform: "translate(0, -50%)"
                }
            }
    })
);

export const FirstLetterCapital = styled("div")<FirstLetterCapital>((props) => ({
    paddingRight: props.paddingRight ? "10px" : "0px",
    ":first-letter": {
        textTransform: "uppercase",
    },
}));