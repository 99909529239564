import React, {useCallback, useMemo, useState} from 'react';
import {
    BodyContainer,
    ContentContainer,
    CrossContainer,
    Description,
    HeaderContainer,
    StyleButton,
    StyleCode,
    StyleImage,
    StylesSpan,
    Title,
    Wrapper,
} from './ModalWindow.styles';
import CrossIcon from '../../assets/icons/CrossIcon';
import PopupBg from './images/popup_bg.png';
import ShareBlack from '../../assets/icons/ShareBlack';
import { ModalWindowProps } from './types';
import { useTranslation } from 'react-i18next';
import paths from "../../AppRouter/paths";
import {CheckRoundedIcon} from "../../assets/icons/CheckRoundedIcon";

const ModalWindow: React.FC<ModalWindowProps> = ({
    handleClick,
    code,
    nameLeague,
}) => {
    const [wasTextCopied, setWasTextCopied] = useState(false);

    const { t } = useTranslation();

    const data: ShareData = useMemo(
        () => ({
            url: `${process.env.REACT_APP_PEPSI_NHL_URL}${paths.myLeagues}`,
            text: `Code: ${code}`,
            title: `${t("modalShareTitle")}`
        }),
        []
    );

    const handleCopyCode = useCallback(() => {
        navigator.clipboard
            .writeText(code)
            .then(() => setWasTextCopied(true))
            .catch(() => alert(`Code: ${code}`));

        if(wasTextCopied || navigator.share) {
            setTimeout(() => navigator.share(data), 3000);
        }
    }, [code]);

    return (
        <Wrapper>
            <ContentContainer>
                <HeaderContainer>
                    <CrossContainer onClick={handleClick}>
                        <CrossIcon />
                    </CrossContainer>
                    <StyleImage src={PopupBg} alt={PopupBg} />
                </HeaderContainer>
                <BodyContainer>
                    <Title>{t('shareYourLeag')}</Title>
                    <Description>
                        {t('shareCode')} <StylesSpan>{nameLeague}</StylesSpan>:
                    </Description>
                    <StyleCode>{code}</StyleCode>
                    <StyleButton onClick={handleCopyCode}>
                        {wasTextCopied ? "Copied!" : t('copyLink')}
                        <div style={{ marginLeft: '5px' }}>
                            {wasTextCopied ? <CheckRoundedIcon/> : <ShareBlack />}
                        </div>
                    </StyleButton>
                </BodyContainer>
            </ContentContainer>
        </Wrapper>
    );
};

export default ModalWindow;
