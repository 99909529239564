import React from "react";
import { StyledBackground, IconWrapper } from "./Background.styles";
import { BackgroundProps } from "./types";
import Close from "../../assets/icons/Close";

const Background: React.FC<BackgroundProps> = ({
  onCloseClick,
  isVisible,
  style,
}) => {
  if (!isVisible) return null;
  return (
    <StyledBackground onClick={onCloseClick} style={style}>
      <IconWrapper>
        {onCloseClick && (
          <div onClick={onCloseClick}>
            <Close />
          </div>
        )}
      </IconWrapper>
    </StyledBackground>
  );
};

export default Background;
