import React from "react";
import SidebarLayoutAnimation from "./SidebarLayout.animation";
import { Bottom, ScrollContent, Top, Wrapper } from "./SidebarLayout.styles";
import { SidebarLayoutProps } from "./types";
import AdsBanner from "../AdsBanner";

const SidebarLayout: React.FC<SidebarLayoutProps> = ({
    TopComponent,
    MiddleComponent,
    BottomComponent,
    isVisible,
}) => {
    return (
        <SidebarLayoutAnimation inProp={isVisible}>
            <Wrapper>
                <ScrollContent>
                    <div style={{marginLeft: "28px"}}>
                        <Top>{TopComponent}</Top>
                        <div>{MiddleComponent}</div>
                    </div>
                    <AdsBanner slotSize={[250, 140]}/>
                    <Bottom>{BottomComponent}</Bottom>
                </ScrollContent>
            </Wrapper>
        </SidebarLayoutAnimation>
    );
};

export default SidebarLayout;