import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { leaderboardPlayer } from "../Components/Overall/Overall";

export interface ITournamentData {
  end_time: string;
  is_visible: boolean;
  league_id: number;
  league_tournament_id: number;
  prize_image: string;
  prize_image_en: string;
  prize_image_fr: string;
  start_time: string;
  title: string;
  tournament_type: string;
}

interface IPaginationParams {
  offset: number;
  limit: number;
}

export const useLeagueData = (
    league_id: string,
    offset: number = 0,
    limit: number = 20
) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [leagueLeaderboard, setLeagueLeaderboard] = useState<
      leaderboardPlayer[]
  >([]);

  const getTournaments = async () => {
    const {data} = await axios.get(
        `${process.env.REACT_APP_LOGIN_API_KEY}/leagues/${league_id}/tournaments?tournament_type=SEASON`
    );

    return {
      league_tournament_id: data.tournaments[0].league_tournament_id,
    };
  };

  const getLeagueLeaderboard = async (tournament_id: number) => {
    const leaderboard = (
        await axios.get(
            `${process.env.REACT_APP_LOGIN_API_KEY}/league/${league_id}/tournament/${tournament_id}/leaderboard?offset=${offset}&limit=${limit}`
        )
    ).data.leaderboard;
    setLeagueLeaderboard(leaderboard);
  };

  const getLeagueData = async () => {
    setIsLoading(true);
    const {league_tournament_id} = await getTournaments();
    getLeagueLeaderboard(league_tournament_id);
    setIsLoading(false);
  };
  const fetcherFunction = async ({limit, offset}: IPaginationParams) => {
    const getTournaments = async () => {
      const {data} = await axios.get(
          `${process.env.REACT_APP_LOGIN_API_KEY}/leagues/${league_id}/tournaments?tournament_type=SEASON`
      );

      return data.tournaments[0].league_tournament_id;
    };

    const leaderboard = async () => (
        await axios.get(
            `${process.env.REACT_APP_LOGIN_API_KEY}/league/${league_id}/tournament/${await getTournaments()}/leaderboard?offset=${offset}&limit=${limit}`
        )
    ).data.leaderboard;

    return leaderboard();
  }

  return { leagueLeaderboard, userLeagueDataLoading: isLoading, getLeagueData, fetcherFunction };
};
