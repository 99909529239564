import React, { useContext, useEffect, useState } from "react";
import {
  OverallPlayerItem,
  OverallPlayerRank,
  OverallPlayersWrapper,
  OverallPlayerUserName,
  OverallPlayerPoints,
} from "./Overall.styled";
import pepsiLogo from "../../assets/images/2023PepsiLogo.png";
import Loader from "../../pages/Loader/Loader";
//import { useSearchParams } from "react-router-dom";
import { useAdvancedPagination } from "../../hooks/useAdvancedPagination";
import axios from "axios";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { useLeagueLeaderboardUserPosition } from "../../api/hooks/useLeagueLeaderboardUserPosition";
import { PlayersContext } from "../../context/PlayersContext";
import AdsBanner from "../AdsBanner";
import { useWindowSize } from "../../helpers/useWidth";
import { useNavigate, useParams } from "react-router-dom";
import paths from "../../AppRouter/paths";
import { IPickem } from "../../context/type";

export type leaderboardPlayer = {
  user_id: number;
  username: string;
  favourite_team_id: number;
  favourite_team_logo: string;
  points: number;
  rank: number;
};

const REACT_APP_COLLECTIVE_LEADERBOARD =
  process.env.REACT_APP_COLLECTIVE_LEADERBOARD;

const getTournamentLeaderboard = async ({
  offset,
  limit,
  leagueId,
  tournamentId,
}: any) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_LOGIN_API_KEY}/league/${leagueId}/tournament/${tournamentId}/leaderboard`,
    {
      params: {
        offset,
        limit,
      },
    }
  );
  return data;
};

const Overall: React.FC = () => {
  const width = useWindowSize().width
  const isDesktop = width > 970;
  const navigate = useNavigate();
  const { langId } = useParams();

  const { tournamentData } = useContext(PlayersContext);
  const { allPlayersData } = useContext(PlayersContext);
  const [filteredAllPlayersData] = useState(allPlayersData.length ? allPlayersData.filter((pickem: IPickem) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY") : [])
  const storageTournamentId = tournamentData && tournamentData?.league_tournament_id
  const [usersListToRender, setUsersListToRender] = useState<
    leaderboardPlayer[]
  >([]);

  const currentUserPosition: any = useLeagueLeaderboardUserPosition(
    REACT_APP_COLLECTIVE_LEADERBOARD ? +REACT_APP_COLLECTIVE_LEADERBOARD : 0,
    storageTournamentId
  );

  const currentUserPositionData = currentUserPosition.result
    ? currentUserPosition.result.position
    : null;

  const { list, onLoadMore, isLoading } = useAdvancedPagination<
    any,
    leaderboardPlayer
  >({
    fetcher: ({ offset, limit }) =>
      getTournamentLeaderboard({
        offset,
        limit,
        leagueId: process.env.REACT_APP_COLLECTIVE_LEADERBOARD,
        tournamentId: storageTournamentId,
      }),
    deps: [tournamentData?.league_tournament_id],
    execute: !!storageTournamentId,
    mapResult: (data: any) => data?.leaderboard,
  });

  const { intersectionObserverRef } = useIntersectionObserver({
    onReachPageEnd: onLoadMore,
    deps: [storageTournamentId],
  });

  const readUsersList = () => {
    const isUserInTop9 = currentUserPositionData
      ? list
          .slice(0, 8)
          .find((item) => item.user_id === currentUserPositionData.user_id)
      : false;
    if (currentUserPositionData && !isUserInTop9) {
      const listToSet = list;
      listToSet.splice(list.indexOf(currentUserPositionData), 1);
      setUsersListToRender([currentUserPositionData, ...listToSet]);
    } else {
      setUsersListToRender(list);
    }
  };

  useEffect(() => {
    readUsersList();
  }, [list, currentUserPositionData]);

  const handleLeagueMemberClick = (userId: number) => () => {
    navigate(`/${langId}${paths.leaderboardScores}/${userId.toString()}/${filteredAllPlayersData[0].pickem_id}`);
  };

  return (
    <OverallPlayersWrapper>
      {usersListToRender.map((item, index) => {
        return (
          <OverallPlayerItem
            key={item.user_id}
            bgColor={
              currentUserPositionData && item.user_id === currentUserPositionData.user_id
                  ? "blue"
                  : "black"
            }
            onClick={handleLeagueMemberClick(item.user_id)}
          >
            <OverallPlayerRank>{item.rank}</OverallPlayerRank>
            <OverallPlayerUserName>
              <img src={pepsiLogo} alt="pepsi logo" style={{width: '40px', marginRight: '18px'}}/>
              <span>{item.username}</span>
            </OverallPlayerUserName>
            <OverallPlayerPoints>{item.points}</OverallPlayerPoints>
          </OverallPlayerItem>
        );
      })}
      <div>
        <div style={{ color: "rgba(0,0,0,0.0)", textAlign: "center" }}>end</div>
        <div ref={intersectionObserverRef} />
      </div>
      {isDesktop ? <AdsBanner slotSize={[970, 250]}/>: <AdsBanner slotSize={[300, 250]}/> }
      {isLoading && <Loader />}
    </OverallPlayersWrapper>
  );
};

export default Overall;
