import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
const prefleng = {
    fr: ['fr'],
    default: ['en'],
};
i18n.use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'fr'],
        debug: false,
        fallbackLng: prefleng,
        react: {
            useSuspense: true,
        },
        detection: {
            order: ['path', 'cookie', 'localStorage'],
            caches: ['cookie', 'localStorage'],
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
    });

export default i18n;
