import { useQuery } from "../../hooks/useQuery";
import { getCurrentUserPickemPosition } from "../weeklyApi";

export const usePickemLeaderboardUserPosition = (pickemId: number) => {
  const { isLoading, refetchData, result } = useQuery({
    fetcher: () => getCurrentUserPickemPosition(pickemId),
    deps: [pickemId],
    execute: !!pickemId,
  });

  return { isUserLoading: isLoading, refetchData, result };
};
