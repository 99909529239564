import styled from "styled-components";

// @ts-ignore
import wins from "../images/wins.svg";
// @ts-ignore
import winsActive from '../images/winsWhite.svg';
// @ts-ignore
import winsBig from '../images/WinsBig.svg';
// @ts-ignore
import winsActiveBig from '../images/WinsBigActive.svg';
import winsFr from '../images/Wins_fr.svg';
import winsActiveFr from '../images/WinsActive_fr.svg';


// @ts-ignore
import goalsAgainst from "../images/goalsAgainst.svg";
// @ts-ignore
import goalsAgainstActive from '../images/goalsAgainstActive.svg';
// @ts-ignore
import goalsBig from '../images/GoalsBig.svg';
// @ts-ignore
import goalsActiveBig from '../images/GoalsBigActive.svg';
import goalsFr from '../images/GoalsAgainst_fr.svg'
import goalsActiveFr from '../images/GoalsAgainstActive_fr.svg'

// @ts-ignore
import mostSaves from "../images/mostSaves.svg";
// @ts-ignore
import mostSavesActive from '../images/mostSavesActive.svg';
// @ts-ignore
import savesBig from '../images/SavesBig.svg';
// @ts-ignore
import savesActiveBig from '../images/SavesBigActive.svg';

import savesFr from '../images/MostSaves_fr.svg';
import savesActiveFr from '../images/MostSavesActive_fr.svg';

// @ts-ignore
import pepsi from "../images/2023PepsiInactive.svg";
// @ts-ignore
import pepsiActive from '../images/2023PepsiActive.svg';
// @ts-ignore
import pepsiBig from '../images/2023PepsiInactiveBig.svg';
// @ts-ignore
import pepsiActiveBig from '../images/2023PepsiActiveBig.svg';
import pepsiFr from '../images/Pepsi_fr.svg'
import pepsiActiveFr from '../images/PepsiActive_fr.svg'



export const TabsWrapper = styled.div`
    display: flex;
    justifyContent: center;
  
    .tabs {
      display: inline-block;
      width: calc(25% - 2px);
      height: 50px;
      background-color: #292933;
      border-right: 2px solid #1D1D1B;
      background-repeat: no-repeat;
      background-position: center;

      @media screen and (min-width: 768px) {
        maxWidth: "300px",
      }
      
      :last-child {
        border-right: none;
        width: 25%;
      }
    }
  
  //  WINS TAB STYLE
  
  .wins_tab {
    background-image: url(${wins});
    :hover {
      background-image: url(${winsActive});
    }
  }
  .wins_tab.active {
    background-image: url(${winsActive});
    background-color: #800018;
    border-radius: 0;
  }
  
  //SAVES TAB STYLE
  
  .saves_tab {
    background-image: url(${mostSaves});
    :hover {
      background-image: url(${mostSavesActive});
    }
  }
  .saves_tab.active {
    background-image: url(${mostSavesActive});
    border-radius: 0;
    background-color: #800018
  }

  .saves_tab.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  //GOALS TAB STYLE
  
  .goals_tab {
      background-image: url(${goalsAgainst});
    :hover {
      background-image: url(${goalsAgainstActive});
    }
  }
  .goals_tab.active{
    background-image: url(${goalsAgainstActive});
    border-radius: 0;
    background-color: #800018
  }
  
  .goals_tab.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  // PEPSI TAB STYLE
  .pepsi_tab {
    background-image: url(${pepsi});
    :hover {
      background-image: url(${pepsiActive});
    }
  }
  .pepsi_tab.active {
    background-image: url(${pepsiActive});
    border-radius: 0;
    background-color: #800018
  }
  .pepsi_tab.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media screen and (min-width: 768px) {
    .tabs {
      height: 80px;
    }
    
  //  WINS TAB STYLE
    
  .wins_tab.en {
    background-image: url(${winsBig});
    :hover {
      background-image: url(${winsActiveBig});
    }
  }
  .wins_tab.en.active {
    background-image: url(${winsActiveBig});
  }  
  
  .wins_tab.fr {
    background-image: url(${winsFr});
    :hover {
      background-image: url(${winsActiveFr});
    }
  }
  .wins_tab.fr.active {
    background-image: url(${winsActiveFr});
  }

  //SAVES TAB STYLE
    
  .saves_tab.en {
    background-image: url(${savesBig});
    :hover {
      background-image: url(${savesActiveBig});
    }
  }
  .saves_tab.en.active {
    background-image: url(${savesActiveBig});
  }    
  
  .saves_tab.fr {
    background-image: url(${savesFr});
    :hover {
      background-image: url(${savesActiveFr});
    }
  }
  .saves_tab.fr.active {
    background-image: url(${savesActiveFr});
  }
  
  //GOALS TAB STYLE 
    
  .goals_tab.en {
    background-image: url(${goalsBig});
    :hover {
      background-image: url(${goalsActiveBig});
    }
  }
  .goals_tab.en.active{
    background-image: url(${goalsActiveBig});
  }   
  
  .goals_tab.fr {
    background-image: url(${goalsFr});
    :hover {
      background-image: url(${goalsActiveFr});
    }
  }
  .goals_tab.fr.active {
    background-image: url(${goalsActiveFr});
  }
  
  // PEPSI TAB STYLE
  .pepsi_tab.en {
    background-image: url(${pepsiBig});
    background-size: 160px 50px;
    :hover {
      background-image: url(${pepsiActiveBig});
    }
  }
  .pepsi_tab.en.active {
    background-image: url(${pepsiActiveBig});
    background-size: 160px 50px;
  }
    .pepsi_tab.fr {
    background-image: url(${pepsiFr});
    :hover {
      background-image: url(${pepsiActiveFr});
    }
  }
  .pepsi_tab.fr.active {
    background-image: url(${pepsiActiveFr});
  }
}
`;
