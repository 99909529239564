import {useContext, useState, useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {PlayersContext} from "../../../../context/PlayersContext";

const COLLECTIVE_LEADERBOARD = process.env.REACT_APP_COLLECTIVE_LEADERBOARD;

const collectiveLeaderboard = COLLECTIVE_LEADERBOARD
    ? +COLLECTIVE_LEADERBOARD
    : 0;

export const useOverall = ({ setIsDropdown }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const tournament_id = searchParams.get('tournament_id');
    const [overallPeriodsState, setOverallPeriodsState] = useState([]);
    const [currentOverallSelection, setCurrentOverallSelection] = useState(collectiveLeaderboard !== 0 ? null : null);
    const period = overallPeriodsState.length && overallPeriodsState.at(-1)?.title;

    const {fetchTournamentData} = useContext(PlayersContext);

    useEffect(() => {
        const getTournaments = async () => {
            const tournaments = await fetchTournamentData();
            setOverallPeriodsState(tournaments);
            if (tournament_id) {
                const f = tournaments.find(
                    (item) => item.league_tournament_id === tournament_id
                );
                if (f) {
                    setCurrentOverallSelection(f);
                } else {
                    navigate('?');
                }
            }
        };
        getTournaments();
    }, []);

    const handleOverallSelection = (item) => {
        setCurrentOverallSelection(item);
        setIsDropdown(false);
    };

    useEffect(() => {
        if (currentOverallSelection) {
            navigate(
                `?tournament_id=${currentOverallSelection.league_tournament_id}`
            );
        }
    }, [currentOverallSelection ? currentOverallSelection.title : null]);

    return {
        overallPeriodsState,
        handleOverallSelection,
        currentOverallSelection,
        setCurrentOverallSelection,
        period,
    };
};
