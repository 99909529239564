import React, { useEffect } from 'react';
import { AppStyles, GlobalStyles, Reset } from './App.styles';
import AppRouter from '../AppRouter';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReferenceDataContextProvider } from '../context/PlayersContext';
import { ThemeProvider } from 'styled-components';
import defaultTheme from '../assets/theme/defaultTheme';
import { useAuth } from './useAuth';
import { StateContextProvider } from '../context/StateContex';
import Loader from '../pages/Loader/Loader';
declare const window: Window & { dataLayer: Record<string, unknown>[] };

const App: React.FC = () => {
    const loading = useAuth();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'page_view'
        });
    }, [window.location.pathname])

    if (localStorage.getItem('jwt') && localStorage.getItem('navigateTo')) {
        let nav = localStorage.getItem('navigateTo')
        nav = nav ? decodeURIComponent(nav.replaceAll('"', '')) : nav
        if (nav) {
            window.location.href = nav
            localStorage.removeItem('navigateTo')
        }
    }

    if (loading) {
        return (
            <div style={{ height: '100vh', width: '100vw' }}>
                <Loader />
            </div>
        );
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <Reset />
            <ReferenceDataContextProvider>
                <GlobalStyles />
                <StateContextProvider>
                    <AppStyles>
                        {/* <Layout> */}
                        <AppRouter />
                        {/* </Layout> */}
                    </AppStyles>
                </StateContextProvider>
            </ReferenceDataContextProvider>
        </ThemeProvider>
    );
};

export default App;
