import colors from "./colors";

const defaultTheme = {
  // fonts: ["sans-serif", "Sintony"],
  fonts: ["sans-serif", "averta-bold"],
  colors,
  space: (value: number) => value * 8 + "px",
  shadow: "0px 0px 10px 0px rgb(0 0 0 / 0.8)",
};

export default defaultTheme;
