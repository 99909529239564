import styled from "styled-components";
import colors from "../../../assets/theme/colors";
import HockeyFieldBnw from './../images/HockeyFieldBnw.svg';

export const SharePopupContainer = styled('div')({
    maxWidth: '500px',
    position: 'relative',
    width: '100%',
    margin: '25vh auto',
    padding: '0 20px',
    borderRadius: '18px',
    zIndex: 100,
    '@media screen and (max-width: 768px)': {
        maxWidth: '400px',
        height: '500px',
    },
});

export const SharePopupBodyContainer = styled('div')({
    position: "relative",
    height: 'max-content',
    backgroundColor: 'white',
    borderBottomLeftRadius: '18px',
    borderBottomRightRadius: '18px',
    color: 'black',
    textAlign: 'center',
    padding: '24px',
})


export const SharePopupBackdrop = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    width: '100vw',
    height: '100vh',
    background: 'rgb(0, 0, 0, 0.7)',
});

export const SharePopupCrossContainer = styled("div") ({
 margin: '16px 8px', zIndex: 100,
});

export const ShareBlueGoalieContainer = styled("div") ({
    background: 'linear-gradient(0deg, #003D75 0%, #0025FF 100%)',
    height: 'auto',
    minHeight: '150px',
    borderTopLeftRadius: '18px',
    borderTopRightRadius: '18px',
    position: "relative",

    
    ".htmlToCanvasResult": {
        backgroundColor: colors.primaryDarkGrey,
        backgroundImage: `url(${HockeyFieldBnw})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "25%",
        left: "50%",
        right: "0",
        height: "300px",
        overflow: "hidden",
        width: "90%",
        margin: "auto",
        borderRadius: "20px",
        '@media screen and (min-width: 1920px)': {
            width: "95%",
        },
    },

    '@media screen and (max-width: 768px)': {
        display: 'flex',
        justifyContent: 'end',
        // height: '200px',
    },
});
export const ShareTitle = styled("h3") ({
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "27px",
    maxWidth: "332px",
    margin: "auto",
    textAlign: "center",
    '@media screen and (max-width: 768px)': {
        fontWeight: "500",
        fontSize: "18px",
    },
});

export const ShareIcons = styled("div") ({
    display: "flex",
    justifyContent: "center",
    gap: "24px",
    margin: "40px 0 30px",
});
export const ShareCopyBoardTitle = styled("p") ({
    margin: `0 0 15px`,
    textAlign: 'left',
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "140%",
});

