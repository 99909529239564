import styled from "styled-components";
import Bg from "../../assets/images/background_leagues.png"

export const Wrapper = styled("div")({
    background: "#1E1E1E",
    position: "relative",
    minHeight: "100vh",
});

export const WrapperHeader = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "25px",
    "@media screen and (min-width: 768px)" : {
        padding: "60px 25px",
        backgroundColor: "black",
        backgroundImage: `url(${Bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top right",
        height:"54vh",
    }
})

export const HeaderContent = styled("div")({
    maxWidth: "500px",
});

export const WrapperTable = styled("div")({

});


export const ContentWrapper = styled("div")({
    padding: "0px 25px 25px 25px",
});

export const Title = styled("h1")({
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "130%",
    textAlign: "center",
    color: "#FFFFFF",
});

export const Description = styled("p")({
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "5px",
});

export const SubscriptionsText = styled("p")({
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "140%",
    textAlign: "center",
    color: "#FFFFFF",
    marginTop: "10px",
});