import styled from "styled-components";
import { variant } from "styled-system";

interface InputProps {
    borderTick?: boolean;
    positionVar?: boolean;
    inputVariant?: string;
};

export const InputComponent = styled("input")<InputProps>(
    (prop) => ({
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "12px 0px",
        background: "#383837",
        border: "none",
        borderBottom: `2px solid ${prop.borderTick ? "#0CD664" : "#E4002B"}`,
        color: "#FFFFFF",
        borderRadius: "6px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "140%",
        position: prop.positionVar ?  "relative" : "static",
        "::placeholder": {
            color: "#FFFFFF"
        }
    }),
    variant({
        prop: "inputVariant",
        variants: {
            custom: {
                ":focus": {
                    outlineWidth: "0px",
                }
            }
        }
    })
);