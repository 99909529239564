import React from "react";

const HowToPlay: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11L21 6V18L3 14V11Z" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.5997 16.7992C11.4947 17.1801 11.3156 17.5365 11.0728 17.8481C10.83 18.1597 10.5282 18.4205 10.1847 18.6155C9.84108 18.8105 9.46245 18.9359 9.07041 18.9846C8.67836 19.0333 8.28056 19.0043 7.89973 18.8992C7.51889 18.7942 7.16248 18.6151 6.85084 18.3723C6.5392 18.1295 6.27844 17.8277 6.08343 17.4841C5.88843 17.1406 5.76301 16.7619 5.71433 16.3699C5.66565 15.9778 5.69467 15.5801 5.79973 15.1992" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default HowToPlay;