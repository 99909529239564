import styled from 'styled-components';
import background from './background.png';

export const Container = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
    padding: '20px 18px',
    '@media screen and (min-width: 768px)': {
        backgroundImage: `url(${background})`,
        padding: '50px',
    },
});

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px',
});

export const Title = styled('h1')({
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '130%',
    textAlign: 'center',
    '@media screen and (min-width: 768px)': {
        fontSize: '40px',
    },
});

export const Description = styled('p')({
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    marginTop: '20px',
    maxWidth: '450px',

    '@media screen and (min-width: 768px)': {
        marginBottom: '40px',
        fontSize: '20px',
    },
});

export const ImagePepsiLogo = styled('img')({
    margin: '20px 0px',
    width: '242px',
    height: '282px',
    '@media screen and (min-width: 768px)': {
        display: 'none',
    },
});

export const DesktopImagePepsiLogo = styled('img')({
    display: 'none',
    '@media screen and (min-width: 768px)': {
        display: 'block',
        width: '318px',
        height: '370px',
    },
});

export const RulesLink = styled('span')({
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'center',
    marginTop: '25px',
});
