import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { IPickem } from "../../context/type"
import { useParams } from "react-router-dom"
import { useWindowSize } from "../../helpers/useWidth"

interface ICountdown {
    days: number,
    hours: number,
    minutes: number,
    seconds: number,
}

const CountdownTimer = ({currentPickem}: {currentPickem: IPickem}) => {
    // const picksUntil = useSelector((state: any) => state.bracket.game.allow_picks_until)
    const picksTimestamp = new Date(currentPickem.start)
    const { langId } = useParams();
    const { t } = useTranslation()
    const {width} = useWindowSize();

    const [{days, hours, minutes, seconds}, setCountdown] = useState<ICountdown>({days: 0, hours: 0, minutes: 0, seconds: 0})
    const [isTimerGoing, setIsTimerGoing] = useState(false)

    useEffect(() => {
        if (typeof currentPickem.start === "string" && !isTimerGoing) {setIsTimerGoing(true)}
        setInterval(() => {
            if (isTimerGoing) {

                const now = new Date();
                
                const diffInMs = picksTimestamp.getTime() - now.getTime();
                
                const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
                const diffInHours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
                const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000);
                setCountdown({days: diffInDays, hours: diffInHours, minutes: diffInMinutes, seconds: diffInSeconds})
            }
                
            }, 1000);
            
    }, [picksTimestamp])

    const startHours = picksTimestamp.getHours();
    const startMinutes = picksTimestamp.getMinutes();
    const amPm = startHours >= 12 ? "PM" : "AM";
    const formattedHours = startHours % 12 || 12;
    const formattedMinutes = startMinutes < 10 ? `0${startMinutes}` : startMinutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`

    return (
        <div style={{
            marginBottom: '18px', minWidth: '300px',
            maxWidth: "330px", minHeight: '90px', padding: '8px 14px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            background: "rgba(2, 43, 83, 0.40)", border: '1px solid #015EB7', borderRadius: '8px', textAlign: 'center'
        }}>
            <span style={{fontSize: '12px', margin: width < 360 ? '0 -10px' : 'unset'}}>
                {langId === 'en' ? `Picks will lock at puck drop of the ${formattedTime} ET game(s)` : `Les choix seront verrouillés au début du (des) match(s) de ${formattedTime} HE`}
            </span>

            <div style={{width: '100%', display: 'flex', justifyContent: 'center',}}>
                {days > 0 ? <>
                    <div style={{width: '30%', display: 'flex', flexDirection: 'column', textTransform: 'capitalize'}}>
                        <div style={{fontSize: '30px', fontWeight: 700, height: '28px'}}>{days}</div>
                        <div style={{fontSize: '10px', fontWeight: 700, lineHeight: '18px'}}>{langId === 'en' ? 'Days' : 'Jours'}</div>
                    </div>
                    <div style={{width: '2px', border: "1px solid #015EB7"}}/>
                    </>
                    : null
                }

                <div style={{width: '30%', display: 'flex', flexDirection: 'column', textTransform: 'capitalize'}}>
                    <div style={{fontSize: '30px', fontWeight: 700, height: '28px'}}>{hours}</div>
                    <div style={{fontSize: '10px', fontWeight: 700, lineHeight: '18px'}}>{langId === 'en' ? 'Hours' : 'Heures'}</div>

                </div>
                <div style={{width: '2px', border: "1px solid #015EB7"}}/>
                <div style={{width: '30%', display: 'flex', flexDirection: 'column', textTransform: 'capitalize'}}>
                    <div style={{fontSize: '30px', fontWeight: 700, height: '28px'}}>{minutes}</div>
                    <div style={{fontSize: '10px', fontWeight: 700, lineHeight: '18px'}}>{langId === 'en' ? 'Minutes' : 'Minutes'}</div>

                </div>
                {days <= 0 ? <>
                <div style={{width: '2px', border: "1px solid #015EB7"}}/>
                <div style={{width: '30%', display: 'flex', flexDirection: 'column', textTransform: 'capitalize'}}>
                    <div style={{fontSize: '30px', fontWeight: 700, height: '28px'}}>{seconds}</div>
                    <div style={{fontSize: '10px', fontWeight: 700, lineHeight: '18px'}}>{langId === 'en' ? 'Seconds' : 'Secondes'}</div>
                </div>
                </>
                : null
                }
            </div>

        </div>

        // minutes !== 0 ? <FlexContainer style={{flexDirection: "column"}}>
        //     <Span500>
        //         {t('bracket.lockIn')}
        //     </Span500>
        //     <Span500 style={{fontSize: '22px', fontWeight: 900}}>
        //         {`${hours}D:${minutes}H:${seconds}M`}
        //     </Span500>
        // </FlexContainer> : null
    )
}

export default CountdownTimer

const FlexContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    // '@media screen and (max-width: 970px)': {
    //   '.bracket_button': {
    //     height: '35px',
    //     fontSize: '12px',
    //     padding: '8px 15px'
    //   }
    // },
  });
  const Span500 = styled('span')({
    fontWeight: 500,
    fontSize: '17px',
    lineHeight: '20px',
    '@media screen and (max-width: 970px)': {
      fontSize: '14px',
    },
  });
  
  