import styled from 'styled-components';

interface StylesImageProps {
    marginRightVariant?: string;
}

export const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'space-between',
    padding: '25px',
});

export const LogoContainer = styled('div')({
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginBottom: '25px',
});

export const StylesImage = styled('img')<StylesImageProps>((props) => ({
    marginRight: props.marginRightVariant || '0px',
}));

export const Description = styled('span')({
    fontWeight: '500',
    fontSize: '9px',
    lineHeight: '11px',
});

export const DescriptionAbr = styled('span')({
    fontWeight: '700',
    fontSize: '9px',
    lineHeight: '12px',
    paddingBottom: '10px',
    textAlign: 'start',
});
