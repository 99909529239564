import React, {useEffect, useRef} from "react";
import {VideoPlayerProps} from "./types";
import Pepsi from "../../assets/videos/PZS_Wide.mp4";
import PepsiMobile from "../../assets/videos/PZS_Mobile.mp4";
import './video.css';
import { useWindowSize } from "../../helpers/useWidth";
const VideoPlayer: React.FC<VideoPlayerProps> = ({widthSize, heightSize}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const {width} = useWindowSize();
  const isDesktop = width > 970;

  useEffect(() => {
    videoRef.current?.play();
  }, []);


  return (
    <div>
      <video ref={videoRef} width={widthSize} height={heightSize} controls disablePictureInPicture>
        <source
          src={isDesktop ? Pepsi : PepsiMobile}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default VideoPlayer;