import axios from 'axios';
import { localStorageGet, localStorageSet } from '../utils/localStorage';

// localStorageSet("jwt", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo5LCJpYXQiOjE2NzMwMjMxOTUsImV4cCI6MTY3Mzg4NzE5NX0.UUSQ6BF6phWIdoz4AsISvk6pRIO_820vI2V007IzUv4")

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_LOGIN_API_KEY}`,
});

instance.interceptors.request.use(function (config: any) {
  const token = localStorageGet('jwt');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('jwt');
      localStorageSet('navigateTo', window.location.pathname)
      window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
    }
  }
);

interface LoginResponse {
  token: string;
  country: string;
  service_user_id: number;
  favourite_team_league_id: number;
}

export const getJWTToken = async (token: string) => {
  try {
    // const code = localStorage.getItem('token');
    // const token = code && JSON.parse(code);
    const jwt = await instance.post<LoginResponse>(`/login`, {
      token: token,
    });
    const location = {
      country: jwt.data.country,
    };
    localStorageSet('jwt', jwt.data.token);
    localStorageSet('country', location.country);
    localStorageSet('favourite_team_league_id', jwt.data.favourite_team_league_id);
  } catch {
    // window.location.replace(process.env.REACT_APP_URL_GAMEZONE);
    window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`;
  }
};

export type League = {
  league_id: number;
  title: string;
  code: string;
  league_type: 'CUSTOM' | 'FRIEND';
  league_image?: string;
  is_owner: boolean;
  rank: number;
};

interface GetUserLeagues {
  leagues: League[];
}

export const getUserLeagues = async () => {
  const { data } = await instance.get<GetUserLeagues>('/leagues?include_rank=true');

  return data;
};

interface CheckLeagueName {
  exists: boolean;
}
export const checkLeagueName = async (name: string) => {
  const { data } = await instance.get<CheckLeagueName>(`/league-name/exists?title=${name}`);
  return data;
};

interface CreateLeague {
  code: string;
}

export const createLeague = async (title: string) => {
  const { data } = await instance.post<CreateLeague>('/leagues', { title });
  return data;
};

interface JoinLeague {
  success: boolean;
}

export const joinLeague = async (code: string) => {
  const { data } = await instance.post<JoinLeague>('/leagues/join', { code });
  return data;
};

export const leaveLeague = async (league_id: number) => {
  const { data } = await instance.post<JoinLeague>('/leagues/leave', {
    league_id,
  });
  return data;
};

interface DeleteUserFromLeague {
  success: boolean;
}

export interface ISelection {
  question_id: number;
  option_id: number | string;
  bonus_selection_value?: string;
  custom_value?: number | string;
}

export const deleteUserFromLeague = async (league_id: number, user_id: number) => {
  const { data } = await instance.delete<DeleteUserFromLeague>(`/leagues/${league_id}/user`, {
    data: { user_id },
  });

  return data;
};

export const deleteLeague = async (league_id: number) => {
  const { data } = await instance.delete<DeleteUserFromLeague>(`/leagues/${league_id}`);
  return data;
};

export const getSelections = async () => {
  const { data } = await instance.get('/pickems/1/selections?user_id=1&include_stats=true');
  return data;
};

export interface IUserPickemSelections {
  selections: Array<any>;
  stats: {
    avg: number;
    max: number;
  }
}

export const getUserPickemSelections = async (userId: string | number, pickemId: string | number): Promise<IUserPickemSelections> => {
  const { data } = await instance.get(`/pickems/${pickemId}/selections?user_id=${userId}&include_stats=true`);
  return data;
};

export const postSelections = async (pickemId: number, selections: Array<ISelection>) => {
  const data = await instance.post(`/pickems/${pickemId}/selections`, {
    selections,
  });
  return data;
};
