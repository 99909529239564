import styled from "styled-components";
import colors from "../../assets/theme/colors";

export const Container = styled("div") ({
  textAlign: "center",
  "@media screen and (min-width: 768px)": {
    maxWidth: "1040px",
    margin: "auto"
  }
})

export const LeagueAdminHeader = styled("div") ({
  padding: "13px 0px 16px 0px",
  "h1": {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "25.2px",
  }
})

export const LeagueMember = styled("div") ({
  border: `1px solid ${colors.secondaryGrey}`,
  padding: "16px 30px 16px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: "black"
})

export const LeagueMemberName = styled("div") ({
  display: "flex",
  alignItems: "center",
  marginLeft: "14px",
  // "& svg": {
  //   marginRight: "14px",
  // }
})

export const LeagueMemberIndex = styled("div") ({
  width: "36px",
})

export const LeagueMemberScore = styled("div") ({
  width: "28px", marginLeft: "auto",
})

export const LeagueButtonsContainer = styled("div") ({
  padding: "36px 16px",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
  "@media screen and (min-width: 768px)": {
    flexDirection: "row",
  }
})

export const AdminLeagueInputContainer = styled("div") ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "13px 20px",
  fontWeight: "400",
  fontSize: "16px",
  textAlign: "center",
  color: "white",
  background: colors.greyInputButton,
  borderRadius: "8px",
  border: "none",
  cursor: "pointer",
  "@media screen and (min-width: 768px)": {
    width: "100%",
  }
})

export const StyledAdminMemberInput = styled("input") ({
  height: "25px",
  fontSize: "16px",
  border: "none",
  background: colors.greyInputButton,
  color: "white",
  textAlign: "center",
  ":focus" :{
    outline: "none",
  },
  // "&textarea": {
  // },
  "::placeholder": {
    color: "whitesmoke",
  }
})

export const LeagueMembersHeader = styled("div") ({
  border: `1px solid ${colors.secondaryGrey}`,
  padding: "16px 30px 16px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: colors.greyInputButton
})