import { useTranslation } from 'react-i18next';
import PicksHomeNavigation from '../../Components/PicksHomeNavigation';
import { StyledButton } from '../PepsiShutout/PepsiShutout.styles';
import { Container, H1W700S36, P14W700, P18W400 } from './TimeoutAlert.styled';

const TimeoutAlert = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <PicksHomeNavigation currentPage={'leaderboard'} />
            <div style={{ textAlign: 'center', padding: '60px 47px 0px 47px' }}>
                <H1W700S36
                >
                    {t('timeOut')}
                </H1W700S36>

                <P18W400>
                    {t('thisWek')}
                </P18W400>

                <P14W700>
                    {t('comeBackSoon')}
                </P14W700>
            </div>

            <StyledButton style={{ marginTop: '40px' }}>
                {t('backtoGz')}
            </StyledButton>
        </Container>
    );
};

export default TimeoutAlert;
