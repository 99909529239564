import React from "react";

const ShareBlack: React.FC = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9993 26.2166V19.3965C7.33112 19.3965 1.97435 27.8729 0.416016 31.0881C0.416016 20.3708 3.92227 9.45861 15.9993 7.70487V0.884766L31.5827 13.843L15.9993 26.2166Z" fill="black"/>
        </svg>
    );
}

export default ShareBlack;