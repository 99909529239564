import React from "react";
import {InputProps} from "./types";
import {InputComponent} from "./Input.styles";
import {useTranslation} from "react-i18next";


const Input: React.FC<InputProps> = ({
     textInput,
     updateItem,
     value,
     type = "text",
     disabled,
     inputVariant,
     addButton,
     borderTick,
     readonly,
     defaultValue,
     checkItem,
     isLoaded,
     maxItem,
     join
}) => {
    const {t} = useTranslation()

    return (
        <InputComponent
            type={type}
            borderTick={borderTick}
            autoFocus
            positionVar={addButton}
            value={isLoaded ? " " : maxItem ? `${join ? t("maxLeagueJoined") : t("maxLeagueCreate")}` : value}
            inputVariant={inputVariant}
            defaultValue={defaultValue}
            onChange={updateItem}
            placeholder={textInput}
            disabled={isLoaded ? true : maxItem ? true : disabled}
            readOnly={readonly}
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    event.preventDefault();
                    if (checkItem) {
                        checkItem();
                    }
                }
            }}
        />
    );
};

export default Input;