import {
    StyledButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
    B,
    BlueGoalieContainer,
    PopupBackdrop,
    PopupBodyContainer,
    PopupContainer,
    PopupCrossContainer,
} from "../Popups/Popups.styles" ;
import CongratPopupBg from './images/CongratPopupBg.png';
import { ILeaveLeaguePopupProps } from '../PepsiShutout/types';
import { CaretRight } from '../../assets/icons/CaretRight';
import CrossIcon from '../../assets/icons/CrossIcon';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { leaveLeague } from '../../api/pickems';
import paths from '../../AppRouter/paths';
import { useLazyQuery } from '../../hooks/useLazyQuery';
import { StateContext } from '../../context/StateContex';
import { useContext } from 'react';

const LeaveLeaguePopup = ({
    currentViewedLeague,
    isVisible,
    setIsVisible,
}: ILeaveLeaguePopupProps) => {
    const navigate = useNavigate();

    const {userLeagues} = useContext(StateContext) 
    const { t } = useTranslation();
    const { langId } = useParams();
    const leaveLeagueQuery = useLazyQuery<{ league_id: number }, any>({
        fetcher: ({ league_id }) => leaveLeague(league_id),
    });

    const handleLeaveLeague = async () => {
        if (!currentViewedLeague) return;
        const data = await leaveLeagueQuery.fetchData({
            league_id: currentViewedLeague.league_id,
        });
        if (data.success) {
            navigate(`/${langId}${paths.myLeagues}`);
            await userLeagues.refetchData();
        } else {
            alert('Error');
        }
    };
    
    return (
        <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
            <PopupCrossContainer
                onClick={() => {
                    setIsVisible(false);
                }}
            >
                <CrossIcon />
            </PopupCrossContainer>
            <PopupContainer>
                <BlueGoalieContainer>
                    <img
                        src={CongratPopupBg}
                        alt={'goalie'}
                        style={{ float: 'right' }}
                    />
                </BlueGoalieContainer>
                <PopupBodyContainer>
                    <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                        {t('leaveLeag')}
                    </h1>
                    <p style={{ fontWeight: 400, marginTop: '16px' }}>
                        {t('yousure')}
                        <B> {currentViewedLeague?.title}</B>
                    </p>

                    <p
                        style={{
                            marginTop: '18px',
                            fontWeight: 700,
                            fontSize: '16px',
                        }}
                    >
                        {t('confirmBelow')}{' '}
                    </p>

                    <StyledButton
                        onClick={handleLeaveLeague}
                        style={{ marginTop: '18px' }}
                    >
                        {t('confirm')}
                        <CaretRight />
                    </StyledButton>
                </PopupBodyContainer>
            </PopupContainer>
        </PopupBackdrop>
    );
};

export default LeaveLeaguePopup;
