import React from "react";
import {Row, Value} from "./MyLeaguesItem.styles";
import RedTick from "./assets/RedTick";
import RightArray from "../../../assets/icons/RightArray";
import {MyLeaguesItemProp} from "./types";

const MyLeaguesItem: React.FC<MyLeaguesItemProp> = ({
    leaderName,
    rank,
    onClick
}) => {
    return(
        <>
            <Row onClick={onClick}>
                <Value>{rank}</Value>
                <Value style={{textAlign: "left"}}>{leaderName}</Value>
                <Value>
                    <RightArray/>
                </Value>
            </Row>
        </>
    );
};

export default MyLeaguesItem;