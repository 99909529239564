import styled from "styled-components";
import colors from "../../assets/theme/colors";

export const Wrapper = styled("div")({
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 100,
    width: "100vw",
    height: "100vh",
    background: "rgb(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

export const ContentContainer = styled("div")({
    width: "80vw",
    zIndex: 100,
    borderRadius: "18px",
    "@media screen and (min-width: 768px)" : {
        width: "560px",
    }
});

export const HeaderContainer = styled("div")({
    background: colors.gradientBlue,
    borderTopLeftRadius: "18px",
    borderTopRightRadius: "18px",
    position: "relative",
});

export const StyleImage = styled("img")({
    width: "100%",
})

export const CrossContainer = styled("div")({
    cursor: "pointer",
    position: "absolute",
    top: "10px",
    right: "10px",
    "@media screen and (min-width: 768px)": {
        top: "35px",
        right: "35px",
    }
});

export const BodyContainer = styled("div")({
    height: "max-content",
    backgroundColor: "white",
    borderBottomLeftRadius: "18px",
    borderBottomRightRadius: "18px",
    color: "#000000",
    textAlign: "center",
    padding: "20px 20px",
    "@media screen and (min-width: 768px)": {
        padding: "20px 80px",
    }
});

export const Title = styled("h1")({
    fontWeight: "700",
    lineHeight: "130%",
    "@media screen and (min-width: 768px)": {
        fontSize: "40px",
    }
});

export const Description = styled("p")({
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "130%",
    marginTop: "8px",
    "@media screen and (min-width: 768px)": {
        fontSize: "24px",
        marginTop: "8px"
    },
});

export const StylesSpan = styled("span")({
    fontWeight: "700",
});

export const StyleCode = styled("p")({
    margin: "10px 0px",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "130%",
    "@media screen and (min-width: 768px)": {
        margin: "20px 0px",
        fontWeight: "700",
        fontSize: "24px",
        lineHeight: "130%",
    }
});

export const StyleButton = styled("button") ({
    borderRadius: "8px",
    width: "max-content",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    border: "none",
    cursor: "pointer",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "130%",
    "@media screen and (min-width: 768px)": {
        fontSize: "24px",
        padding: "17px 18px 15px 24px",
    }
})