import React from "react";
import RightArray from "../../assets/icons/RightArray";
import {FirstLetterCapital, StylesButton} from "./Button.styles";
import {ButtonProps} from "./types";

const Button: React.FC<ButtonProps> = ({
    startIcon,
    endIcon,
    fullWidth,
    text,
    handleClick,
    color = undefined,
    type = "button",
    buttonMargin,
    iconItem,
    disabled,
    colorIcon,
    paddingRight,
    customStyle,
}) => {
    return (
        <StylesButton
            onClick={handleClick}
            fullWidth={fullWidth}
            colorVariant={color}
            type={type}
            buttonMargin={buttonMargin}
            disabled={disabled}
            colorIcon={colorIcon}
            style={customStyle}
        >
            {iconItem}
            <FirstLetterCapital paddingRight={paddingRight}>{text}</FirstLetterCapital>
            {endIcon}
        </StylesButton>
    );
};

export default Button;