import {
  StyledButton,
} from '../PepsiShutout/PepsiShutout.styles';
import {
  PopupBackdrop,
  PopupBodyContainer,
  PopupContainer,
  P16W700,
  BlueGoalieContainer,
} from "../Popups/Popups.styles"
import CongratPopupBg from './images/CongratPopupBg.png';
import { IPopupProps } from '../PepsiShutout/types';
import { CaretRight } from '../../assets/icons/CaretRight';
import paths from '../../AppRouter/paths';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { PlayersContext } from '../../context/PlayersContext';

const GameAlreadyInPlayPopup = ({ isVisible, setIsVisible }: IPopupProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { langId } = useParams();
  const closeCrossFetch = () => {
      setIsVisible(false);

      fetchAllPlayersData();

      navigate(`/${langId}${paths.myLeagues}`);
  };
  const {allPlayersData, fetchAllPlayersData} = useContext(PlayersContext)
  const currentPopupText = allPlayersData[0].status === "IN_PLAY" ? "Game already in play!" : (allPlayersData[0].status === "COMPLETE" ? "Game has already completed!" : "Something went wrong")
  return (
      <PopupBackdrop style={{ display: isVisible ? 'block' : 'none' }}>
          <PopupContainer>
              <BlueGoalieContainer>
                  <img
                      src={CongratPopupBg}
                      alt={'goalie'}
                      style={{ float: 'right' }}
                  />
              </BlueGoalieContainer>
              <PopupBodyContainer>
                  <h1 style={{ fontSize: '24px', fontWeight: 700 }}>
                      {currentPopupText}
                  </h1>
                  <p style={{ marginTop: '8px', fontWeight: 400 }}>
                      Come back later to vote for upcoming games
                  </p>
                  <P16W700>
                      Or check out our friends leagues  feature
                  </P16W700>

                  <StyledButton
                      onClick={closeCrossFetch}
                      style={{ marginTop: '18px' }}
                  >
                      {t('createAndJoin')}
                      <CaretRight fill="white" />
                  </StyledButton>
              </PopupBodyContainer>
          </PopupContainer>
      </PopupBackdrop>
  );
};

export default GameAlreadyInPlayPopup;
