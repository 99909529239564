import styled from 'styled-components';
// @ts-ignore
import headerBackground from '../../assets/images/nhl_pepsi_header.png';
const hasMobMode =
    window.innerHeight / window.innerWidth < 2 && window.innerWidth < 768;

const hasMobSecondMode =
    window.innerHeight / window.innerWidth > 1.7 && window.innerWidth < 768;
export const Wrapper = styled('header')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '155px',
    position: 'relative',
    background: `url(${headerBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100vw',
});

export const BurgerButton = styled('button')({
    position: 'absolute',
    background: 'none',
    border: 'none',
    left: '20px',
    top: '50%',
    cursor: 'pointer',
    padding: '0px',
    margin: '0px',
    '@media screen and (min-width: 768px)': {
        display: 'none',
    },
});
export const ImgLogoFanVote = styled('img')({
    margin: hasMobMode ? '0 auto' : '1rem auto 0 auto',
    maxWidth: '45vw',

    '@media screen and (min-width: 768px)': {
        width: '24vw',
        margin: '1.5rem auto 0 auto',
    },
});
export const LogoImage = styled('img')({
    margin: hasMobMode ? '0 auto' : '1rem auto 0 auto',
    maxWidth: '45vw',

    '@media screen and (min-width: 768px)': {
        width: '24vw',
        margin: '1.5rem auto 0 auto',
    },
});

type FlexContainerProps = {
    customStyles?: { [key: string]: string | number };
};

export const HeaderContainer = styled('div')(({ theme }) => ({
    width: '100vw',
    background: `url(${headerBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: "relative",

    '@media screen and (min-width: 768px)': {},
}));

export const FlexContainer = styled('div')<FlexContainerProps>(
    ({ customStyles }) => {
        return {
            display: 'flex',
            alignItems: 'center',
            ...customStyles,
        };
    }
);

export const HeaderTopContainer = styled('div')({
    display: 'flex',
    width: '100vw',
    zIndex: 2,
    alignItems: 'center',
    padding: hasMobMode
        ? hasMobSecondMode
            ? '0 1rem 0'
            : '0 1rem 0.5rem'
        : '0 1rem 1rem',
    position: 'relative',
    '@media screen and (min-width: 768px)': {
        padding: '0 1rem 2rem',
    },
});
