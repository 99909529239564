import React from "react";

interface UseQueryParams<Params, Response> {
    fetcher: (params: Params) => Promise<Response>;
    //when the value is false, fetcher is not executed
    execute?: boolean;
}

interface UseQueryResponse<Params, Response> {
    isLoading: boolean;
    error: string;
    fetchData: (params: Params) => Promise<Response | null>;
    result: Response | null;
}

export const useLazyQuery = <Params, Response>({
    fetcher,
    execute = true,
}: UseQueryParams<Params, Response>): UseQueryResponse<Params, Response> => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<Response | null>(null);
    const [error, setError] = React.useState<string>("");

    const fetchData = async (params: Params): Promise<Response | null> => {
        if (!execute) return null;

        let data;
        setIsLoading(true);

        try {
            data = await fetcher(params);
            setError("");
            setResult(data);
        } catch (err: unknown) {
            if (typeof err === "string") setError(err);
            else setError("Unknown error");
            setResult(null);
        } finally {
            setIsLoading(false);
        }
        // @ts-ignore
        return data;
    };

    return { isLoading, error, fetchData, result };
};
