import styled from 'styled-components';
export const CloseButton = styled.div`
    color: black;
    position: absolute;
    cursor: pointer;

    right: 0px;
    font-size: 70px;
    z-index: 999999;
`;
export const CloseButtonMob = styled.div`
    color: white;
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
    font-size: 45px;
    z-index: 999999;
`;
