import styled from "styled-components";

export const StylesForm = styled("form")({
    position: "relative",
    width: "100%",
    marginBottom: "17px"
});

export const InputContainer = styled("div")({
    position: "relative",
});

export const ImageLoaded = styled("img")({
    position: "absolute",
    top: "0px",
    left: "50%",
    transform: "translate(-50%)",
    width: "37px"
});