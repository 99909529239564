import PepsiIcon from "../../assets/images/2023PepsiLogo.png";
import SearchIcon from "../../assets/icons/SearchIcon";
import ShareArrowIcon from "../../assets/icons/ShareArrowIcon";
import TrashcanIcon from "../../assets/icons/TrashcanIcon";
import Button from "../../Components/Button";
import {
  AdminLeagueInputContainer,
  Container,
  LeagueAdminHeader,
  LeagueButtonsContainer,
  LeagueMember,
  LeagueMemberIndex,
  LeagueMemberName,
  LeagueMemberScore,
  LeagueMembersHeader,
  StyledAdminMemberInput,
} from "./AdminLeaguePage.styles";
import DeleteLeaguePopup from "../Popups/DeleteLeaguePopup";
import React, { useContext, useEffect, useState } from "react";
import ShareLeaguePopup from "../Popups/ShareLeaguePopup";
import DeleteLeagueMemberPopup from "../Popups/DeleteLeagueMemberPopup";
import { ArrowRightIcon } from "../../assets/icons/ArrowRightIcon";
import { League } from "../../api/pickems";
import { useTranslation } from "react-i18next";
import { ITrs } from "../../utils/types/types";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { StateContext } from "../../context/StateContex";
import { useLeagueData } from "../../helpers/useLeagueData";
import { leaderboardPlayer } from "../../Components/Overall/Overall";
import Loader from "../Loader/Loader";
import paths from "../../AppRouter/paths";
import {useAdvancedPagination} from "../../hooks/useAdvancedPagination";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { PlayersContext } from "../../context/PlayersContext";
import { IPickem } from "../../context/type";

const AdminLeaguePage = ({
  currentLeague,
}: {
  currentLeague: League | undefined;
}) => {
  const { allPlayersData } = useContext(PlayersContext);
  const [isDeletePopupVisible, setIsDeletePopupVisible] = useState(false);
  const [isSharePopupVisible, setIsSharePopupVisible] = useState(false);
  const [isLeagueMemberPopupVisible, setIsLeagueMemberPopupVisible] = useState(false);
  const [leagueMemberToDelete, setLeagueMemberToDelete] = useState<leaderboardPlayer>();
  const [isLeagueMemberInputActive, setLeagueMemberInputActive] = useState(false);
  const [currentMemberInput, setCurrentMemberInput] = useState("");
  const [isLeagueMemberInputError, setIsLeagueMemberInputError] = useState(true);
  const [currentLeagueToRender, setCurrentLeagueToRender] = useState<League | undefined>();
  const { t } = useTranslation();
  const { langId } = useParams();
  const trs: ITrs = t("trs", {
    returnObjects: true,
  });
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { userLeagues } = useContext(StateContext);
  const searchParam_league_id = searchParams.get("league_id");

  // useEffect(() => {
  //   if (!searchParam_league_id || (userLeagues.result && !userLeagues?.result.leagues?.find((league: League) => league.league_id.toString() === searchParam_league_id))) {
  //     navigate(`${langId}/${paths.myLeagues}`)
  //   }
  // }, [userLeagues])


  const { leagueLeaderboard, getLeagueData, fetcherFunction } = useLeagueData(
    searchParam_league_id || ""
  );

  const { list, isLoading, onLoadMore } = useAdvancedPagination<
      any,
      leaderboardPlayer
  >({
    fetcher: ({limit, offset}) => fetcherFunction({limit, offset}),
    deps: [],
    execute: !!searchParam_league_id,
    limit: 10,
    mapResult: (data: any) => data
  });

  const { intersectionObserverRef } = useIntersectionObserver({
    onReachPageEnd: onLoadMore,
    deps: [],
  });

  useEffect(() => {
    // defining current league
    if (!currentLeague) {
      userLeagues.result?.leagues.find((league: League) => {
        if (league.league_id.toString() === searchParam_league_id) {
          setCurrentLeagueToRender(league);
          return league;
        }
      });
    }
    if (
      (currentLeague && !currentLeague.is_owner) ||
      (currentLeagueToRender && !currentLeagueToRender.is_owner)
    ) {
      navigate(`/${langId}${paths.myLeagues}`);
    }
  }, [currentLeague, userLeagues]);

  const handleOpenDeletePopup = () => {
    setIsDeletePopupVisible(true);
  };

  const handlePlayerInput = () => {
    setLeagueMemberInputActive(true);
  };

  const handleOpenSharePopup = () => {
    setIsSharePopupVisible(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMemberInput(event.target.value);
  };

  const handleInputBlur = () => {
    if (!currentMemberInput.length) {
      setLeagueMemberInputActive(false);
    }
  };

  const handleDeleteLeagueMember = () => {
    getLeagueData();
  }

  const handleLeagueMemberClick = (user: leaderboardPlayer) => () => {
    if (currentMemberInput.length || isLeagueMemberInputActive) {
      setLeagueMemberToDelete(user);
      setIsLeagueMemberPopupVisible(true);
    } else {
      const lastPickem = allPlayersData.find((pickem: IPickem) => pickem.status === "IN_PLAY" || pickem.status === "COMPLETE")
      navigate(`/${langId}${paths.leaderboardScores}/${user.user_id.toString()}/${lastPickem.pickem_id}`);
    }
  };

  const handleSubmitInput = () => {
    if (leagueLeaderboard.length === 1) {
      handleLeagueMemberClick(leagueLeaderboard[0])();
    }
  };

  return (
    <>
      {currentLeagueToRender && (
        <DeleteLeaguePopup
          currentLeague={currentLeagueToRender}
          isVisible={isDeletePopupVisible}
          setIsVisible={setIsDeletePopupVisible}
        />
      )}
      {currentLeagueToRender && (
        <ShareLeaguePopup
          currentLeague={currentLeagueToRender}
          isVisible={isSharePopupVisible}
          setIsVisible={setIsSharePopupVisible}
        />
      )}
      <DeleteLeagueMemberPopup
        currentLeague={currentLeagueToRender}
        memberToDelete={leagueMemberToDelete}
        leagueName={currentLeague?.title}
        handleDeleteLeagueMember={handleDeleteLeagueMember}
        isVisible={isLeagueMemberPopupVisible}
        setIsVisible={setIsLeagueMemberPopupVisible}
      />
      <Container>
        <LeagueAdminHeader>
          <h1>
            {currentLeagueToRender
              ? currentLeagueToRender?.title
              : "League Title"}
          </h1>
        </LeagueAdminHeader>
        <div>
          <LeagueMembersHeader>
            <LeagueMemberIndex>{t('rank')}</LeagueMemberIndex>
            <LeagueMemberName>{t('player')}</LeagueMemberName>
            <LeagueMemberScore>{t('total')}</LeagueMemberScore>
          </LeagueMembersHeader>

          {/* <LeaderboardPlaceholder /> */}
          {list
              .slice(0, 10)
              .filter((item) => item.username.toLowerCase().includes(currentMemberInput.toLowerCase()))
              .map((member, index) => {
            return (
              <LeagueMember
                key={index}
                onClick={handleLeagueMemberClick(member)}
              >
                <LeagueMemberIndex>{member.rank}</LeagueMemberIndex>
                <LeagueMemberName>
                  <img src={PepsiIcon} style={{width: '34px', height: '34px', marginRight: '14px'}}/>
                  {member.username}
                </LeagueMemberName>
                <LeagueMemberScore>{member.points}</LeagueMemberScore>
              </LeagueMember>
            );
          })}
        </div>

        <LeagueButtonsContainer>
          <Button
            fullWidth
            text={trs.delete}
            endIcon={<TrashcanIcon />}
            handleClick={handleOpenDeletePopup}
          />

          {!isLeagueMemberInputActive ? (
            <Button
              fullWidth
              text={trs.remove}
              endIcon={<SearchIcon />}
              handleClick={handlePlayerInput}
            />
          ) : (
            <AdminLeagueInputContainer
              style={{
                borderBottom: isLeagueMemberInputActive
                  ? isLeagueMemberInputError
                    ? "3px solid red"
                    : "3px solid green"
                  : "none",
              }}
            >
              <StyledAdminMemberInput
                type="text"
                onBlur={handleInputBlur}
                onChange={handleInputChange}
                value={currentMemberInput}
                autoFocus
                enterKeyHint={"search"}
                placeholder={trs.place}
                onSubmit={handleSubmitInput}
              />
              <div onClick={handleSubmitInput}>
                <ArrowRightIcon />
              </div>
            </AdminLeagueInputContainer>
          )}

          <Button
            fullWidth
            text={trs.shareleague}
            endIcon={<ShareArrowIcon />}
            handleClick={handleOpenSharePopup}
          />
        </LeagueButtonsContainer>
        {list
            .slice(10)
            .filter((item) => item.username.toLowerCase().includes(currentMemberInput.toLowerCase()))
            .map((member, index) => {
              return (
                  <LeagueMember
                      key={index}
                      onClick={handleLeagueMemberClick(member)}
                  >
                    <LeagueMemberIndex>{index + 11}</LeagueMemberIndex>
                    <LeagueMemberName>
                      <img src={PepsiIcon} style={{width: '34px', height: '34px', marginRight: '14px'}}/>
                      {member.username}
                    </LeagueMemberName>
                    <LeagueMemberScore>{member.points}</LeagueMemberScore>
                  </LeagueMember>
              );
            })}
        <div>
          <div style={{ color: "rgba(0,0,0,0.0)", textAlign: "center" }}>end</div>
          <div ref={intersectionObserverRef} />
        </div>
        {isLoading && <Loader />}
      </Container>
    </>
  );
};

export default AdminLeaguePage;
