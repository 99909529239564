import React from "react";

const Leaderboard: React.FC = () => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 8V0H6V6H0V18H20V8H14ZM8 2H12V16H8V2ZM2 8H6V16H2V8ZM18 16H14V10H18V16Z" fill="#262626"/>
        </svg>
    );
};

export default Leaderboard;