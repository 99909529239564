import styled from "styled-components";
// @ts-ignore
import bga from "../../assets/images/onboarding_background.png";

export const Wrapper = styled("div")({
    position: "fixed",
    right: 0,
    top: 0,
    zIndex: 3,
    width: "100%",
    height: "100vh",
    background: "silver",
});