import axios from "axios";
import { localStorageGet } from "../utils/localStorage";

export const FetchDevision = async () => {

  const getPickems2 = `${process.env.REACT_APP_LOGIN_API_KEY}/pickems?offset=0&limit=2`;

  const jwtToken = localStorageGet("jwt");
  try {
    const result2 = await axios.get(getPickems2, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    const data2 = result2.data.pickems;

    return data2;
  } catch {
    throw Error("Promise failed");
  }
};

export const FetchSinglePickem = async (pickemId: number | string) => {
  const getPickem = `${process.env.REACT_APP_LOGIN_API_KEY}/pickems/${pickemId}`

  const jwtToken = localStorageGet("jwt");

  try {
    const result2 = await axios.get(getPickem, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    const data2 = result2.data.pickem;

    return data2;
  } catch {
    throw Error("Promise failed");
  }
}

export const FetchPickemList = async () => {
  const getPickemsList = `${process.env.REACT_APP_LOGIN_API_KEY}/pickems?offset=0&limit=6&selections=false`;

  const jwtToken = localStorageGet("jwt");

  try {
    const result2 = await axios.get(getPickemsList, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    const data2 = result2.data.pickems;

    return data2;
  } catch {
    throw Error("Promise failed");
  }
}
