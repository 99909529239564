const colors = {
  primaryDarkGrey: "#262626",
  primaryGrey: "#999999",
  primaryLightGrey: "#dadada",
  secodaryDarkGrey: "#10100F",
  secondaryGrey: "#656563",
  greyInputButton: "#2D2D2C",
  greenScoreBackground: "#0CD664",
  lightGrayFont: "#808098",
  gradientBlue: "linear-gradient(0deg, #003D75 0%, #0025FF 100%)",
};

export default colors