import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #1d1d1b;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding-bottom: 40px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
`;

export const ImgHeader = styled.img`
  width: 100%;
`;
export const DesktopSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
  padding: 40px 0px;
  overflow: hidden;
`;
export const SliderTitle = styled.span`
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
  color: white;
  margin-bottom: 22px;
`;
export const BoxWrapper = styled.div`
  display: flex;
  width: 600px;
  height: 404px;
  border: 8px solid white;
  border-radius: 40px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 36px;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 58%;
  background: white;
  text-align: center;
  padding: 10px;
  justify-content: center;
  align-items: center;
`;

export const BoldText = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 140%;
  color: #1c1c1d;
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 140%;
  color: #1c1c1d;
`;
export const SlideImageWrapper = styled.div`
  max-height: 400px;
  width: 450px;
`;

export const SlideImage = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const ButtonWrapper = styled.div`
  width: 170px;
  margin: 0 auto;
  height: 52px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
`;
export const SliderWrap = styled.div`
  .slick-slider {
    height: auto;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin-right: 0px;
  }
  .slick-prev {
    left: 30px;
    top: 50%;
  }
  .slick-next {
    top: 50%;
    right: 30px;
  }
  .slick-prev::before,
  .slick-next::before {
    display: none;
  }
  .ft-slick__dots--custom {
    width: 8px;
    height: 8px;
    float: left;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s ease width;
    position: relative;
  }

  .slick-dots li {
    width: 8px;
    margin: 0 4px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active {
    width: 30px;
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    width: 32px;
    top: 0px;
    overflow: hidden;
    background-color: white;
  }
  .dots {
    width: 200px;
    height: 20px;
    margin: 0 auto;
    transform: translateY(-50%);
    overflow: hidden;
    z-index: 1;
  }
  .slick-dots::before {
    display: none;
  }
  .slick-dots {
    bottom: 30px;
  }
`;
