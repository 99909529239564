import React, { useContext, useEffect, useState } from 'react';
import {
    Wrapper,
    Title,
    ContentWrapper,
    Description,
    SubscriptionsText,
    HeaderContent,
    WrapperHeader,
    WrapperTable,
} from './MyLeagues.styles';
import JoinLeague from '../JoinLeague';
import CreateLeague from '../CreateLeague';
import MyLeaguesTable from '../MyLeaguesTable';
import { League } from '../../api/pickems';
import MyLeaguesItem from '../MyLeaguesTable/MyLeaguesItem/MyLeaguesItem';
import {useNavigate, useParams} from 'react-router-dom';
import paths from '../../AppRouter/paths';
import { useTranslation } from 'react-i18next';
import { StateContext } from '../../context/StateContex';
import AdsBanner from "../AdsBanner";
import { useWindowSize } from '../../helpers/useWidth';

interface MyLeaguesProps {
    setCurrentViewedLeague: React.Dispatch<React.SetStateAction<League | undefined>>;
}

const MyLeagues: React.FC<MyLeaguesProps> = ({
    setCurrentViewedLeague,
}: MyLeaguesProps) => {
    const [openJoin, setOpenJoin] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [listLeagues, setListLeagues] = React.useState<League[]>([]);
    const [leagueToDelete, setLeagueToDelete] = useState<League | null>(null);
    const { t } = useTranslation();

    const width = useWindowSize().width
    const isDesktop = width > 768;
    const { langId } = useParams();

    const navigate = useNavigate();
    const {userLeagues} = useContext(StateContext) 

    useEffect(() => {
        const list = userLeagues.result?.leagues.sort((a: any, b: any) => b.rank - a.rank);
        setListLeagues(list || []);
    }, [userLeagues.isLoading]);

    // const handleDeleteLeagueClick = (league: League) => {
    //     setLeagueToDelete(league);
    // };

    // const handleCloseDeleteLeagueModal = () => setLeagueToDelete(null);

    const handleNavigateLeague =
        (leagueId: number, leagueIndex: number) => (event: React.MouseEvent) => {
            if (
                listLeagues[leagueIndex]
                    ?.is_owner
            ) {
                setCurrentViewedLeague(listLeagues[leagueId]);
                navigate(`/${langId}${paths.admin}?league_id=${leagueId}`);
            } else {
                setCurrentViewedLeague(listLeagues[leagueId]);
                navigate(`/${langId}${paths.userLeague}?league_id=${leagueId}`);
            }
        };

    return (
        <Wrapper>
            <WrapperTable>
                {listLeagues.length !== 0 && (
                    <MyLeaguesTable>
                        {listLeagues.map((item: League, index: number) => (
                            <React.Fragment key={index}>
                                <MyLeaguesItem
                                    leagueId={item.league_id}
                                    isOwner={item.is_owner}
                                    leaderName={item.title}
                                    rank={item.rank}
                                    onClick={handleNavigateLeague(item.league_id, index)}
                                />
                            </React.Fragment>
                        ))}
                    </MyLeaguesTable>
                )}
            </WrapperTable>
            <WrapperHeader>
                <HeaderContent>
                    <ContentWrapper>
                        <Title>{t('play')}</Title>
                        <Description>{t('joinAnd')}</Description>
                    </ContentWrapper>
                    <JoinLeague
                        refetchData={userLeagues.refetchData}
                        openJoin={openJoin}
                        setOpenJoin={setOpenJoin}
                        setOpenCreate={setOpenCreate}
                        maxItem={listLeagues.length === 10}
                        isLoaded={userLeagues.isLoading}
                    />
                    <CreateLeague
                        refetchData={userLeagues.refetchData}
                        openCreate={openCreate}
                        setOpenCreate={setOpenCreate}
                        setOpenJoin={setOpenJoin}
                        maxItem={listLeagues.length === 10}
                        isLoaded={userLeagues.isLoading}
                    />
                    <SubscriptionsText>{t('youCan')}</SubscriptionsText>
                </HeaderContent>
            </WrapperHeader>
            {isDesktop ? <AdsBanner slotSize={[970, 250]}/>: <AdsBanner slotSize={[300, 250]}/> }
        </Wrapper>
    );
};

export default MyLeagues;