import styled from "styled-components"
// import TimeoutAlertBackground from "./images/TimeoutAlertBackground.svg"

export const Container = styled("div") ({
  // background: `url(${TimeoutAlertBackground})`,
  width: "100vw",
  height: "100vh",
});

export const H1W700S36 = styled("h1") ({
  fontSize: '36px',
  fontWeight: 700,
  lineHeight: '46.8px',
})

export const P18W400 = styled("p") ({
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '25.2px',
  marginTop: '12px',
})

export const P14W700 = styled("p") ({
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '19.6px',
  marginTop: '8px',
})