import React from "react";
import HomeBody from "../../Components/HomeBody";
import HomeFooter from "../../Components/HomeFooter";
import { Wrapper } from "./HomeScreen.styles";
import PicksHomeNavigation from "../../Components/PicksHomeNavigation";

const HomeScreen: React.FC = () => {
  return (
    <div>
      <PicksHomeNavigation currentPage={"game"} />
      <Wrapper>
        <HomeBody />
        <HomeFooter />
      </Wrapper>
    </div>
  );
};

export default HomeScreen;
