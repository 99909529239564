import React from 'react';
// @ts-ignore
import logo from '../../assets/images/2023LogoEnDesktop.png';
import logoFR from '../../assets/images/2023LogoFrDesktop.png';
import gzLogoDesk from '../../assets/images/2023_gamezone_logo_black.svg';
import gzLogoDeskFr from '../../assets/images/2023_gamezone_logo_fr_black.svg';
import Burger from '../../assets/icons/Burger';
import {
  // Wrapper,
  BurgerButton,
  LogoImage,
  HeaderContainer,
  HeaderTopContainer,
} from './ApplicationHeader.styles';
import Navigation from '../Layout/Components/Navigation';
import { useWindowSize } from '../../helpers/useWidth';
import gzLogo from './../../assets/images/2023_Gamezone_Logo.svg';
import gzLogoFR from './../../assets/images/2023_Gamezone_Logo_Fr.svg';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';

const ApplicationHeader: React.FC<any> = ({ onBurgerClick }) => {
  const lng = localStorage.getItem('i18nextLng');
  const width = useWindowSize().width;
  const isDesktop = width > 768;

  return (
    <HeaderContainer>
      {/* <Wrapper> */}
      <HeaderTopContainer>
        {isDesktop && (
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              position: 'absolute',
              top: '32px',
              alignItems: 'center',
              zIndex: 1000,
              columnGap: '12px',
              color: 'black',
            }}
            onClick={() => window.location.href = `${process.env.REACT_APP_URL_GAMEZONE}`}
          >
            {' '}
            {isDesktop && (
              <>
                <ChevronLeft />
              </>
            )}
            <img
              src={isDesktop ? (lng === 'fr' ? gzLogoDeskFr : gzLogoDesk) : lng === 'fr' ? gzLogoFR : gzLogo}
              style={{
                width: '50%',
                // WebkitBoxShadow: '0px 0px 60px 0px rgba(0,0,0,0.20)',
                // MozBoxShadow: '0px 0px 60px 0px rgba(0,0,0,0.20)',
                // boxShadow: '0px 0px 60px 0px rgba(0,0,0,0.20)',
                maxWidth: '280px',
                borderRadius: '6px',
              }}
            />
            {/* <div  style={{cursor: 'pointer',}}>
                    <ChevronLeft />
                    <Gznhl />
                    </div> */}
            {/* <span style={{display: 'inline-block'}}>Gamezone</span> */}
          </div>
        )}
        <LogoImage src={lng === 'fr' ? logoFR : logo} />
        <BurgerButton onClick={onBurgerClick}>
          <Burger />
        </BurgerButton>
      </HeaderTopContainer>
      {isDesktop && <Navigation />}
      {/* </Wrapper> */}
    </HeaderContainer>
  );
};

export default ApplicationHeader;
