import React from 'react';
import Button from '../Button';
import { Logout } from '../../assets/icons/Logout';
import { useTranslation } from 'react-i18next';
import { ITrs } from '../../utils/types/types';

const SidebarBottom: React.FC = () => {
    const { t } = useTranslation();
    const trs: ITrs = t('trs', {
        returnObjects: true,
    });

    return (
        <div>
            <Button
                handleClick={() => {
                    window.location.replace(`${process.env.REACT_APP_URL_GAMEZONE}`)
                }}
                text={trs.logOut}
                startIcon={
                    <div style={{ marginRight: '5px' }}>
                        <Logout />
                    </div>
                }
                color={'black'}
            />
        </div>
    );
};

export default SidebarBottom;
