import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserLeagues } from "../api/pickems";
import { useQuery } from "../hooks/useQuery";
import { PlayersContext } from "./PlayersContext";

const StateContext = createContext<any>({});

const StateContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {

  const [isVideoAdPopupOpen, setIsVideoAdPopupOpen] = useState(false)
  const [isCongratsPopupOpen, setIsCongratsPopupOpen] = useState(false)

  const userLeagues = useQuery({ fetcher: getUserLeagues });

 return (
    <StateContext.Provider
      value={{
        isVideoAdPopupOpen, setIsVideoAdPopupOpen,
        isCongratsPopupOpen, setIsCongratsPopupOpen,

        userLeagues,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateContextProvider };
