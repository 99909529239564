import styled from 'styled-components';
import bgImg from './image/backgroundImg.svg';
export const OverallPlayersWrapper = styled.div``;

type playerBackground = {
    bgColor: string;
}
export const OverallPlayerItem = styled.div<playerBackground>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px;
  marginBottom: 6px;
  max-width: 500px;
  cursor: pointer;
  // width: 100%;
  margin: auto;
  background-color: ${(props) =>
      props.bgColor === "black"
          ? "#10100F"
          : "#0025FF"
        };

  background-image: ${(props) =>
      props.bgColor === "blue"
          ? `url(${bgImg})`
          : "unset"
  };
  background-repeat: no-repeat;
  background-position: right;
  border-bottom: ${(props) =>
      props.bgColor === "black"
          ? "1px solid #656563"
          : "1px solid rgba(255, 255, 255, 0.8)"
        };
  ;
`;
export const OverallPlayerRank = styled.div`
    width: 20px;
`;
export const OverallPlayerUserName = styled.div`
  display: flex;
  //justify-content: space-between;
  align-items: center;
  width: 250px;
  img {
    margin-right: 20px;
  }
`;
export const OverallPlayerPoints = styled(OverallPlayerRank)`
`;