import axios from "axios";
import { localStorageGet } from "../utils/localStorage";

interface GetPickemLeaderboard {
  offset: number;
  limit: number;
  pickemId: number;
}

export const getPickemLeaderboard = async ({
  offset,
  limit,
  pickemId,
}: GetPickemLeaderboard) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_LOGIN_API_KEY}/leaderboard/pickem/${pickemId}`,
    {
      params: {
        offset,
        limit,
      },
    }
  );
  return data;
};

export const getCurrentUserPickemPosition = async (pickem_id: number) => {
  const jwtToken = localStorageGet("jwt");
  const { data } = await axios.get(
    `${process.env.REACT_APP_LOGIN_API_KEY}/pickem-leaderboard/${pickem_id}/user`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    }
  );
  return data;
};

export const FetchWeekly = async (pickemId: number) => {
  const jwtToken = localStorageGet("jwt");
  const allWeekly = `${process.env.REACT_APP_LOGIN_API_KEY}/leaderboard/pickem/${pickemId}?offset=0&limit=40`;
  const weeklyUser = `${process.env.REACT_APP_LOGIN_API_KEY}/pickem-leaderboard/${pickemId}/user`;

  try {
    const result = await axios.get(allWeekly, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    const userResult = await axios.get(weeklyUser, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    const data = {
      leaderboard: result.data,
      userPosition: userResult.data,
    };
    return data;
  } catch {
    throw Error("Promise failed");
  }
};
