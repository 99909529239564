import React from "react";
import { Container, StyledInput, Copy } from "./CopyBoard.styles";
import { CopyBoardProps } from "./types";

const CopyBoard: React.FC<CopyBoardProps> = ({ value }) => {
  const handleCopyValue = React.useCallback(() => {
    navigator.clipboard
      .writeText(value)
      .then()
      .catch(() => alert("error"));
  }, [value]);

  return (
    <Container>
      <StyledInput value={value} readOnly />
      <Copy onClick={handleCopyValue}>Copy</Copy>
    </Container>
  );
};

export default CopyBoard;
