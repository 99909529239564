export const MouseIcon = () => {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58325 12.7487C7.58325 7.27187 12.0231 2.83203 17.4999 2.83203C22.9767 2.83203 27.4166 7.27187 27.4166 12.7487V21.2487C27.4166 26.7255 22.9767 31.1654 17.4999 31.1654C12.0231 31.1654 7.58325 26.7255 7.58325 21.2487V12.7487ZM17.4999 5.66536C13.5879 5.66536 10.4166 8.83668 10.4166 12.7487V21.2487C10.4166 25.1607 13.5879 28.332 17.4999 28.332C21.4119 28.332 24.5833 25.1607 24.5833 21.2487V12.7487C24.5833 8.83668 21.4119 5.66536 17.4999 5.66536ZM17.4999 8.4987C18.2823 8.4987 18.9166 9.13296 18.9166 9.91536V14.1654C18.9166 14.9478 18.2823 15.582 17.4999 15.582C16.7175 15.582 16.0833 14.9478 16.0833 14.1654V9.91536C16.0833 9.13296 16.7175 8.4987 17.4999 8.4987Z"
        fill="white"
      />
    </svg>
  );
};
