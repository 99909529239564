import styled from "styled-components";

export const LengWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-left: 20px;
  @media screen and (min-width: 600px) {
    padding-left: 0;
    gap: 10px;
    justify-content: center;
  }
`;