import React from 'react';
// @ts-ignore
import PepsiLogo from '../../assets/images/2023PepsiLogo.png';
// @ts-ignore
import NhlLogo from '../../assets/images/nhl_logo.png';
// @ts-ignore
import NhlPaLogo from '../../assets/images/nhl_logo_pa.png';
import {
    Wrapper,
    Description,
    LogoContainer,
    StylesImage,
    DescriptionAbr,
} from './HomeFooter.styles';
import { useTranslation } from 'react-i18next';

const HomeFooter: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Wrapper>
            <LogoContainer>
                <StylesImage marginRightVariant={'20px'} src={PepsiLogo} style={{width: '46px'}}/>
                <StylesImage marginRightVariant={'20px'} src={NhlLogo} />
                <StylesImage src={NhlPaLogo} />
            </LogoContainer>
            <div style={{ paddingTop: '10px' }}>
                {/* <DescriptionAbr>{t('abrtitle')}</DescriptionAbr> */}
                <Description style={{ display: 'block' }}>
                    <br />
                    {t('abrText')}
                </Description>
            </div>
            <div style={{ paddingTop: '10px' }}>
                {/* <DescriptionAbr>{t('abrtitle')}</DescriptionAbr> */}
                <Description style={{ display: 'block' }}>
                    <br />
                    {t('fottertext')}
                </Description>
            </div>
        </Wrapper>
    );
};

export default HomeFooter;
