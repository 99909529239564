import PlayerCard from '../PlayerCard';
import {GoalNetIcon} from './images/GoalNetIcon';
import {HelmetIcon} from './images/HelmetIcon';
import {PuckIcon} from './images/PuckIcon';
import nhlLogo from '../Play/images/nhl_logo.svg';
import {
    Button,
    Container,
    ScoringSpan,
    ThreeGoaliesContainer,
    UnderGoalieSpan,
} from './PepsiShutout.styles';
import colors from '../../assets/theme/colors';
import React, {useContext, useEffect, useState} from 'react';
import {ISelection, IUserPickemSelections} from '../../api/pickems';
import PepsiIcon from '../../assets/images/2023PepsiLogo.png';
import {CornerIconContainer} from '../PlayerCard/playerCard.styled';
import PepsiLogoTextEN from '../../assets/images/2023LogoEnDesktop.png';
import {useNavigate, useParams} from 'react-router-dom';
import {PlayersContext} from '../../context/PlayersContext';
import {IPlayer} from '../../context/type';
import {useTranslation} from 'react-i18next';
import {ITrs} from '../../utils/types/types';
import {
    ShutoutScoreHeader,
    ChevronLeftContainer,
    ScoreHeaderDate,
    ChevronRightConrainer,
    PointsContainer,
    ScoreContainer,
    ScoreSpan,
    ScoresBottomContainer,
    ScoreBottomFlexContainer
} from './ShutoutScoreReview.styles';
import Loader from "../Loader/Loader";
import {useUserPickemSelections} from "../../api/hooks/useUserPickemSelections";
import paths from '../../AppRouter/paths';
import { FetchSinglePickem } from '../../api/devisionApi';
import ScoresCount from './ScoresCount';

interface IScoreReviewProps {
    currentWeeklyIndex: number;
    setCurrentWeeklyIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ShutoutScoreReview: React.FC<IScoreReviewProps> = ({currentWeeklyIndex, setCurrentWeeklyIndex}) => {
    const [selectedPlayers, setSelectedPlayers] = useState<IPlayer[]>([]); // full players data
    const [userDidSelect, setUserDidSelect] = useState(false);
    const [userSelectionsData, setUserSelectionsData] = useState<IUserPickemSelections | null>(null);
    const [boostedPlayer, setBoostedPlayer] = useState<any>();
    const [currentPickem, setCurrentPickem] = useState<any | null>(null);
    const isDesktop = window.innerWidth < 768;
    const navigate = useNavigate();
    const { langId } = useParams();
    const params = useParams();
    const {isUserSelectionsLoading, fetchData, userSelections} = useUserPickemSelections({userId: params.id, pickemId: params.pickemId});
    const {pickemsList, fetchPickemsList, isLoaded} = useContext(PlayersContext);
    const lng = localStorage.getItem("i18nextLng");
    const {t} = useTranslation();
    const trs: ITrs = t('trs', {returnObjects: true,});
    const [filteredAllPlayersData, setFilteredAllPlayersData] = useState(
        pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY" || pickem.status === "UPCOMING")
    )
    const getCurrentPickem = async (pickemId: string) => {
        const pickem = await FetchSinglePickem(pickemId);
        setCurrentPickem(pickem);
    }

    useEffect(() => {
        // setUserDidSelect(false)
        const userId = params && params.id;
        const pickemId = params && params.pickemId
        if (userId && pickemId && pickemsList.length){
            fetchData({userId, pickemId});
            // getCurrentPickem(pickemId)
            setCurrentPickem(pickemsList.find(((pickem: any) => +pickem.pickem_id === +pickemId)))
        } else if (
            userId && pickemId && !userSelections?.selections
        ) { 
            fetchData({userId, pickemId});
            if (!pickemsList.length && !currentPickem) {
                getCurrentPickem(pickemId)
            }
        }
    }, [currentWeeklyIndex]);

    useEffect(() => {
        const filteredData = pickemsList.length ? pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY") : fetchPickemsList();
        if (filteredData?.length) {setFilteredAllPlayersData(filteredData)}
        const pickemId = params && params.pickemId
        if (pickemId !== filteredData[currentWeeklyIndex]?.pickem_id) {
            filteredData.length && filteredData?.find((pickem: any, index: number) => {
                if (pickem.pickem_id == pickemId) {
                    setCurrentWeeklyIndex(index)
                }
            })
        }
    }, [pickemsList])

    useEffect(() => {
        !!userSelections ? setUserSelectionsData(userSelections) : setUserSelectionsData(null);
        if (userSelections?.selections?.length && !userDidSelect) {
            setUserDidSelect (true)
        } else if (!userSelections?.selections?.length && userDidSelect) {
            setUserDidSelect (false)
    }
    }, [userSelections]);

    const PopulatePlayersArray = () => {
        const playersFromSelection: IPlayer[] = [];
        const targetSelections = userSelectionsData?.selections?.length ? userSelectionsData?.selections : userSelections?.selections;
        if (targetSelections?.length) {

                if (!userDidSelect && targetSelections?.length) {
                    setUserDidSelect(true)
                }
                targetSelections.forEach((selection: ISelection, index: number) => {
                        if (index === 3) {
                            const boostedPlayerFind = currentPickem.questions[3].options.find( // find boosted player data from 4th question
                                (option: IPlayer) => option.option_id === selection.option_id
                            )
                            if (boostedPlayerFind) {
                                setBoostedPlayer(boostedPlayerFind); // need for player scoring points
                                playersFromSelection.map((player) => {
                                  // THIS IS CRUTCH (КОСТИЛЬ) to prevent duplicate pepsi icon on two cards
                                  delete player.boosted;
                                  return player;
                                }).find((player: any, index: number) => { // find that player in selected players
                                    if (player && (player.title_en === boostedPlayerFind?.title_en)) {
                                        player.boosted = true // give them boost
                                        return player
                                    } else {
                                        if (player?.boosted) { player.boosted = false } // each other is to unboost
                                    }
                                })
                            }
                        } else {
                            const player = currentPickem?.questions[
                                index
                                ]?.options.find(
                                (option: IPlayer) =>
                                    option.option_id === selection.option_id
                            );
                            playersFromSelection.push(player);
                        }
                    }
                );
                setSelectedPlayers(playersFromSelection);
            } else {
                // if (!filteredAllPlayersData) {
                //     setTimeout(() => {
                //         PopulatePlayersArray();
                //     }, 2000);
                // }
                if (!targetSelections?.length && userDidSelect) {
                        setUserDidSelect(false)
                        setSelectedPlayers([])
                }
            }
    }

    useEffect(() => {
        const userId = params && params.id;
        if (userId && currentPickem) {
            !isUserSelectionsLoading && isLoaded && PopulatePlayersArray();
        }
    }, [currentPickem, isLoaded, userSelectionsData]);

    const handleGoBackClick = () => {
        navigate(-1);
    };

    // const handleChevronLeftClick = () => {
    //     if (currentWeeklyIndex === 0) {
    //         navigate(paths.weekly);
    //         return
    //     }
    //     if (currentWeeklyIndex > 0) {
    //         const userId = params && params.id;
    //         navigate(`${paths.leaderboardScores}/${userId?.toString()}/${filteredAllPlayersData[currentWeeklyIndex - 1].pickem_id}`);
    //         setCurrentWeeklyIndex(currentWeeklyIndex - 1)
    //     }
    // }


    // const handleChevronRightClick = () => {
    //     if (filteredAllPlayersData.length > 1 && currentWeeklyIndex < filteredAllPlayersData.length - 1) {
    //         const userId = params && params.id;
    //         navigate(`${paths.leaderboardScores}/${userId?.toString()}/${filteredAllPlayersData[currentWeeklyIndex + 1].pickem_id}`);
    //         setCurrentWeeklyIndex(currentWeeklyIndex + 1)
    //     }
    // }

    // @ts-ignore
    const scores = boostedPlayer ? ScoresCount(selectedPlayers, boostedPlayer) : {scoreStep1WithBoost: 0, scoreStep2WithBoost: 0, scoreStep3WithBoost: 0, totalScoreAmount: 0}

    return (
        <>
            <ShutoutScoreHeader>
                {/* <ChevronLeftContainer onClick={handleChevronLeftClick}><ChevronLeft/></ChevronLeftContainer> */}
                <ScoreHeaderDate>{
                    lng === 'en'
                        ? filteredAllPlayersData && currentPickem?.title_en
                        : filteredAllPlayersData && (currentPickem?.title_fr || currentPickem?.title_en)
                }</ScoreHeaderDate>
                {/* <ChevronRightConrainer
                    onClick={handleChevronRightClick}>{currentWeeklyIndex < filteredAllPlayersData?.length - 1 &&
                  <ChevronLeft/>}</ChevronRightConrainer> */}
            </ShutoutScoreHeader>
            {isLoaded && !isUserSelectionsLoading ? <Container>
                    {!!isLoaded && (
                        <PointsContainer>
                            <ScoreContainer
                                style={{backgroundColor: colors.greenScoreBackground}}
                            >
                                <span>{trs.totalPoints}</span>
                                <ScoreSpan>
                                    {scores.totalScoreAmount || 0}
                                </ScoreSpan>
                            </ScoreContainer>
                        </PointsContainer>
                    )}
                    <ThreeGoaliesContainer>
                        {userDidSelect ? (!!selectedPlayers?.length &&
                            selectedPlayers.map((player, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            style={{
                                                maxWidth: '309px',
                                                width: '30vw',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                gap: '8px',
                                                justifyContent: 'center',
                                                minHeight: '102px',
                                                maxHeight: '330px',
                                            }}
                                        >
                                            {player ? (
                                                <PlayerCard
                                                    nameSize={'20px'}
                                                    teamNameSize={'14px'}
                                                    name={player.title_en}
                                                    team={player.description_en}
                                                    playerNum={
                                                        player.data.jersey_number
                                                    }
                                                    playerPhoto={
                                                        player.option_image
                                                    }
                                                    teamLogo={
                                                        player.option_secondary_image
                                                            ? player.option_secondary_image
                                                            : nhlLogo
                                                    }
                                                    opposingTeam={
                                                        player.data.opp_name
                                                    }
                                                    CornerIcon={
                                                        player.boosted ? (
                                                            <CornerIconContainer>
                                                                <img src={PepsiIcon} style={{width: '32px', height: '32px'}}/>
                                                            </CornerIconContainer>
                                                        ) : undefined
                                                    }
                                                    stats={{stat_1: player.data.stat_1, stat_2: player.data.stat_2,stat_3: player.data.stat_3}}
                                                    opp_image={player.option_sponsor_image}
                                                />
                                            ) : null}

                                            <UnderGoalieSpan>
                                                {isDesktop &&
                                                    (index === 0 ? (
                                                        <HelmetIcon/>
                                                    ) : index === 1 ? (
                                                        <PuckIcon/>
                                                    ) : (
                                                        <GoalNetIcon/>
                                                    ))}

                                                {index === 2
                                                    ? trs.goalsAgainst
                                                    : index === 1
                                                        ? trs.mostSaves
                                                        : trs.wins}
                                            </UnderGoalieSpan>
                                            <ScoringSpan>
                                                {index === 2
                                                    ?
                                                    `+${scores.scoreStep3WithBoost || 0}`
                                                    : index === 1
                                                        ?
                                                        `+${scores.scoreStep2WithBoost || 0}`
                                                        :
                                                        `+${scores.scoreStep1WithBoost || 0}`}
                                            </ScoringSpan>
                                        </div>
                                    </div>
                                );
                            })) :
                                <div style={{width: "100%", textAlign: 'center', margin: "12vh 0"}}>
                                    <span>No selection was made!</span>
                                </div> 
                            }
                    </ThreeGoaliesContainer>
                </Container> :
                <Loader/>
                }

            <ScoresBottomContainer>
                <ScoreBottomFlexContainer>
                    {/* <PepsiLogoTextEN/> */}
                    {/* <img src={PepsiLogoTextEN} /> */}
                    <div style={{marginTop: "34px"}}>
                        <Button
                            onClick={handleGoBackClick}
                            className={"active"}>
                            {trs.back}
                        </Button>
                    </div>
                </ScoreBottomFlexContainer>
            </ScoresBottomContainer>
        </>
    );
}

export default ShutoutScoreReview;
