import React from "react";

const Burger:React.FC = () => {
    return (
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.666748 1.91667C0.666748 1.13426 1.30101 0.5 2.08341 0.5H21.9167C22.6992 0.5 23.3334 1.13426 23.3334 1.91667C23.3334 2.69907 22.6992 3.33333 21.9167 3.33333H2.08341C1.30101 3.33333 0.666748 2.69907 0.666748 1.91667ZM0.666748 9C0.666748 8.2176 1.30101 7.58333 2.08341 7.58333H21.9167C22.6992 7.58333 23.3334 8.2176 23.3334 9C23.3334 9.7824 22.6992 10.4167 21.9167 10.4167H2.08341C1.30101 10.4167 0.666748 9.7824 0.666748 9ZM0.666748 16.0833C0.666748 15.3009 1.30101 14.6667 2.08341 14.6667H21.9167C22.6992 14.6667 23.3334 15.3009 23.3334 16.0833C23.3334 16.8657 22.6992 17.5 21.9167 17.5H2.08341C1.30101 17.5 0.666748 16.8657 0.666748 16.0833Z" fill="#262626"/>
        </svg>
    );
}

export default Burger;