import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import { lenguagesData } from './lenguagesData';
import { LengWrapper } from './Lenguages.styled';
import analytics from '../../../../hooks/GoogleAnalytics/analytics';

type IProps = {
  setActiveLang: React.Dispatch<React.SetStateAction<string>>;
};

const Lenguages: React.FC<any> = ({ setActiveLang }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLeng = (leng: string) => {
    const currentPage = pathname.split('/')[2];

    setActiveLang(leng.toUpperCase());
    i18next.changeLanguage(leng);

    analytics.sendEvent({
      event: 'language_selection',
      gtm: { uniqueEventId: 1 },
      language: leng.toUpperCase(),
    });

    navigate(`/${leng}/${currentPage}`);
  };

  return (
    <LengWrapper>
      {lenguagesData.map((item, id) => (
        <div key={id}>
          <img
            style={{ width: '30px', height: '30px' }}
            src={item.logo}
            onClick={() => handleLeng(item?.lenguageName)}
          />
        </div>
      ))}
    </LengWrapper>
  );
};

export default Lenguages;
