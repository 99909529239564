import { useContext, useEffect, useState } from "react";
import { ExitIcon } from "../../assets/icons/ExitIcon";
import pepsiLogo2023 from "../../assets/images/2023PepsiLogo.png";
import Button from "../../Components/Button";
import {
  Container,
  LeagueAdminHeader,
  LeagueButtonsContainer,
  LeagueMember,
  LeagueMemberIndex,
  LeagueMemberName,
  LeagueMemberScore,
  LeagueMembersHeader,
} from "./AdminLeaguePage.styles";
import LeaveLeaguePopup from "../Popups/LeaveLeaguePopup";
import { League, joinLeague } from "../../api/pickems";
import { useLeagueData } from "../../helpers/useLeagueData";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { StateContext } from "../../context/StateContex";
import Loader from "../Loader/Loader";
import {useAdvancedPagination} from "../../hooks/useAdvancedPagination";
import {leaderboardPlayer} from "../../Components/Overall/Overall";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import paths from "../../AppRouter/paths";
import { useTranslation } from "react-i18next";
import { IPickem } from "../../context/type";
import { PlayersContext } from "../../context/PlayersContext";

const UserLeaguePage = ({
  currentViewedLeague,
}: {
  currentViewedLeague: League | undefined;
}) => {
  const { allPlayersData } = useContext(PlayersContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLeavePopupVisible, setIsLeavePopupVisible] = useState(false);
  const [currentLeagueToRender, setCurrentLeagueToRender] = useState(currentViewedLeague);
  const searchParam_league_id = searchParams.get("league_id");
  const { userLeagues } = useContext(StateContext);
  const { langId } = useParams();
  const { t } = useTranslation()

  useEffect(() => {
    const code = searchParams.get('code')
    const isUserInLeague = userLeagues?.result?.leagues?.find((league: League) => league.league_id.toString() === searchParam_league_id)
    if (code && userLeagues.result && !userLeagues.isLoading && !isUserInLeague && searchParam_league_id) {
      joinLeague(code).then((response) => {
        if (!response.success) {
          navigate(`${langId}/${paths.myLeagues}`)
        } else {
          userLeagues.refetchData();
          setSearchParams({league_id: searchParam_league_id})
          fetcherFunction({limit: 10, offset: 0}).then((response) => {setList(response)})
        };
      return response
    })
    } else if (!searchParam_league_id || (!isUserInLeague && !userLeagues.isLoading && !code)) {
    // navigate(`${langId}/${paths.myLeagues}`)
    }
  }, [userLeagues])

  const { leagueLeaderboard, fetcherFunction } = useLeagueData(
      searchParam_league_id || ""
  );

  const { list, isLoading, onLoadMore, setList } = useAdvancedPagination<
      any,
      leaderboardPlayer
  >({
    fetcher: ({limit, offset}) => fetcherFunction({limit, offset}),
    deps: [],
    limit: 10,
    execute: !!searchParam_league_id,
    mapResult: (data: any) => data,
  });

  const { intersectionObserverRef } = useIntersectionObserver({
    onReachPageEnd: onLoadMore,
    deps: [],
  });

  useEffect(() => {
    if (!currentViewedLeague) {
      userLeagues.result?.leagues.find((league: League) => {
        if (league.league_id.toString() === searchParam_league_id) {
          setCurrentLeagueToRender(league);
          return league;
        }
      });
    }
  }, [currentViewedLeague, userLeagues]);

  const handleLeagueMemberClick = (userId: number) => () => {
    const lastPickem = allPlayersData.find((pickem: IPickem) => pickem.status === "IN_PLAY" || pickem.status === "COMPLETE")
    navigate(`/${langId}${paths.leaderboardScores}/${userId.toString()}/${lastPickem.pickem_id}`);
  };

  return (
    <>
      <LeaveLeaguePopup
        currentViewedLeague={currentLeagueToRender}
        isVisible={isLeavePopupVisible}
        setIsVisible={setIsLeavePopupVisible}
      />
      <Container>
        <LeagueAdminHeader>
          <h1>{currentLeagueToRender?.title}</h1>
        </LeagueAdminHeader>

        <div>
          <LeagueMembersHeader>
            <LeagueMemberIndex>{t('rank')}</LeagueMemberIndex>
            <LeagueMemberName>{t('player')}</LeagueMemberName>
            <LeagueMemberScore>{t('total')}</LeagueMemberScore>
          </LeagueMembersHeader>
          {!!list.length && list.slice(0, 10).map((member, index) => {
            return (
              <LeagueMember key={index}
                onClick={handleLeagueMemberClick(member.user_id)}
              >
                <LeagueMemberIndex>{member.rank}</LeagueMemberIndex>
                <LeagueMemberName>
                  <img src={pepsiLogo2023} style={{width: '34px', height: '34px', marginRight: '14px'}}/>
                  {member.username}
                </LeagueMemberName>
                <LeagueMemberScore>{member.points}</LeagueMemberScore>
              </LeagueMember>
            );
          })}
        </div>

        <LeagueButtonsContainer style={{ width: "330px", margin: "auto" }}>
          <Button
            fullWidth
            text={"Leave league"}
            endIcon={<ExitIcon />}
            handleClick={() => setIsLeavePopupVisible(true)}
          />
        </LeagueButtonsContainer>
        {list
            .slice(10, list.length)
            .map((member, index) => {
          return (
              <LeagueMember
                key={index}
                onClick={handleLeagueMemberClick(member.user_id)}
              >
                <LeagueMemberIndex>{index + 11}</LeagueMemberIndex>
                <LeagueMemberName>
                  <img src={pepsiLogo2023} style={{width: '34px', height: '34px', marginRight: '14px'}}/>
                  {member.username}
                </LeagueMemberName>
                <LeagueMemberScore>{member.points}</LeagueMemberScore>
              </LeagueMember>
          );
        })}
        <div>
        <div style={{ color: "rgba(0,0,0,0.0)", textAlign: "center" }}>ends</div>
        <div ref={intersectionObserverRef} />
      </div>
        {isLoading && <Loader />}
      </Container>
    </>
  );
};

export default UserLeaguePage;
