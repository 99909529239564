import React, {Dispatch, SetStateAction, ReactNode, useContext} from 'react';
import {
    ShareBlueGoalieContainer, ShareCopyBoardTitle, ShareIcons,
    SharePopupBackdrop,
    SharePopupBodyContainer,
    SharePopupContainer,
    SharePopupCrossContainer,
    ShareTitle,
} from "./SharePopUp.styles";
import CrossIcon from "../../../assets/icons/CrossIcon";
import {useTranslation} from "react-i18next";
import twitter from './images/twitterX.png';
import Facebook from './images/Facebook.svg';
// @ts-ignore
import {FacebookShareButton, TwitterShareButton} from "react-share";
import CopyBoard from "./CopyBoard";
import {useWindowSize} from "../../../helpers/useWidth";
import CongratImg from './images/2023ShareLeaguePopup.png';
import { PlayersContext } from '../../../context/PlayersContext';
import { localStorageGet } from '../../../utils/localStorage';
type Props = {
    isShareVisible: boolean;
    setIsShareVisible: Dispatch<SetStateAction<boolean>>;
    child: ReactNode;
}
const SharePopUp:React.FC<Props> = ({isShareVisible,setIsShareVisible, child}) => {
    const width = useWindowSize().width
    const {leaderboardUser, fetchOverallData} = useContext(PlayersContext)
    const isDesktop = width > 970;
    const { t } = useTranslation();
    const closeCross = () => {
        setIsShareVisible(false);
    };
    return (
        <SharePopupBackdrop style={{ display: isShareVisible ? 'block' : 'none' }}>
            <SharePopupContainer>
                    <SharePopupCrossContainer style={{position: 'absolute', top: isDesktop ? '-20%' : '-7vh', right: isDesktop ? '-10%' : '4%'}}
                        onClick={() => {
                            closeCross();
                        }}
                    >
                        <CrossIcon />
                    </SharePopupCrossContainer>
                <ShareBlueGoalieContainer style={{position: "relative"}}>
                     {
                        width < 1 ? 
                        child
                         : (<img src={CongratImg} alt="golie" style={{width: "100%"}}/>)
                    }
                </ShareBlueGoalieContainer>
                <SharePopupBodyContainer>
                    <ShareTitle>
                        {/* {
                            width > 768 ? */}
                                 {t('shareWho')}
                                {/* : 'Please use the "share" button in your browser app to share your choices with your friends !'
                        } */}
                    </ShareTitle>
                    {/* {width > 768 && */}
                        <>
                            <ShareIcons>
                                <TwitterShareButton
                                    url={localStorageGet('sharePath') || 'https://goaliechallenge.nhl.com/en/home'}
                                    title={`Check out my NHL Pepsi Zero Sugar Goalie Challenge picks for this week...`}
                                >
                                    <img src={twitter} style={{width: '32px', height: '32px'}}/>
                                </TwitterShareButton>

                                <FacebookShareButton
                                    url={localStorageGet('sharePath') || 'https://goaliechallenge.nhl.com/en/home'}
                                    quote={`Check out my NHL Pepsi Zero Sugar Goalie Challenge picks for this week...`}
                                >
                                    <img src={Facebook} />
                                </FacebookShareButton>
                            </ShareIcons>
                            <div>
                                <ShareCopyBoardTitle>or copy link</ShareCopyBoardTitle>
                                <CopyBoard value={`Check out my NHL Pepsi Zero Sugar Goalie Challenge picks for this week ${localStorageGet('sharePath') || 'https://goaliechallenge.nhl.com/en/home'}`} />
                            </div>
                        </>
                        
                        {/* } */}
                </SharePopupBodyContainer>
            </SharePopupContainer>
        </SharePopupBackdrop>
    );
};

export default SharePopUp;