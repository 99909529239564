import styled from 'styled-components';
import './styles.css';

export const Btn = styled.button`
    border: none;
    background: transparent;
    margin-left: auto;
    position: fixed;
    right: 3px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
    color: black;
    font-size: 44px;
    @media (min-width: 499px) {
        color: black;
    }
`;
export const Img = styled.img`
    display: block;
    width: 30px;
    height: 30px;
`;
export const RulWrapper = styled.div`
    position: relative;
`;
