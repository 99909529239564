import styled from "styled-components";
import HockeyFieldBnw from "./images/HockeyFieldBnw.svg";
import colors from "../../assets/theme/colors";

export const Container = styled('div')({
    color: 'white',
    textAlign: 'center',
    // '@media screen and (max-width: 768px)': {
    //     backgroundColor: colors.primaryDarkGrey,
    //     backgroundImage: `url(${HockeyFieldBnw})`,
    //     backgroundSize: 'cover',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundPosition: 'top',
    // },
    '@media screen and (min-width: 768px)': {
        maxWidth: '900px',
        margin: 'auto',
        backgroundPosition: '50% -100px',
    },
    WebkitTouchCallout: "none",
});

export const StyledButton = styled('button')({
    borderRadius: '8px',
    maxWidth: '200px',
    height: '50px',
    width: '100%',
    color: "white",
    margin: 'auto',
    padding: '15px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    backgroundColor: '#0025FF',
    border: 'none',
    cursor: 'pointer',
    marginTop: '18px',
    '& div': {
        display: 'flex',
        top: 'unset',
        marginTop: '0'
    },
});

export const StyledShareButton = styled(StyledButton)({
    backgroundColor: '#262626',
    marginTop: '15px',
})

export const ShutoutTopContainer = styled('div')({});

export const ThreeGoaliesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'flex-start',
    gap: '6px',
    justifyContent: 'space-around',
    margin: '4rem 0',
    '@media screen and (min-width: 768px)': {
        padding: 0,
        margin: 0,
    },
});

export const DragDropField = styled('div')({
    background: 'transparent',
    textAlign: 'center',
    // marginTop: '26px',
    '@media screen and (min-width: 768px)': {
        marginTop: '0px',
        paddingTop: '60px',
    },
});

export const CanContainer = styled('div')({
    width: 'min-content',
    margin: '8vh auto 0px -14px',
    // paddingBottom: "60px",
    '@media screen and (min-width: 768px)': {
        width: 'auto',
    },
});

export const CanDescriptionContainer = styled('div')({
    background: 'black',
    width: '100vw',
    minHeight: '200px',
    height: '15vh',
    paddingTop: '100px',
    marginTop: '-60px',
    zIndex: 2,
    '@media screen and (min-width: 768px)': {
        width: '100%',
    },
});

export const UnderGoalieSpan = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textTransform: 'uppercase',
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: 700,
    // lineHeight: '14px',
    '& svg': {
        width: '60%',
    },
    '@media screen and (min-width: 768px)': {
        fontSize: '24px',
    },
});
export const ScoringSpan = styled('div')({
    textTransform: 'uppercase',
    marginTop: '20px',
    fontSize: '15px',
    color:"#0CD664",
    fontWeight: 700,
    lineHeight: '14px',
    '@media screen and (min-width: 768px)': {
        fontSize: '30px',
    },
});


export const PepsiCanContainer = styled('div')({
    zIndex: 50,
    minWidth: '80px',
    minHeight: '100px',
    '& img': {
        maxWidth: '100%',
        // maxHeight: "105px",
    },
});

export const Button = styled('button')({
    // to /scores & video ad
    background: "white",
    border: 'none',
    width: '50vw',
    padding: '26px 12px',
    fontWeight: 400,
    fontSize: '16px',
    color: "black !important",
    textTransform: 'none',
});

export const EmptyPepsiCanContainer = styled("div") ({
    height: "175px",
    zIndex: 3,
    position: "relative",
    WebkitTouchCallout: "none",
    WebkitUserSelect: 'none',
    "& img": {
        // WebkitTouchCallout: "none",
        WebkitUserSelect: 'none'
    }
})