import {useLazyQuery} from "../../hooks/useLazyQuery";
import {getUserPickemSelections} from "../pickems";

interface ISearchParams  {
    userId?: string | number;
    pickemId?: string | number;
}
export const useUserPickemSelections = ({userId, pickemId}: ISearchParams) => {
    const { isLoading, fetchData, result } = useLazyQuery({
        fetcher: (params: ISearchParams) => getUserPickemSelections(userId ? userId : String(params.userId), pickemId ? pickemId : String(params.pickemId)),
    });
    return { isUserSelectionsLoading: isLoading, fetchData, userSelections: result };
};
