import React from 'react';
import { Header, HeaderValue, Table, Wrapper } from './MyLeaguesTable.styles';
import { MyLeaguesTableProps } from './types';
// import MyLeaguesItem from './MyLeaguesItem/MyLeaguesItem';
// import AdsBanner from '../AdsBanner';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../helpers/useWidth';

const MyLeaguesTable: React.FC<MyLeaguesTableProps> = ({ children }) => {
    const width = useWindowSize().width
const isDesktop = width > 768;
    const { t } = useTranslation();
    return (
        <Wrapper>
            <Table>
                <Header>
                    <tr>
                        <HeaderValue style={{ padding: "0px 15px", width: "20px" }}>
                            {t('rank')}
                        </HeaderValue>
                        <HeaderValue style={{ textAlign: 'left' }}>
                            {t('leag')}
                        </HeaderValue>
                        <HeaderValue></HeaderValue>
                    </tr>
                </Header>
                <tbody>{children}</tbody>
            </Table>
        </Wrapper>
    );
};

export default MyLeaguesTable;
