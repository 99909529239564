const Gznhl = () => {
    return (
        <svg width="62" height="70" viewBox="0 0 62 70" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: '24px', maxHeight: '30px'}}>
        <g clipPath="url(#clip0_1735_65967)">
        <g clipPath="url(#clip1_1735_65967)">
        <path d="M56.6599 25.3906C56.6599 22.2947 57.3968 19.5503 58.3697 17.3246L60.299 12.8984L55.7615 11.5471C53.7867 10.9572 52.346 9.4469 51.4848 7.06643L49.481 1.53153L45.0222 5.2633C43.5525 6.48909 41.8758 7.29235 40.0956 7.29235C37.7068 7.29235 35.347 6.08747 33.869 4.61484L30.9958 1.74489L28.1267 4.61484C26.6487 6.09165 24.2889 7.29235 21.9001 7.29235C20.1199 7.29235 18.4432 6.48909 16.9735 5.2633L12.5146 1.52734L10.515 7.06225C9.64973 9.4469 8.21314 10.953 6.23834 11.5429L1.70086 12.8942L3.63011 17.3204C4.59888 19.5503 5.33995 22.2906 5.33995 25.3864C5.33995 32.942 1.67188 34.4481 1.67188 42.3802C1.67188 53.0024 9.49655 60.6333 18.1658 62.8882C22.4507 63.9843 24.4421 64.0889 27.6382 64.9926C28.0522 65.4318 31.004 68.6532 31.004 68.6532C31.004 68.6532 33.9559 65.4318 34.3699 64.9926C37.5619 64.0889 39.5574 63.9801 43.8423 62.8882C52.5074 60.6374 60.3321 53.0065 60.3321 42.3802C60.3321 34.4439 56.664 32.942 56.664 25.3864L56.6599 25.3906ZM43.3248 60.8466C38.7376 62.018 35.5705 62.3151 33.2397 63.1643C32.9416 63.4488 30.9999 65.5741 30.9999 65.5741C30.9999 65.5741 29.0624 63.4488 28.7601 63.1643C26.4293 62.3151 23.2622 62.018 18.675 60.8466C9.02044 58.3783 3.72947 50.1868 3.72947 42.3886C3.72947 35.3057 7.39341 33.1177 7.39341 25.378C7.39341 19.3453 4.54506 14.2539 4.54506 14.2539C4.54506 14.2539 11.5459 13.6389 13.4461 5.0332C13.4461 5.0332 17.6483 9.40506 21.9001 9.40506C27.3898 9.40506 30.9999 4.69433 30.9999 4.69433C30.9999 4.69433 34.61 9.40506 40.0997 9.40506C44.3557 9.40506 48.5537 5.0332 48.5537 5.0332C50.454 13.6347 57.4548 14.2539 57.4548 14.2539C57.4548 14.2539 54.6064 19.3495 54.6064 25.378C54.6064 33.1177 58.2703 35.3057 58.2703 42.3886C58.2703 50.191 52.9794 58.3783 43.3248 60.8466Z" fill="#D0D3D4"/>
        <path d="M52.5445 25.3749C52.5445 19.8526 54.6104 15.602 54.6104 15.602C54.6104 15.602 49.8452 14.6314 47.6261 8.53592C47.6261 8.53592 44.4631 11.5188 40.0995 11.5188C34.6305 11.5188 30.9997 7.64062 30.9997 7.64062C30.9997 7.64062 27.3689 11.5188 21.8999 11.5188C17.5363 11.5188 14.3733 8.53592 14.3733 8.53592C12.1543 14.6314 7.38907 15.602 7.38907 15.602C7.38907 15.602 9.45495 19.8567 9.45495 25.3749C9.45495 33.5664 5.79102 35.9385 5.79102 42.3854C5.79102 51.4011 12.7463 57.141 19.1923 58.7977C24.3011 60.1072 27.638 60.2536 29.8778 61.3497C29.8778 61.3497 30.6933 62.1027 30.9997 62.45C31.3102 62.1027 32.1258 61.3497 32.1258 61.3497C34.3656 60.2536 37.7066 60.1072 42.8112 58.7977C49.2573 57.141 56.2125 51.4011 56.2125 42.3854C56.2125 35.9343 52.5486 33.5664 52.5486 25.3749H52.5445ZM11.7941 25.3749C11.7941 21.8732 11.0406 18.8485 10.3947 16.8947C11.8976 16.058 13.7896 14.5854 15.2924 12.046C16.9112 12.958 19.2006 13.8826 21.8999 13.8826C25.982 13.8826 29.174 12.0794 30.9997 10.7281C32.8255 12.0836 36.0174 13.8826 40.0995 13.8826C40.7495 13.8826 41.3788 13.8282 41.9791 13.7319L8.55242 39.1013C9.51705 35.2817 11.7941 32.1231 11.7941 25.3749ZM16.3481 55.1914C12.0715 52.9699 8.32472 48.7068 8.13842 42.8498L47.3777 13.0751C48.7481 14.9954 50.3171 16.1835 51.6047 16.8947C50.9588 18.8527 50.2054 21.8732 50.2054 25.3749C50.2054 26.8308 50.3089 28.1152 50.4869 29.2824L16.3481 55.1914ZM42.2358 56.5093C38.191 57.551 33.2436 58.0823 31.7532 58.9441C31.4179 59.1909 31.0039 59.6344 31.0039 59.6344C31.0039 59.6344 30.5898 59.1909 30.2545 58.9441C28.76 58.0823 23.8126 57.5468 19.7678 56.5093C19.6146 56.4716 19.4614 56.4256 19.3124 56.3838L51.1327 32.236C52.2174 36.0682 53.8734 38.5783 53.8734 42.3938C53.8734 50.0456 47.8414 55.0743 42.2358 56.5134V56.5093Z" fill="#D0D3D4"/>
        <path d="M22.7121 42.8735L19.0482 37.2006L14.9785 40.2923C14.9785 40.2923 16.4068 40.3299 16.4068 41.8402V52.5209L19.28 50.3413V42.7857L22.5548 47.852L25.5936 45.5469V32.2305L22.7204 34.4101V42.8652L22.7121 42.8735Z" fill="#D0D3D4"/>
        <path d="M34.3575 30.503L31.12 32.9588V28.0389L26.744 31.3606C26.744 31.3606 28.1723 31.4025 28.1723 32.9128V43.5935L31.1242 41.3553V36.4856L34.3617 34.0298V38.8995L37.1728 36.7659V23.4495L34.3617 25.5831V30.503H34.3575Z" fill="#D0D3D4"/>
        <path d="M42.5674 29.2628V19.3477L38.1914 22.6694C38.1914 22.6694 39.6197 22.7071 39.6197 24.2174V34.9023L47.7632 28.7189C47.5893 26.5895 47.7632 25.3218 47.7632 25.3218L42.5674 29.2628Z" fill="#D0D3D4"/>
        <path d="M48.5547 5.03028C48.5547 5.03028 44.3567 9.40214 40.1007 9.40214C34.611 9.40214 31.0009 4.69141 31.0009 4.69141C31.0009 4.69141 27.3908 9.40214 21.9011 9.40214C17.6451 9.40214 13.4471 5.03028 13.4471 5.03028C11.551 13.6318 4.54606 14.2509 4.54606 14.2509C4.54606 14.2509 7.3944 19.3466 7.3944 25.3751C7.3944 33.1148 3.73047 35.3028 3.73047 42.3856C3.73047 50.1881 9.0173 58.3754 18.676 60.8437C23.2632 62.0151 26.4303 62.3121 28.7611 63.1614C29.0592 63.4459 31.0009 65.5711 31.0009 65.5711C31.0009 65.5711 32.9426 63.4459 33.2407 63.1614C35.5715 62.3121 38.7386 62.0151 43.3258 60.8437C52.9804 58.3754 58.2713 50.1839 58.2713 42.3856C58.2713 35.3028 54.6074 33.1148 54.6074 25.3751C54.6074 19.3424 57.4558 14.2509 57.4558 14.2509C57.4558 14.2509 50.4508 13.6359 48.5547 5.03028ZM52.5457 25.3751C52.5457 33.5666 56.2096 35.9387 56.2096 42.3856C56.2096 51.4013 49.2543 57.1412 42.8083 58.7979C37.7036 60.1074 34.3626 60.2538 32.1229 61.3499C32.1229 61.3499 31.3073 62.1029 30.9968 62.4502C30.6863 62.1029 29.8748 61.3499 29.8748 61.3499C27.6309 60.2538 24.294 60.1074 19.1894 58.7979C12.7433 57.1412 5.78807 51.4013 5.78807 42.3856C5.78807 35.9345 9.452 33.5666 9.452 25.3751C9.452 19.8528 7.38612 15.6022 7.38612 15.6022C7.38612 15.6022 12.1513 14.6316 14.3704 8.53613C14.3704 8.53613 17.5334 11.519 21.897 11.519C27.3659 11.519 30.9968 7.64084 30.9968 7.64084C30.9968 7.64084 34.6276 11.519 40.0966 11.519C44.4602 11.519 47.6232 8.53613 47.6232 8.53613C49.8422 14.6316 54.6074 15.6022 54.6074 15.6022C54.6074 15.6022 52.5415 19.857 52.5415 25.3751H52.5457Z" fill="black"/>
        <path d="M52.3337 6.74815L49.8952 0L44.451 4.55594C43.0103 5.765 41.5447 6.37581 40.104 6.37581C38.1747 6.37581 35.9805 5.42613 34.5149 3.96187L31 0.456012L27.4934 3.96187C26.0279 5.43031 23.8336 6.37581 21.9044 6.37581C20.4636 6.37581 18.9981 5.76082 17.5573 4.55594L12.1049 0L9.66229 6.74815C8.90466 8.84413 7.66265 10.1661 5.9818 10.6682L0.458984 12.3165L2.80225 17.6966C3.88694 20.1775 4.43342 22.7672 4.43342 25.3945C4.43342 32.6614 0.765347 34.3181 0.765347 42.3882C0.765347 54.0186 9.66643 61.6286 17.9341 63.779C22.1155 64.8081 23.3741 64.8751 27.1498 65.8164C27.4562 66.1511 31 70.0042 31 70.0042C31 70.0042 34.5398 66.1511 34.8503 65.8164C38.626 64.8751 39.8887 64.8081 44.066 63.779C52.3337 61.6286 61.2389 54.0186 61.2389 42.3882C61.2389 34.3139 57.5667 32.6614 57.5667 25.3945C57.5667 22.7672 58.1132 20.1775 59.1979 17.6966L61.5411 12.3165L56.0183 10.6724C54.3292 10.1703 53.0954 8.84831 52.3337 6.75233V6.74815ZM58.3698 17.3243C57.3969 19.5541 56.66 22.2944 56.66 25.3903C56.66 32.9459 60.3281 34.4519 60.3281 42.384C60.3281 53.0062 52.4993 60.6371 43.8383 62.8921C39.5534 63.9882 37.5579 64.0928 34.3659 64.9964C33.9519 65.4357 31 68.6571 31 68.6571C31 68.6571 28.0482 65.4357 27.6342 64.9964C24.4422 64.0928 22.4467 63.984 18.1618 62.8921C9.49669 60.6413 1.66788 53.0104 1.66788 42.3882C1.66788 34.4519 5.33595 32.95 5.33595 25.3945C5.33595 22.2986 4.59902 19.5541 3.62611 17.3285L1.701 12.898L6.23848 11.5467C8.21742 10.9568 9.65401 9.44657 10.5151 7.0661L12.5148 1.52701L16.9736 5.25878C18.4392 6.48458 20.1159 7.28783 21.9002 7.28783C24.289 7.28783 26.6489 6.08295 28.1269 4.61033L30.9959 1.74038L33.8691 4.61033C35.3471 6.08714 37.7069 7.28783 40.0957 7.28783C41.8759 7.28783 43.5526 6.48458 45.0224 5.25878L49.4812 1.52701L51.485 7.06192C52.3461 9.44657 53.7868 10.9527 55.7616 11.5426L60.2991 12.8939L58.3698 17.3201V17.3243Z" fill="black"/>
        <path d="M47.378 13.0742L8.13867 42.8489C8.32497 48.7059 12.0676 52.969 16.3484 55.1905L50.4871 29.2815C50.3091 28.1143 50.2056 26.8299 50.2056 25.374C50.2056 21.8723 50.955 18.8476 51.6049 16.8938C50.3174 16.1785 48.7442 14.9945 47.378 13.0742ZM25.5848 45.5515L22.546 47.8567L19.2712 42.7903V50.3459L16.398 52.5256V41.8448C16.398 40.3346 14.9697 40.2969 14.9697 40.2969L19.0394 37.2052L22.7033 42.8782V34.4231L25.5765 32.2435V45.5599L25.5848 45.5515ZM37.1686 36.7618L34.3575 38.8954V34.0257L31.12 36.4815V41.3512L28.1682 43.5894V32.9087C28.1682 31.3984 26.7399 31.3566 26.7399 31.3566L31.1159 28.0348V32.9547L34.3534 30.4989V25.579L37.1645 23.4454V36.7618H37.1686ZM47.7671 28.7167L39.6237 34.9001V24.2151C39.6237 22.7049 38.1954 22.6672 38.1954 22.6672L42.5714 19.3454V29.2606L47.7671 25.3196C47.7671 25.3196 47.5932 26.5831 47.7671 28.7167Z" fill="black"/>
        <path d="M40.0998 13.8812C36.0177 13.8812 32.8258 12.0781 31 10.7268C29.1743 12.0823 25.9864 13.8812 21.9002 13.8812C19.2051 13.8812 16.9115 12.9608 15.2927 12.0446C13.7857 14.5841 11.8979 16.0567 10.3951 16.8934C11.0409 18.8514 11.7944 21.8719 11.7944 25.3736C11.7944 32.1176 9.51322 35.2804 8.55273 39.1L41.9794 13.7306C41.3791 13.8269 40.7498 13.8812 40.0998 13.8812Z" fill="black"/>
        <path d="M19.3086 56.3824C19.4618 56.4242 19.6108 56.4702 19.764 56.5079C23.8088 57.5496 28.7562 58.0809 30.2507 58.9428C30.5861 59.1896 31.0001 59.633 31.0001 59.633C31.0001 59.633 31.4141 59.1896 31.7494 58.9428C33.244 58.0809 38.1913 57.5454 42.232 56.5079C47.8376 55.0687 53.8696 50.0401 53.8696 42.3882C53.8696 38.577 52.2136 36.0668 51.1289 32.2305L19.3086 56.3782V56.3824Z" fill="black"/>
        <path d="M59.8058 60.205C59.8058 58.8871 58.7501 57.8203 57.4419 57.8203C56.1336 57.8203 55.082 58.8871 55.082 60.205C55.082 61.5228 56.1377 62.5896 57.4419 62.5896C58.746 62.5896 59.8058 61.5228 59.8058 60.205ZM55.5913 60.205C55.5913 59.1716 56.4193 58.3307 57.446 58.3307C58.4727 58.3307 59.3007 59.1716 59.3007 60.205C59.3007 61.2383 58.4686 62.0792 57.446 62.0792C56.4234 62.0792 55.5913 61.2425 55.5913 60.205Z" fill="white"/>
        <path d="M58.4161 61.5712L57.882 60.4165C58.1926 60.3035 58.4161 60.0065 58.4161 59.6551C58.4161 59.2074 58.0601 58.8477 57.6171 58.8477H56.4785V61.5712H56.9878V60.4625H57.348L57.8613 61.5712H58.4203H58.4161ZM57.6171 59.9521H56.9836V59.3581H57.6171C57.7785 59.3581 57.911 59.4919 57.911 59.6551C57.911 59.8182 57.7785 59.9521 57.6171 59.9521Z" fill="white"/>
        </g>
        </g>
        <defs>
        <clipPath id="clip0_1735_65967">
        <rect width="62" height="70" fill="white"/>
        </clipPath>
        <clipPath id="clip1_1735_65967">
        <rect width="61.0821" height="70" fill="white" transform="translate(0.458984)"/>
        </clipPath>
        </defs>
        </svg>
)}

export default Gznhl