import React, { useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import paths from "./paths";
import HomeScreen from "../pages/HomeScreen";
import OnboardingScreen from "../pages/OnboardingScreen";
import Play from "../pages/Play/Play";
import { PlayersContext } from "../context/PlayersContext";
import PicksHomeNavigation from "../Components/PicksHomeNavigation";
import LeaderBoardNavigation from "../Components/Layout/Components/LeaderBoarbNavigation/LeaderBoardNavigation";
import TimeoutAlert from "../pages/Alerts/TimeoutAlert";
import Overall from "../Components/Overall/Overall";
import AdminLeaguePage from "../pages/LeaguePages/AdminLeaguePage";
import UserLeaguePage from "../pages/LeaguePages/UserLeaguePage";
import WeeklyTabPage from "../Components/Weekly/WeeklyTabPage";
import { Wrapper } from "../pages/MyLeaguesScreen/MyLeaguesScreen.styles";
import MyLeagues from "../Components/MyLeagues";
import RulesPage from "../pages/Rules/Rules";
import { useTranslation } from "react-i18next";
import SplashScreen from "../pages/SpalshScreen/SplashScreen";
import Layout from "../Components/Layout";
import ShutoutScoreReview from "../pages/PepsiShutout/ShutoutScoreReview";
import { League } from "../api/pickems";
import { ITrs } from "../utils/types/types";
import Loader from "../pages/Loader/Loader";

const pepsiPointsData = ["Shutout = 20pts"];

const AppRouter: React.FC = () => {
  const [currentViewedLeague, setCurrentViewedLeague] = useState<League>();
  const [currentWeeklyIndex, setCurrentWeeklyIndex] = useState<number>(0)

  const { allPlayersData, allQuestions, isLoaded } = useContext(PlayersContext);
  const pickemData = allPlayersData && allPlayersData[0];
  const questionsData = pickemData?.questions;
  const lng = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const trs: ITrs = t("trs", {
    returnObjects: true,
  });
  // @ts-ignore
    return (
      <Routes>
        <Route path=':langId'>
          <Route element={<SplashScreen />} path={`/:langId/${paths.splashscreen}`} />
          <Route element={<Layout />}>
            <Route element={<RulesPage />} path={`/:langId/${paths.rules}`} />
            <Route element={<HomeScreen />} path={`/:langId/${paths.home}`} />
            <Route element={<OnboardingScreen />} path={`/:langId/${paths.guide}`} />
            <Route
              element={
                isLoaded ? <PicksHomeNavigation currentPage={"leaderboard"}>
                  <LeaderBoardNavigation
                    currentWeeklyIndex={currentWeeklyIndex}
                    setCurrentWeeklyIndex={setCurrentWeeklyIndex}
                    periodDate={
                      lng === "en" ? pickemData?.title_en : pickemData?.title_fr
                    }
                  />
                </PicksHomeNavigation> : null
              }
            >
              <Route
                index
                element={
                  <Wrapper>
                    <MyLeagues setCurrentViewedLeague={setCurrentViewedLeague} />
                  </Wrapper>
                }
                path={`/:langId/${paths.myLeagues}`}
              />
              <Route element={<Overall />} path={`/:langId/${paths.overall}`} />
              <Route
                element={isLoaded ? <WeeklyTabPage currentPickemIndex={currentWeeklyIndex}/> : <Loader />}
                path={`/:langId/${paths.weekly}`}
              />
            </Route>

            <Route
              element={
                <Play
                  isPepsiShutout={false}
                  title={trs.wins}
                  playerData={questionsData && questionsData[0]?.options}
                  bannerInfo={
                    lng === "en"
                      ? questionsData && questionsData[0]?.title_en
                      : questionsData && questionsData[0]?.title_fr
                  }
                  pointsInfo={
                    lng === "en"
                      ? questionsData && questionsData[0]?.description_en
                      : questionsData && questionsData[0]?.description_fr
                  }
                  isLoaded={isLoaded}
                  bigData={pickemData && pickemData}
                />
              }
              path={`/:langId/${paths.wins}`}
            />

            <Route
              element={
                <Play
                  isPepsiShutout={false}
                  title={trs.mostSaves}
                  playerData={questionsData && questionsData[1]?.options}
                  bannerInfo={
                    lng === "en"
                      ? questionsData && questionsData[1]?.title_en
                      : questionsData && questionsData[1]?.title_fr
                  }
                  pointsInfo={
                    lng === "en"
                      ? questionsData && questionsData[1]?.description_en
                      : questionsData && questionsData[1]?.description_fr
                  }
                  isLoaded={isLoaded}
                  bigData={pickemData && pickemData}
                />
              }
              path={`/:langId/${paths.save}`}
            />

            <Route
              element={
                <Play
                  isPepsiShutout={false}
                  title={trs.goalsAgainst}
                  playerData={questionsData && questionsData[2]?.options}
                  bannerInfo={
                    lng === "en"
                      ? questionsData && questionsData[2]?.title_en
                      : questionsData && questionsData[2]?.title_fr
                  }
                  pointsInfo={
                    lng === "en"
                      ? questionsData && questionsData[2]?.description_en
                      : questionsData && questionsData[2]?.description_fr
                  }
                  isLoaded={isLoaded}
                  bigData={pickemData && pickemData}
                />
              }
              path={`/:langId/${paths.goals}`}
            />

            <Route
              element={
                <Play
                  isPepsiShutout={true}
                  bannerInfo={
                    lng === "en"
                      ? questionsData && questionsData[3]?.title_en
                      : questionsData && questionsData[3]?.title_fr
                  }
                  title={trs.pepsiZero}
                  pointsInfo={pepsiPointsData}
                  isLoaded={isLoaded}
                />
              }
              path={`/:langId/${paths.shutout}`}
            />

            <Route element={<TimeoutAlert />} path={`/:langId/${paths.timeout}`} />
            <Route
              element={<AdminLeaguePage currentLeague={currentViewedLeague} />}
              path={`/:langId/${paths.admin}`}
            />
            <Route
              element={<UserLeaguePage currentViewedLeague={currentViewedLeague} />}
              path={`/:langId/${paths.userLeague}`}
            />
            <Route
              element={<ShutoutScoreReview currentWeeklyIndex={currentWeeklyIndex}  setCurrentWeeklyIndex={setCurrentWeeklyIndex}/>}
              path={`/:langId/${paths.leaderboardScores}`}
            />
              <Route
                  element={<ShutoutScoreReview currentWeeklyIndex={currentWeeklyIndex}  setCurrentWeeklyIndex={setCurrentWeeklyIndex}/>}
                  path={`/:langId/${paths.leaderboardScores}/:id/:pickemId`}
              />
          </Route>
        </Route>
        <Route path={"*"} element={<Navigate to={`/en${paths.home}`} />} />
      </Routes>
  );
};

export default AppRouter;
