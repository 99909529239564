import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import paths from '../../../../AppRouter/paths';
import {
  ContainerNavLink,
  Dropdown,
  DropdownButton,
  DropdownImageContainer,
  DropdownListContainer,
  DropdownListItemRight,
  StyledDropdownImage,
  StyledDropdownListButton,
  Wrapper,
  Overlay
} from './LeaderBoard.styles';
import './style.css';

import ml from '../../../../assets/images/myleageu.svg';
import mly from '../../../../assets/images/mly.svg';

import over from '../../../../assets/images/Overall.svg';
import overActive from '../../../../assets/images/overActive.svg';
import week from '../../../../assets/images/query-stats.svg';
import weeklyActive from '../../../../assets/images/weeklyactive.svg';
import { useOverall } from './useOverall';
import { PlayersContext } from '../../../../context/PlayersContext';
import { CaretRight } from '../../../../assets/icons/CaretRight';
import { useWindowSize } from '../../../../helpers/useWidth';
type Props = {
  periodDate: string;
  currentWeeklyIndex: number;
  setCurrentWeeklyIndex: React.Dispatch<React.SetStateAction<number>>;
};
const LeaderBoardNavigation: React.FC<Props> = ({ periodDate, currentWeeklyIndex, setCurrentWeeklyIndex }) => {
  const width = useWindowSize().width
const isDesktop = width > 768;
  const lng = localStorage.getItem('i18nextLng');
  // const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const [isDropdown, setIsDropdown] = useState(false);
  const { pickemsList, isLoaded, setTournamentData } = useContext(PlayersContext);
  const { currentOverallSelection, setCurrentOverallSelection, overallPeriodsState, period } = useOverall({ setIsDropdown });
  const [filteredAllPlayersData, setFilteredAllPlayersData] = useState(pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY"))
  
  useEffect(() => {
    setFilteredAllPlayersData(pickemsList.filter((pickem: any) => pickem.status === "COMPLETE" || pickem.status === "IN_PLAY"))
  }, [pickemsList])
  
    const handleOverallSelection = (periodIndex: number) => () => {
      setCurrentOverallSelection(overallPeriodsState[periodIndex]);
      setTournamentData(overallPeriodsState[periodIndex])
      setIsDropdown(false);
    };
  
    const handleWeeklySelection = (pickemIndex: number) => () => {
      setCurrentWeeklyIndex(pickemIndex);
      setIsDropdown(false);
    };

  const toggleDropdown = () => setIsDropdown((value) => !value)
  const { langId } = useParams();
  const { pathname } = useLocation();
  return (
    <>
      <Wrapper>
        <NavLink
          to={`/${langId}${paths.myLeagues}`}
          className={({ isActive }) => isActive ? 'active' : 'inactive'}
          // onClick={toggleActive}
        >
          {isDesktop ? (
            <ContainerNavLink>
              <div style={{ width: '60px' }}>
                <StyledDropdownImage src={pathname === `/${langId}${paths.myLeagues}` ? ml : mly} className="active" />
              </div>
              {t('myLeague')}
            </ContainerNavLink>
          ) : (
            <>{t('myLeague')}</>
          )}
        </NavLink>

        {window.location.pathname === `/${langId}${paths.overall}` ? (
          <Dropdown>
            <DropdownButton
              className="active"
              style={{ padding: '3px 6px' }}
              onClick={() => setIsDropdown(!isDropdown)}
            >
              {isDesktop ? (
                <DropdownImageContainer>
                  <StyledDropdownImage
                    src={pathname === `/${langId}${paths.overall}` ? overActive : over}
                    alt="overall"
                    className="active"
                  />
                </DropdownImageContainer>
              ) : null}
              {currentOverallSelection
                ? //@ts-ignore
                  currentOverallSelection.title
                // : lng === 'fr'
                // ? 'Période 1: 11 Jan - 7 Fév'
                : period}
            </DropdownButton>

            {isDropdown && (
              <>
                <DropdownListContainer>
                  {overallPeriodsState.length
                    ? overallPeriodsState.map((period: any, index: number) => {
                        return (
                          <DropdownListItemRight style={{width: isDesktop ? "20vw" : "55vw"}} key={index}>
                            <StyledDropdownListButton
                              style={{
                                borderBottom: index === overallPeriodsState.length - 1 ? 'none' : '2px solid white',
                                borderTop: index === 0 ? 'none' : '2px solid white',
                              }}
                              onClick={handleOverallSelection(index)}
                              >
                              {period.title}
                            </StyledDropdownListButton>
                          </DropdownListItemRight>
                        )})
                      : null}
                  </DropdownListContainer>
                  <Overlay onClick={toggleDropdown} />
                </>
                )}

          </Dropdown>
        ) : (
          <NavLink to={`/${langId}${paths.overall}`} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            {isDesktop ? (
              <ContainerNavLink>
                <div style={{ width: '60px' }}>
                  <StyledDropdownImage
                    src={pathname === `/${langId}${paths.overall}` ? overActive : over}
                    alt="overall"
                    className="active"
                  />
                </div>
                {t('overall')}
              </ContainerNavLink>
            ) : (
              <>{t('overall')}</>
            )}
          </NavLink>
        )}

        {window.location.pathname === `/${langId}${paths.weekly}` ? (
          <Dropdown style={{ width: 'calc(100% / 3)' }}>
            <DropdownButton
              className="active"
              onClick={toggleDropdown}
            >
              {isDesktop ? (
                <DropdownImageContainer>
                  <StyledDropdownImage
                    src={pathname === `/${langId}${paths.weekly}` ? weeklyActive : week}
                    alt="weekly"
                    className="active"
                  />
                </DropdownImageContainer>
              ) : null}
              <>
                {lng === 'en'
                    ? filteredAllPlayersData && filteredAllPlayersData[currentWeeklyIndex]?.title_en
                    : filteredAllPlayersData && (filteredAllPlayersData[currentWeeklyIndex]?.title_fr || filteredAllPlayersData[currentWeeklyIndex]?.title_en)
                  }
              </>
              {isDesktop ? <div style={{
                display: "flex",
                transform: `rotate(${isDropdown ? "-" : ""}90deg)`
                }}>
                  <CaretRight fill="white"/>
                </div> : null
              }
            </DropdownButton>
            {isDropdown && (
              <>
                <DropdownListContainer>
                  {isLoaded
                    ? filteredAllPlayersData.map((pickem: any, index: number) => {
                        return (
                          <DropdownListItemRight key={index}>
                            <StyledDropdownListButton
                              style={{
                                border: '2px solid white',
                                // borderTop: index === 0 ? 'none' : '2px solid white',
                              }}
                              onClick={handleWeeklySelection(index)}
                              >
                              {lng === 'en' ? pickem.title_en : pickem.title_fr || pickem.title_en}
                            </StyledDropdownListButton>
                          </DropdownListItemRight>
                        )})
                      : null}
                  </DropdownListContainer>
                  <Overlay onClick={toggleDropdown} />
                </>
                )}
          </Dropdown>
        ) : (
          <NavLink to={`/${langId}${paths.weekly}`} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            {isDesktop ? (
              <ContainerNavLink>
                <div style={{ width: '60px' }}>
                  <StyledDropdownImage
                    src={pathname === `/${langId}${paths.weekly}` ? weeklyActive : week}
                    alt="overall"
                    className="active"
                  />
                </div>
                {t('weakly')}
              </ContainerNavLink>
            ) : (
              <> {t('weakly')}</>
            )}
          </NavLink>
        )}
      </Wrapper>
    </>
  );
};

export default LeaderBoardNavigation;
