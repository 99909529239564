import styled from 'styled-components';
import colors from '../../../../assets/theme/colors';
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13px 17px 14px;
  gap: 4px;
  background: #000000;
  border-bottom: 1px solid #656563;
  box-sizing: border-box;
  @media (min-width: 768px) {
    background: linear-gradient(180deg, rgba(28, 28, 29, 0) 0%, #1c1c1d 100%);
    padding: 0px 100px;
    height: 120px;
  }
`;

export const Dropdown = styled('div')({});

export const DropdownButton = styled('button')({
  color: colors.lightGrayFont,
  width: 'fit-content',
  minWidth: '116px',
  border: 'none',
});

export const DropdownListContainer = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  transform: 'translateY(15px)',
  zIndex: 100,
});

export const DropdownListItem = styled('div')({
  // width: '30vw',
  backgroundColor: '#292933',
  color: 'white',
  margin: '0 auto 6px 0',
  '& button': {
    textAlign: 'left',
    justifyContent: 'flex-start !important',
  },
  '@media screen and (max-width: 768px)': {
    width: '65vw',
  },
});

export const DropdownListItemRight = styled('div')({
  width: '31vw',
  maxWidth: "300px",
  '@media screen and (min-width: 768px)': {
    width: '15vw',
    margin: '0 15vw 3px auto',
  },
});

export const StyledDropdownListButton = styled('button')({
  backgroundColor: '#292933',
  color: 'white',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '13px 8px',
  fontWeight: '400',
  fontSize: '16px',
  textAlign: 'center',
  borderRadius: '8px',
  border: 'none',
  cursor: 'pointer',
  userSelect: 'none',
});

export const DropdownImageContainer = styled('div')({
  marginRight: "20px",
  '@media screen and (max-width: 768px)': {
    width: "60px",
  }
});

export const Overlay = styled("div")({
  position: "absolute",
  zIndex: 20,
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
})

export const StyledDropdownImage = styled.img`
  display: block;
  width: 100%;
`;

export const ContainerNavLink = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
