import styled from "styled-components";
import { CornerIconContainerProps } from "./types";

type playerCardSize = {
  mainWidth?: number;
  playerNum?: string;
  teamNameSize?: string;
  positionSize?: string;
  nameSize?: string;
  bgColor?: string;
  oppTeam?: string;
};

export const PlayerCardWrapper = styled.div<playerCardSize>`
  width: 100%;
  height: 254px;
  max-width: 160px;
  background: ${(props) =>
    props.bgColor === "red"
      ? "linear-gradient(138deg, #4D000E 0%, #800018 51.14%, #4D000E 77.52%)"
      : "linear-gradient(138deg, #0E0E96 0%, #0025FF 51.14%, #0E0E96 77.52%)"};
  border: ${(props) =>
    props.bgColor === "red"
      ? "1.5px solid #015EB7"
      : "1.5px solid #015EB7"};

  position: relative;
  border-radius: 20px;
  // touch-action: none;
  // -webkit-touch-callout: none;
  // user-select: none;
  :hover {
    cursor:pointer;
  };
`;

export const DisabledOverlay = styled('div') ({
  width: '100%',
  height: '100%',
  position: "absolute",
  background: 'rgba(0, 0, 0, 0.50)',
  zIndex: 100,
  borderRadius: '16px',
  display: 'flex',
  alignItems: "center",
  justifyContent: 'center',
})

export const PlayerCardPhoto = styled.div`
  position: relative;
  top: -12px;
  left: 0;
  right: 0;
  @media screen and (min-width: 768px) {
    top: -25px;
  };
`;

export const PlayerCardLogo = styled.div`
  position: absolute;
  width: 15%;
  top: 5%;
  left: 8%;
`;

export const OpposingTeam = styled.div<playerCardSize>`
  display: block;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 0 12px 0 0;
  font-size: 9px;
  text-transform: uppercase;
  color: #004c97;
  padding: 8px 14px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 27px;
  span {
    font-weight: 700;
  }
  @media screen and (min-width: 500px) {
    font-size: ${(props) => (props.teamNameSize ? "12px": "9px")};
    height: ${(props) => (props.teamNameSize ? "44px" : "30px")};
  }
  @media screen and (min-width: 700px) {
    font-size: ${(props) => (props.teamNameSize ? props.teamNameSize : "9px")};
    height: ${(props) => (props.teamNameSize ? "53px" : "30px")};
  }
`;

export const PlayerCardInfoWrapper = styled.div<playerCardSize>`
  // border-radius: 15px 0 20px 20px;
  padding: 5px 0;
  background-color: #022B53;
  position: relative;
  top: -95px;
  display: flex;
  justify-content: space-around;
`;

export const PlayerImg = styled.img`
  width: 100%;
  // position: relative;
  // transform: translate(-5.5%);
`;

export const TeamLogoImg = styled('img')`
  // max-height: 160px;
  // -webkit-touch-callout: none;
  pointer-events: none;
  -webkit-user-select: none;
  width: 100%
`;

export const PlayerCardInfo = styled.p<playerCardSize>`
  padding: 5px 0;
  font-weight: 700;
  text-align: center;
  background: #022B53;
  // @media screen and (min-width: 500px) {
  //   margin: ${(props) => (props.nameSize ? "7px 0": "5px 0")};
  // }
`;

export const PlayerCardName = styled(PlayerCardInfo)<playerCardSize>`
  margin-top: -3px;
  font-size: 10px;
  color: #fff;
  @media screen and (min-width: 500px) {
    font-size: ${(props) => (props.nameSize ? "12px": "10px")};
  }
  @media screen and (min-width: 600px) {
    font-size: ${(props) => (props.nameSize ? "14px" : "10px")};
  }
  @media screen and (min-width: 700px) {
    font-size: ${(props) => (props.nameSize ? "18px" : "10px")};
  }
  @media screen and (min-width: 900px) {
    font-size: ${(props) => (props.nameSize ? props.nameSize : "10px")};
  }
`;

export const PlayerCardTeam = styled(PlayerCardInfo)`
  font-size: 13px;
  position: relative;
  top: -95px;
  background: none;
  color: white;
  @media screen and (min-width: 500px) {
    font-size: ${(props) => (props.teamNameSize ? "11px" : "9px")};
  }
  @media screen and (min-width: 600px) {
    font-size: ${(props) => (props.teamNameSize ? "13px" : "9px")};
  }
  @media screen and (min-width: 700px) {
    font-size: ${(props) => (props.teamNameSize ? props.teamNameSize : "9px")};
  }
`;

export const PlayerCardPosition = styled.span<playerCardSize>`
  display: inline-block;
  padding: 10px 5px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 6px;
  font-size: 11px;
  position: absolute;
  top: 5%;
  right: 3%;
  @media screen and (min-width: 768px) {
    font-size: 11px;
  }
`;

export const CornerIconContainer = styled("div")<CornerIconContainerProps>((isDragging) => ({
  position: "absolute",
  zIndex: 50,
  top: !isDragging ? "-9%" : '0',
  right: !isDragging ? "-12%" : '0',
  "@media screen and (min-width: 768px)" : {
    top: "-5%",
    right: "-3%",
  }
}));

export const TeamLogoContainer = styled('div') ({
  width: '100%',
  height: '133px',
  position: 'relative',
  top: "-35px",
  // WebkitTouchCallout: 'none',
})

export const PlayerCardStat = styled('div') ({
  textTransform: "uppercase",
  display: 'flex',
  alignItems: "center",
  flexDirection: "column",
})

export const PlayerCardStatTitle = styled('div') ({
  fontSize: '10.5px',
  letterSpacing: '-0.5px',
  color: '#878787',
})

export const PlayerCardStatValue = styled('div') ({
  fontSize: '18px',
})