import styled from "styled-components";

export const Wrapper = styled("div")({
    "@media screen and (min-width: 768px)": {
        padding: "60px 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
    }
});

export const Table = styled("table")({
    width: "100vw",
    "@media screen and (min-width: 768px)": {
        margin: "0px 20px",
        width: "600px",
    },
});

export const Header = styled("thead")({
    marginBottom: "15px",
    background: "#2D2D2C",
    border: "1px solid #656563",
});

export const HeaderValue = styled("th")({
    height: "60px",
    verticalAlign: "middle",
    textAlign: "center",
});