import React from 'react';
import {
    AdditionalContent,
    Corner,
    StyledButton,
    ButtonContent,
    LinkBtn,
} from './SidebarButton.styles';
import { SidebarButtonProps } from './types';

const SidebarButton: React.FC<SidebarButtonProps> = ({
    label,
    AdditionalComponent,
    isActive,
    withoutCorners,
    onTabClick,
    onLinkClick,
    icon,
    redirectLink,
}) => {
    const isAdditionalContentVisible = !!AdditionalComponent && isActive;
    return (
        <StyledButton
            isActive={isActive}
            onClick={onTabClick}
            rotateIcon={icon?.rotateIcon}
            fillIcon={icon?.fillIcon}
        >
            {!withoutCorners && (
                <Corner isReversed={false} isActive={isActive} />
            )}
            <ButtonContent onClick={onTabClick}>
                {redirectLink ? (
                    <div>
                        <LinkBtn
                            isActive={isActive}
                            href={redirectLink}
                            target="_blank"
                        >
                            {icon?.Component}
                            {label}
                        </LinkBtn>
                    </div>
                ):(
                    <div
                        onClick={onLinkClick}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "10px",
                        }}
                    >
                        {icon?.Component}
                        {label}
                    </div>
                )}


            </ButtonContent>
            <AdditionalContent isVisible={isAdditionalContentVisible}>
                {AdditionalComponent}
            </AdditionalContent>
            {!withoutCorners && (
                <Corner isReversed={true} isActive={isActive} />
            )}
        </StyledButton>
    );
}

export default SidebarButton;