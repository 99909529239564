import styled from "styled-components";
import colors from "../../assets/theme/colors";

export const B = styled("b") ({
  fontWeight: 700,
})

export const PopupContainer = styled('div')({
  margin: '25vh auto',
  maxWidth: '400px',
  position: 'relative',
  width: '100%',
  padding:'0 20px',
  borderRadius: '18px',
  zIndex: 100,
});

export const PopupBodyContainer = styled('div')({
  position: "relative",
  height: 'max-content',
  backgroundColor: 'white',
  borderBottomLeftRadius: '18px',
  borderBottomRightRadius: '18px',
  color: 'black',
  textAlign: 'center',
  padding: '24px',
})


export const PopupBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  width: '100vw',
  height: '100vh',
  background: 'rgb(0, 0, 0, 0.7)',
});

export const PopupCrossContainer = styled("div") ({
  margin: '16px 8px', zIndex: 10, position: 'absolute',
  top: '-20%', right: '3%',
})

export const P16W700 = styled("div") ({
  fontWeight: 700,
  marginTop: '18px',
  fontSize: "16px",
})

export const BlueGoalieContainer = styled("div") ({
  background: colors.gradientBlue,
  borderTopLeftRadius: '18px',
  borderTopRightRadius: '18px',
  'img': {
    width: '100%'
  }
})