// @ts-ignore
import English from './images/English.svg';
// @ts-ignore
import France from './images/French.svg';

export const lenguagesData = [
    {
        lenguageName: 'en',
        logo: English,
    },
    {
        lenguageName: 'fr',
        logo: France,
    },
];
