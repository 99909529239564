import styled from "styled-components";

export const Wrapper = styled("div")(() => ({
    width: "250px",
    maxWidth: "400px",
    height: "100%",
    background: "white",
    overflow: "hidden",
    zIndex: 5,
}));

export const ScrollContent = styled("div")({
    overflowY: "auto",
    height: "100%",
});

export const Top = styled("div")({
    padding: "29px 0",
    display: "flex",
    justifyContent: "center",
});

export const Bottom = styled("div")({
    margin: "65px 0 30px",
    paddingLeft: "52px",
});